import './defines';
import {
	Component,
	Vue,
} from 'vue-property-decorator';
import {
	OfferingModel,
	ProductModel,
} from 'interfaces/database';
import experiment from 'controllers/experiment';
import EditorModule from 'modules/editor.vue';
import NewEditorModule from 'modules/new-editor';
import { OfferingGroups } from 'settings/offerings';
import { ProductStateModule } from 'store';
import Template from './template.vue';

@Component({
	name: 'EditorSwitchModule',
	components: {
		EditorModule,
		NewEditorModule,
	},
})
export default class EditorSwitchModule extends Vue.extend(Template) {
	private get offeringModel(): OfferingModel | null {
		return ProductStateModule.getOffering;
	}

	protected get editorTheme(): 'dark' | 'light' {
		if (
			this.projectModel?.group
			&& OfferingGroups(
				this.projectModel.group,
				[
					'PageSets',
				],
			)
		) {
			return 'dark';
		}

		return 'light';
	}

	private get projectModel(): ProductModel | null {
		return ProductStateModule.getProduct;
	}

	protected get showNewEditor(): boolean {
		if (this.offeringModel?.editorVersion === 2) {
			if (OfferingGroups(
				this.offeringModel.groupid,
				'PhotoPrints',
			)) {
				// For multi-page sets, always show the new editor
				return true;
			}

			return experiment.getFlagValue('flag_show_new_editor') == true;
		}

		return false;
	}
}
