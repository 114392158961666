import AWS from 'aws-sdk';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'underscore';
// import Vue from 'vue';
import BetterQueue from 'better-queue';
import { fromBuffer } from 'file-type/browser';
// @ts-ignore: No declaration file
import filesizeParser from 'filesize-parser';
// @ts-ignore: No declaration file
import MemoryStore from 'better-queue-memory';
import ProductState from 'classes/productstate';
import DialogComponent from 'components/dialog-new';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import awsCognito from 'controllers/aws-cognito';
import experiment from 'controllers/experiment';
import {
	MimeTypeExtended,
	NativeObjectLocation,
	NativeSelectedFile,
	UploadModel,
	UploadProgressMessage,
	UploadProgressPhoto,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import * as DialogService from 'services/dialog';
import loadImage from 'services/load-image';
import { ERRORS_UPLOAD_REJECTED_TOO_MANY } from 'settings/errors';
import {
	MIME_TYPES_REQUIRE_CONVERSION,
	MIME_TYPES_BROWSER_SUPPORTED,
	MIME_TYPES_HEIC,
	MIME_TYPES_PDF,
	MIME_TYPES_EPS,
	MIME_TYPES_SVG,
} from 'settings/filetypes';
import { OfferingGroups } from 'settings/offerings';
import store, {
	AppDataModule,
	AppStateModule,
	ConfigModule,
	PhotosModule,
	ProductStateModule,
	ThemeStateModule,
	UploadModule,
	UserModule,
} from 'store';
import detectUpload from 'tools/detect-upload';
import fileConversionView from 'views/file-conversion';
import UploadProgressView from 'views/upload-progress';
import UploadQrView from 'views/upload-qr';
import { VueConstructor } from 'vue';

type AwsAppSyncType = typeof import('controllers/aws-appsync').default | null;

let awsAppSyncInstance: AwsAppSyncType = null;

async function importAwsAppSync() {
	if (awsAppSyncInstance === null) {
		awsAppSyncInstance = (await import('controllers/aws-appsync')).default;
	}
}

const fullUploadSize = {
	width: 9000,
	height: 9000,
};

interface FileData {
	file: File;
	fileType: MimeTypeExtended;
	date: number;
}

interface FileTypeMap {
	file: File;
	fileName: string;
	fileType: MimeTypeExtended;
	id: string;
	lastModified: number;
	size: number;
}

interface ImageDetails {
	orientation?: number;
	fullWidth?: number;
	fullHeight?: number;
	photodate?: string;
	thumbUrl?: string;
	fcx?: number;
	fcy?: number;
	fcw?: number;
	fch?: number;
}

class Upload {
	// The DOM element this class will be attached to
	private buttonEl: HTMLElement | null = null;

	private bucketModel: DB.BucketModel | undefined;

	// Check for maximum photos of product/theme when uploading?
	private checkMaxPhotos = true;

	// Store array of locally uploaded photos that have completed processing
	private completedPhotoModels: PI.PhotoModel[] = [];

	// Store array of photos uploaded from an external device
	private externalUploads: UploadProgressPhoto[] = [];

	// Flags for experimental features
	public features: {
		scaling: boolean;
	} = {
			scaling: false,
		};

	private fileTypeMapper: FileTypeMap[] = [];

	// Reference to the hidden input field that is used for trigger the file upload UI
	private inputField: HTMLInputElement | undefined;

	// Count number of uploads that are rejected due to a too large batch size
	private rejectBatchSizeCount = 0;

	// Count number of uploads that are rejected due to their file size
	private rejectFileSizeCount = 0;

	// Size limit of files to upload in MB
	// IMPORTANT: Changing the sizeLimit requires the same change in webhook/s3/cors,
	// otherwise the policy document will be invalid
	// 20 mB (1 mB = 1000 * 1024 bytes)
	private sizeLimit = '20MB';

	// S3 Instance of AWS SDK
	private s3: AWS.S3 | undefined;

	// Counter for number of (validated) photos submitted to upload controller
	private submitCount = 0;

	private trackFilesChange = false;

	private uploadProgressDialog: ServiceOpenReturn<DialogComponent<typeof UploadProgressView, VueConstructor, VueConstructor>> | undefined;

	private uploadQRDialog: ServiceOpenReturn<DialogComponent<typeof UploadQrView, VueConstructor, VueConstructor>> | undefined;

	private uploadQueue: BetterQueue<OptionalExceptFor<UploadModel, 'id'>, string> = new BetterQueue({
		concurrent: 2,
		id: 'id',
		maxRetries: 1,
		// Task processing cannot take longer than 5 minutes
		maxTimeout: 5 * 60 * 1000,
		precondition: (cb) => {
			if (AppStateModule.online) {
				cb(
					null,
					true,
				);
			} else {
				cb(
					null,
					false,
				);
			}
		},
		preconditionRetryTimeout: 5 * 1000, // If we go offline, retry every 5s
		process: (uploadModel: OptionalExceptFor<UploadModel, 'id'>, cb) => {
			const mapItem = this.fileTypeMapper.find(
				(m) => m.id == uploadModel.id,
			);

			if (!mapItem) {
				cb(new Error('Could not locate upload model'));
				return;
			}

			const fileExtension = mapItem.fileName.split('.').pop();
			const fileName = `${uploadModel.id}.${fileExtension}`;

			let upload: AWS.S3.ManagedUpload | undefined;
			this.getCognito().then(() => {
				if (!this.bucketModel || !this.bucketModel.label) {
					throw new Error('Missing required auto-expire bucket model');
				}

				if (!this.s3) {
					// Important: We cannot construct this object before the Cognito credentials are fetched
					this.s3 = new AWS.S3({
						params: {
							Bucket: this.bucketModel.label,
							ACL: 'private',
						},
						region: this.bucketModel.region,
						useAccelerateEndpoint: ConfigModule['upload.accelerate'],
						httpOptions: {
							timeout: 60 * 1000 * 5, // 5 minutes
						},
					});
				}

				upload = new AWS.S3.ManagedUpload({
					params: {
						Bucket: this.bucketModel.label,
						Key: fileName,
						Body: mapItem.file,
						ContentType: mapItem.fileType,
					},
					queueSize: 1,
					service: this.s3,
				});
				upload.on(
					'httpUploadProgress',
					(uploadProgress) => {
						UploadModule.updateUploading({
							id: uploadModel.id,
							queue: uploadProgress.loaded / uploadProgress.total,
						});
					},
				);
				upload.send((error, result) => {
					if (error) {
						cb(error);
						return;
					}

					cb(
						null,
						result.Location,
					);
				});
			}).catch((e) => {
				cb(e);
			});
		},
		// @ts-ignore: This fixes an issue with Edge (see https://github.com/diamondio/better-queue/issues/27)
		// setImmediate: setTimeout.bind(null),
		retryDelay: 1000,
		store: new MemoryStore(),
	});

	private validationDialog: ReturnType<typeof DialogService.openProgressDialog> | undefined;

	public init() {
		this.bucketModel = AppDataModule.getBucket('auto-expire');

		if (window.glPlatform === 'native') {
			this.setupNativeBridge();
		} else if (detectUpload()) {
			this.setupWeb();

			store.watch(
				(state) => state.appstate.online,
				(online) => {
					if (online) {
						this.retryAll();
					}
				},
			);
		}
	}

	public changeCheckMaxPhotos(newValue: boolean) {
		this.checkMaxPhotos = newValue;
	}

	public dropFiles(
		event: DragEvent,
	) {
		if (event.dataTransfer) {
			const { files } = event.dataTransfer;
			if (files.length) {
				analytics.trackEvent(
					'drop files',
					{ quantity: files.length },
				);

				// Use DataTransfer interface to access the file(s)
				this.addFiles(files);
			}
		}
	}

	private addFiles(
		selectedFiles: FileList,
	) {
		const errors: string[] = [];
		const promises: Promise<FileData>[] = [];
		for (let i = 0; i < selectedFiles?.length; i += 1) {
			const file = selectedFiles?.item(i);
			if (file) {
				// Check if this file was already submitted previously
				const mapItem = this.fileTypeMapper.find(
					(m) => m.fileName == file.name
						&& m.lastModified == file.lastModified
						&& m.size == file.size,
				);

				if (mapItem) {
					errors.push('File already submitted');
				} else if (file.size >= filesizeParser(this.sizeLimit)) {
					this.rejectFileSizeCount += 1;

					// Log this filesize error, so we can see how often it occurs
					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							new Error('Filesize is too large for upload'),
							(event) => {
								event.severity = 'info';
								event.addMetadata(
									'imageData',
									{
										size: file.size,
									},
								);
							},
						);
					}

					errors.push(`Maximum filesize is ${this.sizeLimit}`);
				} else {
					promises.push(
						this.resolveFileData(file),
					);
				}
			}
		}

		Promise.allSettled(promises).then((results) => {
			const acceptedFiles: FileData[] = [];

			results.forEach((result) => {
				if (result.status == 'fulfilled') {
					acceptedFiles.push(result.value);
				} else {
					errors.push(result.reason);
				}
			});

			// We sort the images by date
			acceptedFiles.sort(
				(a, b) => a.date - b.date,
			);

			// Now we prioritize file formats that need conversion on the server, to minize waiting for upload process
			// (we can't show previews for these filetypes, so those need to be fully processed before shown to user)
			acceptedFiles.sort(
				(a, b) => {
					if (MIME_TYPES_REQUIRE_CONVERSION.includes(a.fileType)
						&& MIME_TYPES_BROWSER_SUPPORTED.includes(b.fileType)
					) {
						return -1;
					}

					if (MIME_TYPES_REQUIRE_CONVERSION.includes(b.fileType)
						&& MIME_TYPES_BROWSER_SUPPORTED.includes(a.fileType)
					) {
						return 1;
					}

					return 0;
				},
			);

			const x = ProductStateModule.getPhotosSelected.length + acceptedFiles.length;
			const { themeModel } = ThemeStateModule;
			const maxphotos = !themeModel || _.isNull(themeModel.maxphotos)
				? 200
				: themeModel.maxphotos;

			// Check if batch size exceeds the photo limit for the product
			if (this.checkMaxPhotos
				&& x >= maxphotos
			) {
				// Too many files, do not validate
				this.rejectBatchSizeCount = x - maxphotos;
			}

			// All selected files are now ready for uploading and processing
			// so we call the submit event (other parts of the app can listen to this)
			this.uploadSubmit(acceptedFiles.length);

			// We now want to process the items sequentially to prevent browser crash/freeze
			const toUpload: UploadModel[] = [];
			const doNextItem = (i: number) => {
				const { file } = acceptedFiles[i];
				this.addUploadToProduct(file)
					.then((uploadModel) => {
						if (this.validationDialog) {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							const dialogProgressComponent = this.validationDialog.api.bodyComponent()!;
							dialogProgressComponent.value += 1;
						}

						toUpload.push(uploadModel);
					})
					.catch((e) => {
						if (e.message == ERRORS_UPLOAD_REJECTED_TOO_MANY) {
							// Intended behavior, too many photos selected
						} else {
							throw e;
						}
					})
					.finally(() => {
						i += 1;
						if (i < acceptedFiles.length) {
							doNextItem(i);
						} else {
							toUpload.forEach((uploadModel) => {
								this.uploadFile(uploadModel);
							});

							this.uploadSubmitted(acceptedFiles.length);
							this.waitForDecodings();
						}
					});
			};

			if (acceptedFiles.length === 0) {
				this.uploadSubmitted(0);
			} else {
				doNextItem(0);
			}
		});
	}

	private addUploadToProduct(
		file: File,
	): Promise<UploadModel> {
		const mapItem = this.fileTypeMapper.find(
			(m) => m.fileName == file.name
				&& m.lastModified == file.lastModified
				&& m.size == file.size,
		);
		if (!mapItem) {
			return Promise.reject(
				new Error('Could not find upload model'),
			);
		}

		const x = ProductStateModule.getPhotosSelected.length;
		const { themeModel } = ThemeStateModule;
		const maxphotos = !themeModel || _.isNull(themeModel.maxphotos)
			? 200
			: themeModel.maxphotos;
		if (this.checkMaxPhotos
			&& x >= maxphotos
		) {
			// Remove item from fileTypeMapper, since this file will not be part of the project selection
			const i = this.fileTypeMapper.findIndex(
				(m) => m.id == mapItem.id,
			);
			if (i >= 0) {
				this.fileTypeMapper.splice(
					i,
					1,
				);
			}

			// We are already at the limit
			// so we reject adding the photo
			return Promise.reject(
				new Error(ERRORS_UPLOAD_REJECTED_TOO_MANY),
			);
		}

		UploadModule.addWaiting({
			_type: mapItem.fileType,
			id: mapItem.id,
			errorCount: 0,
			// metaData: objMetaData,
			name: mapItem.fileName,
			overall: 1,
			photoModelId: null,
			queue: 0,
			uploadEnd: 0,
			uploadStart: 0,
			totalBytes: mapItem.size,
		});

		return this.getImageDetails(
			file,
			mapItem.fileType,
		).then((data) => ProductStateModule.selectPhoto({
			_localRef: mapItem.id,
			_orientation: data.orientation,
			_type: mapItem.fileType,
			source: 'upload',
			externalId: mapItem.id,
			full_width: data.fullWidth,
			full_height: data.fullHeight,
			thumb_url: data.thumbUrl,
			photodate: data.photodate,
			fcx: data.fcx,
			fcy: data.fcy,
			fcw: data.fcw,
			fch: data.fch,
			type: ProductStateModule.getOffering?.type || 'photo',
		})).then((photoModel) => {
			UploadModule.changeModel({
				id: mapItem.id,
				photoModelId: photoModel.id,
				width: photoModel.full_width,
				height: photoModel.full_height,
			});

			const uploadModel = UploadModule.find(
				mapItem.id,
			);

			if (!uploadModel) {
				throw new Error('Could not find upload model');
			}

			return uploadModel;
		});
	}

	private closeProgressDialog() {
		this.uploadProgressDialog?.close();
		this.uploadProgressDialog = undefined;
	}

	private closeQRDialog() {
		this.uploadQRDialog?.close();
		this.uploadQRDialog = undefined;
	}

	private closeValidationDialog() {
		this.validationDialog?.close();
		this.validationDialog = undefined;
	}

	private uploadFile(
		uploadModel: UploadModel,
	) {
		this.uploadQueue.push(uploadModel)
			.on(
				'started',
				() => {
				// Save uploadStart timestamp for performance tracking
					UploadModule.changeModel({
						id: uploadModel.id,
						uploadStart: new Date().getTime(),
					});

					UploadModule.moveWaitingToUploading(uploadModel.id);
				},
			)
			.on(
				'finish',
				(url) => {
					if (!url) {
						throw new Error('Missing url of uploaded file');
					}

					// Set URL of upload to uploadModel
					// and save uploadEnd timestamp for performance tracking
					UploadModule.changeModel({
						id: uploadModel.id,
						url,
						uploadEnd: new Date().getTime(),
					});

					// Get the updated data (otherwise we're missing the url in the data)
					const updatedUploadModel = UploadModule.find(uploadModel.id);
					if (updatedUploadModel) {
					// Note: It's possible that the uploadModel cannot be found,
					// because the user may have removed it from the project in the meantime

						const uploadData = JSON.parse(JSON.stringify(updatedUploadModel));

						UploadModule.remove(updatedUploadModel.id);
						UploadModule.addCompleted(uploadData);

						if (updatedUploadModel.photoModelId) {
							this.processUploadedFile(uploadData);
						}
					}
				},
			)
			.on(
				'failed',
				(err) => {
					if (err && typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							err,
							(event) => { event.severity = 'warning'; },
						);
					}

					if (uploadModel) {
						if (uploadModel.photoModelId) {
							const photoModel = PhotosModule.getById(uploadModel.photoModelId);
							if (photoModel) {
								PhotosModule.updateModel({
									id: photoModel.id,
									_error: err,
								});
							}
						}

						UploadModule.moveToError(uploadModel.id);
					}

					if (err instanceof Error) {
						throw err;
					}
				},
			);
	}

	private getImageDetails(
		file: File,
		fileType?: MimeTypeExtended,
	): Promise<ImageDetails> {
		if (fileType
			&& MIME_TYPES_REQUIRE_CONVERSION.includes(fileType)
		) {
			return Promise.resolve({});
		}

		const useFaceRecognition = experiment.getFlagValue(
			'flag_face_detection',
		) === 'on';

		return loadImage(
			file,
			{
				faceRecognition: useFaceRecognition,
				meta: true,
				orientation: false,
				parallel: 2,
			},
		).then(
			({ data }) => {
				const orientation = data && data.exif
					? data.exif[0x0112] as number
					: undefined;

				let fullWidth: number | undefined;
				let fullHeight: number | undefined;
				if (data) {
					if (orientation && orientation >= 5) {
						fullWidth = data.originalHeight;
						fullHeight = data.originalWidth;
					} else {
						fullWidth = data.originalWidth;
						fullHeight = data.originalHeight;
					}
				}

				// Updates sizes to match maximum dimensions of saved photo
				if (fullWidth && fullHeight) {
					const scale = Math.max(
						1,
						fullWidth / fullUploadSize.width,
						fullHeight / fullUploadSize.height,
					);
					fullWidth = Math.round(fullWidth / scale);
					fullHeight = Math.round(fullHeight / scale);
				}

				let fch: number | undefined;
				let fcw: number | undefined;
				let fcx: number | undefined;
				let fcy: number | undefined;
				if (data && data.faces && data.faces.boundingBox && fullWidth && fullHeight) {
					const { boundingBox } = data.faces;
					fcx = Math.round(boundingBox.x1 * fullWidth);
					fcy = Math.round(boundingBox.y1 * fullHeight);
					fcw = Math.round((boundingBox.x2 - boundingBox.x1) * fullWidth);
					fch = Math.round((boundingBox.y2 - boundingBox.y1) * fullHeight);
				}

				return {
					orientation,
					fullWidth,
					fullHeight,
					photodate: data && data.exif && data.exif[0x9003]
						? moment(
							data.exif[0x9003] as string,
							'YYYY:MM:DD HH:mm:ss',
						).format('X')
						: undefined,
					// @ts-ignore
					thumbUrl: data && data.exif && data.exif.Thumbnail ? data.exif.Thumbnail : null,
					fcx,
					fcy,
					fcw,
					fch,
				};
			},
			() => ({}),
		);
	}

	private resolveFileData(
		file: File,
	): Promise<FileData> {
		const resolveData = (
			fileType?: MimeTypeExtended,
		) => {
			const detectedType = fileType || file.type as MimeTypeExtended;
			if (this.acceptedFileTypes.indexOf(detectedType) >= 0) {
				this.fileTypeMapper.push({
					id: nanoid(),
					file,
					fileName: file.name,
					fileType: detectedType,
					lastModified: file.lastModified,
					size: file.size,
				});

				return {
					file,
					fileType: detectedType,
					date: file.lastModified,
				};
			}

			throw new Error(`Filetype ${detectedType} not allowed`);
		};

		if (window.FileReader) {
			return new Promise((resolve) => {
				const blob = file.slice(
					0,
					20,
				);
				const reader = new FileReader();
				reader.onloadend = (e) => {
					if (!e.target || e.target.readyState !== FileReader.DONE) {
						resolve(resolveData());
					} else {
						try {
							// @ts-ignore
							const bytes = new Uint8Array(e.target.result);
							fromBuffer(bytes).then((res) => {
								resolve(resolveData(
									res?.mime,
								));
							}).catch(() => {
								resolve(resolveData());
							});
						} catch (err) {
							resolve(resolveData());
						}
					}
				};
				reader.readAsArrayBuffer(blob);
			});
		}

		if (file.name.toLowerCase().indexOf('.heic') >= 0) {
			return Promise.resolve(
				resolveData(
					MIME_TYPES_HEIC[0],
				),
			);
		}

		if (file.name.toLocaleLowerCase().indexOf('.pdf') >= 0) {
			return Promise.resolve(
				resolveData(
					MIME_TYPES_PDF[0],
				),
			);
		}

		return Promise.resolve(
			resolveData(),
		);
	}

	private setupNativeBridge() {
		if (!window.nativeToWeb) {
			throw new Error('Missing native bridge');
		}

		window.nativeToWeb.setPickedFiles = (files, pickedOptions) => {
			if (pickedOptions
				&& pickedOptions.hasOwnProperty('sortMode')
			) {
				ProductStateModule.changeProductSettings({
					sortMode: pickedOptions.sortMode,
				});
			}

			if (files.length && files[0].objectLocation) {
				const { objectLocation } = files[0];
				AppStateModule.setNativeObjectLocation(objectLocation);
			}

			const after = _.after(
				files.length,
				() => {
				// Removing of unselected photos currently does not work correctly so we disabled this function
				// When the project includes photos that have been selected on a different device, they will
				// get removed from the project when this is enabled
				/* if (AppStateModule.nativeSettingsMemory.removeUnselected) {
					const toRemove: PhotoModel[] = [];
					ProductStateModule.getPhotosSelected.forEach((photoModel) => {
						if (!_.findWhere(files, {
							source: photoModel.source || 'upload',
							id: photoModel.externalId || `pid${photoModel.id}`,
						})) {
							toRemove.push(photoModel);
						}
					});

					while (toRemove.length) {
						const photoModel = toRemove.shift();
						if (photoModel) {
							ProductStateModule.removePhoto(photoModel.id);
						}
					}
				} */

					this.uploadSubmit(files.length);
					this.uploadSubmitted(files.length);
				},
			);

			files.forEach((file) => {
				const nativeImgId = file.id;
				const { source } = file;

				if (file.source == 'upload' && file.id.substr(
					0,
					3,
				) == 'pid') {
					// This upload from another device is already included in the product
					// so no need to add to upload controller
					after();
				} else if (_.findWhere(
					ProductStateModule.getPhotosSelected,
					{
						source,
						externalId: nativeImgId,
					},
				)) {
					// File from external source is already included in the product
					// so no need to add to upload controller
					after();
				} else {
					this.submitCount += 1;

					// const uuid = nanoid();
					// Estimation: width x height x 3 (rgb) and divided by JPEG 2.5 compression factor
					const totalBytes = (file.width * file.height * 3) / 2.5;
					UploadModule.addWaiting({
						id: nativeImgId,
						errorCount: 0,
						name: '', // file.name,
						// uuid,
						// metaData: {},
						overall: 1,
						photoModelId: null,
						queue: 0,
						uploadEnd: 0,
						uploadStart: new Date().getTime(),
						totalBytes,
					});

					ProductStateModule.selectPhoto({
						_localRef: file.url,
						_orientation: file.orientation,
						source,
						externalId: nativeImgId,
						full_width: file.width,
						full_height: file.height,
						thumb_url: `${file.url}?width=250&height=250`,
						url: `${file.url}?width=${ConfigModule.photoPreviewSizeLocal}&height=${ConfigModule.photoPreviewSizeLocal}`,
						photodate: file.photodate
							? moment(file.photodate).format('X')
							: null,
						fcx: file.fcx,
						fcy: file.fcy,
						fcw: file.fcw,
						fch: file.fch,
						type: ProductStateModule.getOffering?.type || 'photo',
					}).then((photoModel) => {
						UploadModule.changeModel({
							id: nativeImgId,
							photoModelId: photoModel.id,
							width: photoModel.full_width,
							height: photoModel.full_height,
						});

						UploadModule.moveWaitingToUploading(nativeImgId);
					}).finally(() => {
						after();
					});
				}
			});
		};

		window.nativeToWeb.onUploadFailed = (id) => {
			const uploadModel = UploadModule.find(id);
			if (uploadModel) {
				if (uploadModel.photoModelId) {
					const photoModel = PhotosModule.getById(uploadModel.photoModelId);
					if (photoModel) {
						PhotosModule.updateModel({
							id: photoModel.id,
							_error: new Error('Something went wrong uploading file'),
						});
					}
				}

				UploadModule.moveToError(id);
			}

			if (typeof window.glBugsnagClient !== 'undefined') {
				window.glBugsnagClient.notify(
					new Error('Upload failed'),
					(event) => {
						event.severity = 'error';
						event.addMetadata(
							'uploadData',
							uploadModel || {},
						);
					},
				);
			}
		};

		window.nativeToWeb.onUploadProgress = (id, bytesUploaded, actualFileSize) => {
			const uploadModel = UploadModule.find(id);
			if (uploadModel) {
				UploadModule.updateUploading({
					id: uploadModel.id,
					queue: bytesUploaded / actualFileSize,
					totalBytes: actualFileSize,
				});
			} else if (typeof window.glBugsnagClient !== 'undefined') {
				window.glBugsnagClient.notify(
					new Error('Could not find uploadModel submitted by native'),
					(event) => {
						event.severity = 'warning';
					},
				);
			}
		};

		window.nativeToWeb.onUploadSucceeded = (id, url) => {
			const uploadModel = UploadModule.find(id);
			if (uploadModel) {
				const uploadData = JSON.parse(JSON.stringify(uploadModel));
				uploadData.uploadEnd = new Date().getTime();
				uploadData.url = url;

				UploadModule.remove(id);

				UploadModule.addCompleted(uploadData);
				this.processUploadedFile(uploadData);
			} else if (typeof window.glBugsnagClient !== 'undefined') {
				const error = new Error('Could not find uploadModel submitted by native');
				window.glBugsnagClient.notify(
					error,
					(event) => { event.severity = 'warning'; },
				);
			}
		};
	}

	private setupWeb() {
		if (!this.bucketModel || !this.bucketModel.label) {
			throw new Error('Missing required auto-expire bucket model');
		}

		if (ConfigModule['upload.fileSizeLimit']) {
			this.sizeLimit = ConfigModule['upload.fileSizeLimit'];
		}

		AWS.config.update({
			correctClockSkew: true,
		});

		this.buttonEl = document.getElementById('uploadButton');

		if (this.buttonEl) {
			this.inputField = document.createElement('input');
			this.inputField.multiple = true;
			this.inputField.id = nanoid();
			this.inputField.type = 'file';

			this.buttonEl.appendChild(this.inputField);

			this.inputField.addEventListener(
				'change',
				() => {
					if (this.trackFilesChange
					&& this.inputField
					&& this.inputField.files
					) {
						const selectedFiles = this.inputField.files;
						this.addFiles(selectedFiles);
					}
				},
			);
		}
	}

	public filePicker(
		max: number | undefined,
		nativeOptions: {
			// Do we open up the native component by showing the current selection?
			showSelection: boolean;
			// At what bookmark do we open the photo gallery?
			objectLocation?: NativeObjectLocation;
			// Do we remove unselected photos in the native component from the project?
			removeUnselected: boolean;
		},
	) {
		if (window.glPlatform === 'native') {
			if (!window.webToNative || !window.webToNative.onFilePickerClicked) {
				throw new Error('Missing native FilePicker hook');
			}
			if (!this.bucketModel) {
				throw new Error('Missing required auto-expire bucket model');
			}
			if (!this.bucketModel.label) {
				throw new Error('Missing required label property of bucket model');
			}
			if (!this.bucketModel.region) {
				throw new Error('Missing required label property of bucket model');
			}

			const productModel = ProductStateModule.getProduct;
			if (!productModel) {
				throw new Error('Missing required product model');
			}

			const selectedFiles: NativeSelectedFile[] = [];
			if (!max || max > 1) {
				ProductStateModule.getPhotosSelected.forEach((photoModel) => {
					selectedFiles.push({
						id: photoModel.externalId || `pid${photoModel.id}`,
						source: photoModel.source || 'upload',
						url_full: photoModel.full_url || '',
						url_web: photoModel.url || '',
						url_thumb: photoModel.thumb_url || '',
					});
				});
			}

			const productSettings = ProductStateModule.getProductSettings;
			const isSortable = (!max || max > 1)
				&& OfferingGroups(
					productModel.group,
					['BookTypes', 'PhotoSheets'],
				)
				&& typeof productSettings.autoFill === 'undefined';
			let sortMode: PI.ProductSettings['sortMode'] = isSortable ? 'Auto' : '';
			if (ProductStateModule.productSettings.sortMode) {
				sortMode = ProductStateModule.productSettings.sortMode;
			}
			const showSelection = !!(nativeOptions && nativeOptions.showSelection);

			AppStateModule.setNativeSettingsMemory({
				removeUnselected: !!(nativeOptions && nativeOptions.removeUnselected && selectedFiles.length),
			});

			const maxNrOfImages = max
				? max + selectedFiles.length // The native picker needs to have the current selection included in the max
				: 200;

			window.webToNative.onFilePickerClicked({
				maxNrOfImages,
				maxImageWidth: fullUploadSize.width,
				maxImageHeight: fullUploadSize.height,
				selectedFiles,
				awsBucketName: this.bucketModel.label,
				awsRegion: this.bucketModel.region,
				sortMode,
				showSelection,
				objectLocation: nativeOptions && typeof nativeOptions.objectLocation !== 'undefined'
					? nativeOptions.objectLocation
					: {},
			});
		} else if (this.inputField) {
			this.trackFilesChange = true;

			this.inputField.accept = this.acceptedFileTypes.join();
			this.inputField.multiple = Boolean(!max || max !== 1);
			this.inputField.click();

			// Prepare AWS credentials so we don't have to wait when user submits uploads
			this.getCognito();
		}
	}

	private get acceptedFileTypes(): MimeTypeExtended[] {
		const acceptedFileTypes = [
			...MIME_TYPES_BROWSER_SUPPORTED,
		];
		if (ConfigModule['upload.heic']) {
			acceptedFileTypes.push(
				...MIME_TYPES_HEIC,
			);
		}
		if (ConfigModule['upload.pdf']) {
			acceptedFileTypes.push(
				...MIME_TYPES_PDF,
			);
		}
		if (ConfigModule['upload.svg']) {
			acceptedFileTypes.push(
				...MIME_TYPES_SVG,
			);
		}
		if (ConfigModule['upload.eps']) {
			acceptedFileTypes.push(
				...MIME_TYPES_EPS,
			);
		}

		return acceptedFileTypes;
	}

	private fileComplete(
		uploadModel: UploadModel,
		photoModel: PI.PhotoModel,
	) {
		this.completedPhotoModels.push(photoModel);

		const offeringModel = ProductStateModule.getOffering;

		// Track analytics event
		const eventProperties: Record<string, any> = {
			fileSizeKb: uploadModel.totalBytes / 1000,
			platform: window.glPlatform,
		};
		if (uploadModel.uploadEnd && uploadModel.uploadStart) {
			eventProperties.uploadTime = (uploadModel.uploadEnd - uploadModel.uploadStart) / 1000;
		}
		if (offeringModel) {
			eventProperties.groupid = offeringModel.groupid;
		}
		if (offeringModel
			&& uploadModel.width
			&& uploadModel.height
		) {
			// Set a flag to indicate if this combination of offering and photo could benefit from using the upscaling service
			const oW = offeringModel.width * (offeringModel.qualitydpi / offeringModel.configdpi);
			const oH = offeringModel.height * (offeringModel.qualitydpi / offeringModel.configdpi);

			eventProperties.couldUseUpscaling = uploadModel.width < oW || uploadModel.height < oH;
		}

		// We apply 10% sampling to avoid going over the Amplitude/Moengage Event Usage
		const sample = Math.random() < 0.1;
		analytics.trackEvent(
			'Photo uploaded',
			eventProperties,
			{
				moengage: sample,
				amplitude: sample,
			},
		);

		if (UploadModule.waiting.length === 0
			&& UploadModule.uploading.length === 0
			&& ProductStateModule.getPhotosFailed.length === 0
		) {
			// Trigger event to indicate that all files are uploaded to auto-expire bucket
			EventBus.$emit(
				'upload complete',
				this.completedPhotoModels,
			);
		}
	}

	private getCognito(): Promise<void> {
		return awsCognito
			.checkCredentials()
			.then((changedCredentials) => {
				if (changedCredentials
					|| !this.s3
				) {
					const credentials = awsCognito.getCredentials();
					if (!credentials?.AccessKeyId
						|| !credentials?.SecretKey
					) {
						throw new Error('Missing required credentials');
					}

					// Destroy the current instance of S3 so that a new one will be build with the new credentials
					this.s3 = undefined;

					AWS.config.update({
						credentials: {
							accessKeyId: credentials.AccessKeyId,
							secretAccessKey: credentials.SecretKey,
							sessionToken: credentials.SessionToken,
						},
					});
				}
			});
	}

	public reset() {
		if (window.glPlatform === 'native') {
			if (!window.webToNative) {
				throw new Error('Missing WebToNative on window');
			}

			window.webToNative.cancelUploads();
		} else {
			this.fileTypeMapper.forEach((mapItem) => {
				this.uploadQueue.cancel(mapItem.id);
			});
		}

		// Remove all mappings (forget previously uploaded files)
		this.fileTypeMapper = [];

		if (this.inputField) {
			this.inputField.value = '';
		}

		// Reset file queues
		UploadModule.reset();
	}

	public retryAll() {
		if (UploadModule.error.length) {
			// Warning: Do not use forEach, the length of the array will change during execution
			while (UploadModule.error.length) {
				const uploadModel = UploadModule.error[0];
				this.retry(uploadModel);
			}

			UploadModule.resetError();
		}
		if (ProductStateModule.getPhotosFailed.length) {
			ProductStateModule.retryPhotoErrors();
		}
	}

	public retry(uploadModel: UploadModel) {
		if (uploadModel && uploadModel.photoModelId) {
			const photoModel = PhotosModule.getById(uploadModel.photoModelId);
			if (photoModel) {
				PhotosModule.updateModel({
					id: photoModel.id,
					_error: undefined,
				});
			}
		}

		UploadModule.moveErrorToWaiting(uploadModel.id);

		// Log the upload retry effort, so we can see how many times it happens
		if (typeof window.glBugsnagClient !== 'undefined') {
			window.glBugsnagClient.notify(
				new Error('Retry upload'),
				(event) => {
					event.severity = 'info';
					event.addMetadata(
						'uploadData',
						uploadModel,
					);
				},
			);
		}

		if (window.glPlatform === 'native') {
			if (!window.webToNative) {
				throw new Error('Missing WebToNative on window');
			}

			window.webToNative.retryUpload(uploadModel.id);
		} else {
			this.uploadFile(uploadModel);
		}
	}

	public getLocalFile(uploadId: string) {
		const mapItem = this.fileTypeMapper.find(
			(m) => m.id == uploadId,
		);
		if (mapItem) {
			return mapItem.file;
		}

		return undefined;
	}

	public cancelUpload({ id }: {
		id?: string;
	}) {
		let uploadModel: UploadModel | undefined;
		if (id) {
			uploadModel = UploadModule.find(id);
		}

		if (uploadModel) {
			if (window.glPlatform === 'native') {
				if (!window.webToNative) {
					throw new Error('Missing WebToNative on window');
				}

				window.webToNative.cancelUpload(uploadModel.id);
			} else {
				this.uploadQueue.cancel(uploadModel.id);

				// Remove from mapper, so the user could submit it again
				const i = this.fileTypeMapper.findIndex(
					(m) => m.id == id,
				);
				if (i >= 0) {
					this.fileTypeMapper.splice(
						i,
						1,
					);
				}
			}

			UploadModule.remove(uploadModel.id);
		}
	}

	private processUploadedFile(uploadModel: UploadModel) {
		if (uploadModel.photoModelId && uploadModel.url) {
			// Add photo to selected photos collection
			PhotosModule.setTemporaryUploadUrl({
				id: uploadModel.photoModelId,
				url: uploadModel.url,
			}).then((photoModel) => {
				this.fileComplete(
					uploadModel,
					photoModel,
				);
			}).catch((e) => {
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						e,
						(event) => { event.severity = 'warning'; },
					);
				}
			});
		}
	}

	private async shareProgress(data: UploadProgressMessage) {
		if (
			ProductStateModule.getProductId
			&& UserModule.id
		) {
			await importAwsAppSync();
			awsAppSyncInstance?.createMutation({
				type: 'external_upload',
				channel: `product/${ProductStateModule.getProductId}/external_upload/${UserModule.id}`,
				url: String(ConfigModule['appSync.url']),
				event: 'upload',
				payload: data,
			});

			if (data.progressPercentage >= 100) {
				awsAppSyncInstance?.createMutation({
					type: 'external_upload',
					channel: `product/${ProductStateModule.getProductId}/external_upload/${UserModule.id}`,
					url: String(ConfigModule['appSync.url']),
					event: 'complete',
					payload: data,
				});
			}
		}
	}

	private shareProgressThrottled = _.throttle(
		this.shareProgress,
		200,
	);

	private externalUploadsReady() {
		const promises: Promise<PI.PhotoModel>[] = [];
		const photoIds = this.externalUploads.map((photo) => photo.id);
		photoIds.forEach((photoId) => {
			const photoModel = PhotosModule.getById(photoId);
			if (!photoModel
				&& typeof photoId === 'number'
			) {
				promises.push(PhotosModule.fetchModel({
					id: photoId,
				}));
			}
		});

		Promise.allSettled(promises).then(() => {
			ProductStateModule.addPhotos(photoIds);

			this.closeProgressDialog();

			EventBus.$emit(
				'external upload completed',
				photoIds,
			);
		});
	}

	public showQRCode(): Promise<void> {
		const closeLoaderDialog = DialogService.openLoaderDialog();

		this.externalUploads = [];

		return ProductStateModule.getQRCodeForExternalUpload
			.then(({ image, url }) => {
				closeLoaderDialog();

				this.uploadQRDialog = DialogService.openDialogNew({
					header: {
						hasCloseButton: false,
					},
					body: {
						component: UploadQrView,
						props: {
							qrImage: image,
							qrLink: url,
						},
						listeners: {
							close: async () => {
								await importAwsAppSync();
								awsAppSyncInstance?.createMutation({
									type: 'external_upload',
									channel: `product/${ProductStateModule.getProductId}/external_upload/${UserModule.id}`,
									url: String(ConfigModule['appSync.url']),
									event: 'abort',
									payload: {},
								});

								this.closeQRDialog();
							},
						},
					},
					theme: 'light',
					width: 460,
				});
			}).catch((err) => {
				closeLoaderDialog();
				DialogService.openErrorDialog({
					body: {
						content: err.message,
					},
				});
			});
	}

	public updateProgress(
		data: UploadProgressMessage,
		fromExternalUpload: boolean,
	) {
		if (AppStateModule.uploadOnly) {
			this.shareProgressThrottled(
				data,
			);
		} else if (this.uploadQRDialog) {
			this.closeQRDialog();
		}

		if (fromExternalUpload) {
			this.externalUploads = data.photos ?? [];
		}

		if (this.uploadProgressDialog) {
			const apiProgress = this.uploadProgressDialog.api;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const uploadProgressComponent = apiProgress.bodyComponent()!;

			if (data.progressPercentage
				&& data.progressPercentage !== uploadProgressComponent.progressPercentage
			) {
				uploadProgressComponent.progressPercentage = data.progressPercentage;
			}
			if (data.submittedCount
				&& data.submittedCount !== uploadProgressComponent.submittedCount
			) {
				uploadProgressComponent.submittedCount = data.submittedCount;
			}
			if (data.photos
				&& data.photos.length !== uploadProgressComponent.photos.length
			) {
				const photoUrls = data.photos
					.map((photo) => photo.url)
					.filter((url): url is string => !!url);
				uploadProgressComponent.photos = photoUrls;
			}
		} else if (data.submittedCount
			&& data.progressPercentage < 100
			&& (AppStateModule.uploadOnly || fromExternalUpload)
		) {
			this.uploadProgressDialog = DialogService.openDialogNew({
				header: {
					hasCloseButton: false,
				},
				body: {
					component: UploadProgressView,
					props: {
						progressPercentage: data.progressPercentage || 0,
						submittedCount: data.submittedCount,
						photos: [],
						standalone: AppStateModule.uploadOnly,
					},
					listeners: {
						cancel: () => {
							this.reset();
							this.closeProgressDialog();
						},
						close: async () => {
							await importAwsAppSync();
							awsAppSyncInstance?.createMutation({
								type: 'external_upload',
								channel: `product/${ProductStateModule.getProductId}/external_upload/${UserModule.id}`,
								url: String(ConfigModule['appSync.url']),
								event: 'close',
								payload: {},
							});

							this.closeProgressDialog();
						},
						startEditing: async () => {
							await importAwsAppSync();
							awsAppSyncInstance?.createMutation({
								type: 'external_upload',
								channel: `product/${ProductStateModule.getProductId}/external_upload/${UserModule.id}`,
								url: String(ConfigModule['appSync.url']),
								event: 'close',
								payload: {},
							});

							this.externalUploadsReady();
						},
					},
				},
				theme: 'light',
				width: 720,
			});
		}
	}

	private uploadSubmit(batchSize: number) {
		this.trackFilesChange = false;
		this.completedPhotoModels = [];

		if (batchSize > 0) {
			ProductStateModule.flagChange();
		}

		if (batchSize > 1) {
			// Show progress dialog for the validation of the submitted files
			this.closeValidationDialog();

			this.validationDialog = DialogService.openProgressDialog({
				header: {
					title: window.App.router.$t('uploadStatusValidating'),
				},
				body: {
					props: {
						value: 0,
						total: batchSize,
					},
					listeners: {
						complete: () => {
							this.closeValidationDialog();
						},
					},
				},
			});
		}

		// Trigger event to indicate start of processing new selected upload files
		EventBus.$emit(
			'upload submit',
			batchSize,
		);
	}

	public get getUploadProgressPercentage(): number {
		const totalSelectedPhotos = ProductStateModule.getPhotosSelected.length;
		const uploadProgress = UploadModule.totalBytes > 0
			? UploadModule.totalUploadBytes / UploadModule.totalBytes
			: 1;
		const saveProgress = totalSelectedPhotos > 0
			? (totalSelectedPhotos - ProductStateModule.getPhotosQueued.length) / totalSelectedPhotos
			: 1;

		if (UploadModule.totalBytes) {
			return 100 * (0.75 * uploadProgress + 0.25 * saveProgress);
		}

		// Photos selected from cloud, just show saving progress
		return 100 * saveProgress;
	}

	private uploadSubmitted(batchSize: number) {
		// Automatically close the progress dialog in case that is displayed
		if (this.validationDialog) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const dialogProgressComponent = this.validationDialog.api.bodyComponent()!;
			dialogProgressComponent.value = dialogProgressComponent.total;
		}

		if (this.inputField) {
			this.inputField.value = '';
		}

		if (this.rejectFileSizeCount > 0) {
			const closeError = DialogService.openErrorDialog({
				body: {
					content: window.App.router.$t(
						'uploadErrorSize',
						{
							fileCount: this.rejectFileSizeCount,
							sizeLimit: this.sizeLimit,
						},
					),
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: window.App.router.$t('dialogButtonErrorOk'),
							click: () => {
								this.rejectFileSizeCount = 0;
								this.uploadSubmitted(batchSize);
								closeError();
							},
						},
					],
				},
			});
		} else if (this.checkMaxPhotos && this.rejectBatchSizeCount > 0) {
			// Check if batch size exceeds the photo limit for the product
			const { themeModel } = ThemeStateModule;
			const maxphotos = !themeModel || _.isNull(themeModel.maxphotos)
				? 200
				: themeModel.maxphotos;

			const closeError = DialogService.openErrorDialog({
				body: {
					content: window.App.router.$t(
						'uploadErrorTooMaanyPhotos',
						{
							maxphotos,
							skipcount: this.rejectBatchSizeCount,
						},
					),
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: window.App.router.$t('dialogButtonErrorOk'),
							click: () => {
								this.rejectBatchSizeCount = 0;
								this.uploadSubmitted(batchSize);
								closeError();
							},
						},
					],
				},
			});
		} else {
			// Trigger event to indicate that all new submitted upload are either accepted or
			// rejected by the upload controller
			EventBus.$emit(
				'upload submitted',
				batchSize,
			);

			if (batchSize > 0) {
				let interval = 0;

				this.updateProgress(
					{
						progressPercentage: 0,
						submittedCount: batchSize,
						photos: [],
					},
					false,
				);

				// Update progress dialog
				interval = window.setInterval(
					() => {
						this.updateProgress(
							{
								progressPercentage: this.getUploadProgressPercentage,
								submittedCount: batchSize,
							},
							false,
						);

						if (this.getUploadProgressPercentage >= 100) {
							window.clearInterval(interval);
							this.closeProgressDialog();

							const photos: UploadProgressPhoto[] = [];

							const arrPromises: Promise<string | File>[] = [];
							this.completedPhotoModels.forEach((photoModel) => {
								arrPromises.push(
									PhotosModule.getModelUrl({
										id: photoModel.id,
										resolution: 'thumb',
										forceRemote: true,
									}),
								);
							});

							Promise.allSettled(arrPromises).then((results) => {
								this.completedPhotoModels.forEach((photoModel, i) => {
									const promiseResult = results[i];
									if (promiseResult.status === 'fulfilled'
										&& typeof promiseResult.value === 'string'
									) {
										photos.push({
											id: photoModel.id,
											url: promiseResult.value,
										});
									} else {
										photos.push({
											id: photoModel.id,
										});
									}
								});

								this.updateProgress(
									{
										progressPercentage: 100,
										submittedCount: batchSize,
										photos,
									},
									false,
								);
							});
						} else if (
							ProductStateModule.getPhotosQueued.length
							&& AppStateModule.online
							&& !AppStateModule.sync
						) {
							// Save process seems to be stuck so give it a kickstart
							ProductState.save();
						}
					},
					100,
				);
			}
		}
	}

	public waitForDecodings(): Promise<void> {
		const photoModelsToConvert = ProductStateModule.getPhotosQueued.filter(
			(photoModel) => (
				photoModel._type && MIME_TYPES_REQUIRE_CONVERSION.indexOf(photoModel._type) >= 0
			),
		);
		if (photoModelsToConvert.length) {
			analytics.trackEvent(
				'Photo conversion needed',
				{
					groupid: ProductStateModule.getProduct?.group,
					heic: photoModelsToConvert.filter(
						(photoModel) => photoModel._type
							&& MIME_TYPES_HEIC.includes(photoModel._type),
					).length,
					vectors: photoModelsToConvert.filter(
						(photoModel) => photoModel._type
							&& MIME_TYPES_EPS.includes(photoModel._type),
					).length,
					pdf: photoModelsToConvert.filter(
						(photoModel) => photoModel._type
							&& MIME_TYPES_PDF.includes(photoModel._type),
					).length,
				},
			);
		}

		if (photoModelsToConvert.length === 0
			|| AppStateModule.uploadOnly
		) {
			return Promise.resolve();
		}

		return new Promise((resolve) => {
			// Add childView to dialog
			const {
				close: closeDialog,
				api: apiDialog,
			} = DialogService.openDialog({
				header: {
					hasCloseButton: false,
				},
				body: {
					component: fileConversionView,
					props: {
						offeringType: ProductStateModule.getOffering?.type || 'photo',
						photosProcessed: [],
						progressPercentage: 0,
						submittedCount: photoModelsToConvert.length,
					},
					listeners: {
						closeDialog: () => {
							resolve();
							closeDialog();
						},
					},
				},
				width: 600,
			});

			// Wait for heic uploads to be fully processed
			const interval = window.setInterval(
				() => {
					const uploadProgress = UploadModule.totalBytesSpecial > 0
						? UploadModule.totalUploadBytesSpecial / UploadModule.totalBytesSpecial
						: 100;

					// Get all photos that are either processed or ended in an upload error
					const photosProcessed: PI.PhotoModel[] = [];
					const photosErrors: PI.PhotoModel[] = [];
					photoModelsToConvert.forEach((photoModelToConvert) => {
						const photoModel = PhotosModule.findWhere({
							_localRef: photoModelToConvert._localRef,
						});
						if (photoModel
							&& typeof photoModel.id !== 'string'
						) {
							photosProcessed.push(photoModel);
						} else if (photoModel?._error) {
							photosErrors.push(photoModel);
						}
					});

					// Get the progress on the processing of these files
					const saveProgress = photosProcessed.length + photosErrors.length
						? (photosProcessed.length + photosErrors.length) / photoModelsToConvert.length
						: 0;

					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const fileConversionComponent = apiDialog.bodyComponent()!;

					if (UploadModule.totalBytesSpecial) {
						const progressPercentage = Math.floor(100 * (0.75 * uploadProgress + 0.25 * saveProgress));
						fileConversionComponent.progressPercentage = progressPercentage;
					} else {
						// Photos selected from cloud, just show saving progress
						const progressPercentage = Math.floor(100 * saveProgress);
						fileConversionComponent.progressPercentage = progressPercentage;
					}

					if (fileConversionComponent.progressPercentage >= 100) {
						window.clearInterval(interval);
					}
				},
				100,
			);
		});
	}
}

export default new Upload();
