import './defines';
import {
	ArcRotateCamera,
	Color4,
	Engine,
	HDRCubeTexture,
	PBRMaterial,
	Scene,
	SceneLoader,
	Texture,
	Vector3,
} from 'babylonjs';
import 'babylonjs-loaders';
import {
	EditorPreviewPageObjectModels,
	OfferingFrameModel,
} from 'interfaces/app';
import {
	Model3DModel,
	OfferingModel,
	PageModel,
} from 'interfaces/database';
import {
	url as urlTools,
	viewport as viewportTools,
} from 'tools';
import getCanvasSize from 'tools/get-canvas-size';
import EditorDrawView from 'views/editor-draw';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
// @ts-ignore: special file format
import environmentFile from './environment.env';
import Template from './template.vue';

@Component({
	name: 'EditorPreview3DView',
	components: {
		EditorDrawView,
	},
})
export default class EditorPreview3DView extends Vue.extend(Template) {
	@Prop({
		default: 0,
		type: Number,
	})
	public readonly bleedMargin!: number;

	@Prop({
		default: undefined,
		schema: 'OfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: OfferingFrameModel;

	@Prop({
		required: true,
		schema: 'OfferingModel',
		type: Object,
	})
	public readonly offeringModel!: OfferingModel;

	@Prop({
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		required: true,
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects!: EditorPreviewPageObjectModels;

	@Prop({
		required: true,
		schema: 'Model3DModel',
		type: Object,
	})
	public readonly value!: Model3DModel;

	protected get computedBleedMargin(): number {
		if (this.value.includeBleed) {
			return this.bleedMargin;
		}

		return 0;
	}

	protected get computedFullScaling(): number {
		const canvasHeight = Math.round(
			this.pageModel.height + (this.bleedMargin * 2),
		);
		const canvasWidth = Math.round(
			this.pageModel.width + (this.bleedMargin * 2),
		);

		const canvasSize = getCanvasSize(
			canvasWidth,
			canvasHeight,
		);

		return canvasSize.width / canvasWidth;
	}

	private get isReady(): boolean {
		return (
			this.previewReady
			&& this.sceneLoaded
		);
	}

	@Ref('canvas')
	private readonly canvasElement!: HTMLCanvasElement;

	private editorDrawCanvasElement?: HTMLCanvasElement;

	private engine!: Engine;

	private previewReady = false;

	private scene!: Scene;

	private sceneLoaded = false;

	private viewportUnwatch?: () => void;

	protected beforeDestroy(): void {
		this.viewportUnwatch?.();
	}

	protected mounted(): void {
		this.loadScene();
	}

	@Watch('isReady')
	protected isReadyChange(): void {
		if (this.isReady) {
			this.scene.createDefaultCamera(
				true,
				true,
				true,
			);

			let camera: ArcRotateCamera | undefined;

			if (this.scene.activeCamera) {
				camera = this.scene.activeCamera as ArcRotateCamera;

				// Limit zoom speed when using scroll wheel on mouse
				camera.wheelPrecision = 200;
				camera.pinchPrecision = 500;
				camera.panningSensibility = 5000;

				// Let camera automatically rotate around object
				camera.useAutoRotationBehavior = Boolean(this.value.cameraAutoRotate);

				if (camera.autoRotationBehavior) {
					// Set auto rotation speed
					camera.autoRotationBehavior.idleRotationSpeed = this.value.cameraRotationSpeed;
				}
			}

			this.scene.clearColor = new Color4(
				0,
				0,
				0,
				0,
			);

			if (this.value.envTextureUrl) {
				const texture = new HDRCubeTexture(
					this.value.envTextureUrl,
					this.scene,
					64,
					false,
					true,
					false,
					true,
				);
				this.scene.environmentIntensity = this.value.envIntensity;
				this.scene.createDefaultEnvironment({
					createSkybox: false,
					createGround: false,
					environmentTexture: texture,
				});
			} else {
				this.scene.createDefaultEnvironment({
					createSkybox: false,
					createGround: false,
					environmentTexture: environmentFile,
				});
				if (this.value?.envIntensity) {
					this.scene.environmentIntensity = this.value.envIntensity;
				}
			}

			if (camera) {
				// Optionally tilt the camera to get more 3d perspective
				camera.alpha += this.value.cameraAlpha;
				camera.beta += this.value.cameraBeta;
				// Calculate and set the proper camera radius to fit the object in the available space
				camera.radius = this.setCameraToFitObject();
			}

			this.addTextureToModel();
			this.engine.runRenderLoop(() => {
				if (
					this.scene
					&& !this.scene.isDisposed
				) {
					this.scene.render();
				}
			});

			if (!this.viewportUnwatch) {
				this.viewportUnwatch = viewportTools.watch(() => {
					const rootElement = this.$el as HTMLDivElement;
					this.canvasElement.height = 10;
					this.canvasElement.width = 10;

					requestAnimationFrame(() => {
						rootElement.style.maxHeight = '';
						rootElement.style.maxWidth = '';
						rootElement.style.maxHeight = `${rootElement.clientHeight}px`;
						rootElement.style.maxWidth = `${rootElement.clientWidth}px`;
						this.canvasElement.height = rootElement.clientHeight;
						this.canvasElement.width = rootElement.clientWidth;
						this.engine.resize();

						if (camera) {
							// Calculate and set the proper camera radius to fit the object in the available space
							camera.radius = this.setCameraToFitObject();
						}
					});
				});
			}
		} else {
			this.loadScene();
		}
	}

	@Watch('value')
	protected onValueChange(): void {
		this.loadScene();
	}

	private addTextureToModel(): void {
		const { editorDrawCanvasElement } = this;

		if (
			editorDrawCanvasElement
			&& editorDrawCanvasElement.width > 0
		) {
			const renderedImage = editorDrawCanvasElement.toDataURL();
			const texture = new Texture(
				'data:pageImage',
				this.scene,
				false,
				false,
				Texture.BILINEAR_SAMPLINGMODE,
				null,
				null,
				renderedImage,
				true,
			);
			this.scene.addTexture(texture);

			if (this.value.printMaterialName) {
				const printArea = this.scene.getMaterialById<PBRMaterial>(this.value.printMaterialName);

				if (printArea) {
					if (this.value.transparencyMode) {
						printArea.transparencyMode = this.value.transparencyMode;
					}

					printArea.albedoTexture = texture;
				}
			}

			if (
				this.value.textureMaterialName
				&& this.value.textureUrl
			) {
				const textureArea = this.scene.getMaterialById<PBRMaterial>(this.value.textureMaterialName);

				if (textureArea) {
					const textureMaterialTexture = new Texture(
						this.value.textureUrl,
						this.scene,
						false,
						false,
						Texture.BILINEAR_SAMPLINGMODE,
						null,
						null,
						undefined,
						true,
					);
					this.scene.addTexture(textureMaterialTexture);
					textureArea.albedoTexture = textureMaterialTexture;
				}
			}
		}
	}

	private loadScene(): void {
		this.sceneLoaded = false;
		const rootElement = this.$el as HTMLDivElement;

		if (
			rootElement.clientHeight === 0
			|| rootElement.clientWidth === 0
		) {
			requestAnimationFrame(this.loadScene);
			return;
		}

		if (!rootElement.style.maxHeight) {
			rootElement.style.maxHeight = `${rootElement.clientHeight}px`;
		}
		if (!rootElement.style.maxWidth) {
			rootElement.style.maxWidth = `${rootElement.clientWidth}px`;
		}

		this.engine?.dispose();
		this.canvasElement.height = rootElement.clientHeight;
		this.canvasElement.width = rootElement.clientWidth;
		this.engine = new Engine(
			this.canvasElement,
			true,
		);
		/**
		 * Disable the babylon loading screen
		 */
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.engine.displayLoadingUI = () => { };
		this.scene?.dispose();
		this.scene = new Scene(this.engine);

		const parsedUrl = urlTools.parse(this.value.sceneUrl);
		const pathWithoutFileName = `${parsedUrl.path.substring(
			0,
			parsedUrl.path.lastIndexOf('/'),
		)}/`;
		const urlWithoutFileName = `${parsedUrl.protocol}//${parsedUrl.host}${pathWithoutFileName}`;

		SceneLoader
			.AppendAsync(
				urlWithoutFileName,
				parsedUrl.fileName,
				this.scene,
			)
			.then(() => {
				this.sceneLoaded = true;
			});
	}

	protected onCanvasDrawn(canvasElement: HTMLCanvasElement): void {
		if (canvasElement) {
			this.previewReady = false;
			this.editorDrawCanvasElement = canvasElement;

			if (
				this.previewReady
				&& this.value.printMaterialName
			) {
				const printArea = this.scene.getMaterialById<PBRMaterial>(this.value.printMaterialName);

				if (printArea) {
					// The page-image has been updated, so we need to refresh the material in the scene to reflect the changes
					printArea.albedoTexture?.dispose();
					this.addTextureToModel();
				}
			}

			this.previewReady = true;
		}
	}

	private setCameraToFitObject(): number {
		/**
		 * Initialize the max vector to track the bounding box of the model
		 */
		let size = new Vector3(
			Number.NEGATIVE_INFINITY,
			Number.NEGATIVE_INFINITY,
			Number.NEGATIVE_INFINITY,
		);

		/**
		 * Iterate through each mesh in the scene to find the max bounding box
		 */
		this.scene.meshes.forEach((mesh) => {
			if (
				mesh.isVisible
				&& mesh.getTotalVertices() > 0
			) {
				const boundingInfo = mesh.getBoundingInfo();
				const maximum = boundingInfo.boundingBox.maximumWorld;

				/**
				 * Update the max vectors
				 */
				size = Vector3.Maximize(
					size,
					maximum,
				);
			}
		});

		/**
		 * Get the viewport dimensions
		 */
		const canvasWidth = this.canvasElement.width;
		const canvasHeight = this.canvasElement.height;

		/**
		 * Calculate the aspect ratio of the model and the viewport
		 */
		const modelIsLandscape = size.x > size.y;
		const viewportIsLandscape = canvasWidth > canvasHeight;

		let smallScalingFactor: number;
		let highScalingFactor: number;

		/**
		 * Set the scaling factor based on the aspect ratio of the model
		 * and the viewport, also set two scaling factors to later blend
		 * between them based on the size of the model.
		 * Values are based on try and error to get the best fit.
		 */
		if (
			modelIsLandscape
			&& viewportIsLandscape
		) {
			smallScalingFactor = 0.45;
			highScalingFactor = 0.45;
		} else if (
			modelIsLandscape
			&& !viewportIsLandscape
		) {
			smallScalingFactor = 0.20;
			highScalingFactor = 0.23;
		} else if (
			!modelIsLandscape
			&& viewportIsLandscape
		) {
			smallScalingFactor = 0.30;
			highScalingFactor = 0.40;
		} else {
			smallScalingFactor = 0.20;
			highScalingFactor = 0.30;
		}

		if (
			(
				modelIsLandscape
				&& !viewportIsLandscape
			)
			|| !modelIsLandscape
		) {
			const normalizedSize = new Vector3(
				size.x,
				size.y,
				size.z,
			);

			/**
			 * There are some cases where the model axes are greater than 1,
			 * so in these cases we loop through and reduce the scaling factor
			 * so the model fits the canvas (meant for mobile devices).
			 * Values are based on try and error to get the best fit.
			 */
			while (
				normalizedSize.x > 1
				|| normalizedSize.y > 1
			) {
				normalizedSize.x -= 1;
				normalizedSize.y -= 1;
				smallScalingFactor /= 0.91;
				highScalingFactor /= 1.01;
			}
		}

		const maxDimension = Math.max(
			size.x,
			size.y,
		);
		const blendFactor = 1 - Math.exp(-5 * maxDimension);
		const finalScalingFactor = (smallScalingFactor * (1 - blendFactor)) + (highScalingFactor * blendFactor);

		return maxDimension / finalScalingFactor;
	}
}
