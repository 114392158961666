import {
	ColorUtilsGetDominantOptions,
	ColorUtilsGetPalletFinalOptions,
	ColorUtilsGetPalletOptions,
} from 'interfaces/app';
import quantize, { RgbPixel } from 'quantize';

/**
 * Compare two colors to see if they are the same
 * @param color1 Color 1, either as an array of RGB values or a hex string
 * @param color2 Color 2, either as an array of RGB values or a hex string
 * @returns Whether the colors are the same (true) or different (false)
 */
export function compareColors(
	color1?: [number, number, number] | string,
	color2?: [number, number, number] | string,
): boolean {
	if (typeof color1 === 'undefined') {
		return typeof color2 === 'undefined';
	}
	if (typeof color2 === 'undefined') {
		return typeof color1 === 'undefined';
	}

	if (typeof color1 === 'string') {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		color1 = getRGBFromHex(color1);
	}
	if (typeof color2 === 'string') {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		color2 = getRGBFromHex(color2);
	}

	const red = Math.abs(color1[0] - color2[0]);
	const green = Math.abs(color1[1] - color2[1]);
	const blue = Math.abs(color1[2] - color2[2]);

	return (red + green + blue) === 0;
}

export function getContrastRatio(
	color1: [number, number, number] | string,
	color2: [number, number, number] | string,
): number {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const luminance1 = getLuminance(
		...(typeof color1 === 'string'
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			? getRGBFromHex(color1)
			: color1
		),
	);
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const luminance2 = getLuminance(
		...(typeof color2 === 'string'
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			? getRGBFromHex(color2)
			: color2
		),
	);
	const brightest = Math.max(
		luminance1,
		luminance2,
	);
	const darkest = Math.min(
		luminance1,
		luminance2,
	);

	return (brightest + 0.05) / (darkest + 0.05);
}

export function getDominant(options: ColorUtilsGetDominantOptions): RgbPixel {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const palette = getPalette({
		...options,
		colorCount: 1,
	});

	if (palette) {
		return palette[0];
	}

	return [255, 255, 255];
}

export function getHexFromRGB(
	red: number,
	green: number,
	blue: number,
): string {
	// eslint-disable-next-line no-bitwise
	return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}`;
}

export function getLuminance(
	red: number,
	green: number,
	blue: number,
): number {
	const a = [red, green, blue].map((v) => {
		v /= 255;
		return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
	});

	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function getPalette(options: ColorUtilsGetPalletOptions): RgbPixel[] | null {
	const finalOptions: ColorUtilsGetPalletFinalOptions = {
		colorCount: 10,
		quality: 4,
	} as ColorUtilsGetPalletFinalOptions;

	if (
		typeof options.colorCount !== 'undefined'
		&& Number.isInteger(options.colorCount)
	) {
		finalOptions.colorCount = Math.max(
			options.colorCount,
			1,
		);
		finalOptions.colorCount = Math.min(
			finalOptions.colorCount,
			20,
		);
	}

	if (typeof options.backgroundColor !== 'undefined') {
		finalOptions.backgroundColor = options.backgroundColor;
	} else {
		finalOptions.backgroundColor = '#FFFFFF';
	}

	if (
		typeof options.quality !== 'undefined'
		&& Number.isInteger(options.quality)
		&& options.quality > 0
	) {
		finalOptions.quality = options.quality;
	}

	let canvas: HTMLCanvasElement;
	let canvasContext: CanvasRenderingContext2D;

	if (options.sourceImage instanceof HTMLCanvasElement) {
		canvas = options.sourceImage;
		canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
	} else {
		canvas = document.createElement('canvas');
		canvas.height = options.sourceImage.naturalHeight;
		canvas.width = options.sourceImage.naturalWidth;
		canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
		canvasContext.fillStyle = finalOptions.backgroundColor;
		canvasContext.fillRect(
			0,
			0,
			canvas.width,
			canvas.height,
		);
		canvasContext.drawImage(
			options.sourceImage,
			0,
			0,
			canvas.width,
			canvas.height,
		);
	}

	if (typeof options.region === 'undefined') {
		finalOptions.region = {
			height: canvas.height,
			width: canvas.width,
			x: 0,
			y: 0,
		};
	} else {
		finalOptions.region = options.region;
	}

	const imageData = canvasContext.getImageData(
		finalOptions.region.x,
		finalOptions.region.y,
		finalOptions.region.width,
		finalOptions.region.height,
	);
	const pixelCount = finalOptions.region.width * finalOptions.region.height;
	const pixelArray: RgbPixel[] = [];

	for (let pixelIndex = 0; pixelIndex < pixelCount; pixelIndex += finalOptions.quality) {
		const r = imageData.data[pixelIndex + 0];
		const g = imageData.data[pixelIndex + 1];
		const b = imageData.data[pixelIndex + 2];
		const a = imageData.data[pixelIndex + 3];

		/**
		 * If pixel is mostly opaque and not white
		 */
		if (
			typeof a === 'undefined'
			|| a >= 125
		) {
			pixelArray.push([r, g, b]);
		}
	}

	/**
	 * Send array to quantize function which clusters values
	 * using median cut algorithm
	 */
	const colorMap = quantize(
		pixelArray,
		finalOptions.colorCount,
	);

	if (colorMap) {
		return colorMap.palette();
	}

	return null;
}

export function getRGBFromRGBString(
	color: string,
): [number, number, number] {
	const rgb = color
		.replace(
			'rgb(',
			'',
		)
		.replace(
			')',
			'',
		)
		.split(
			',',
		)
		.map(
			(v) => parseInt(
				v,
				10,
			),
		);

	return rgb as [number, number, number];
}

export function getRGBFromHex(
	color: string,
): [number, number, number] {
	if (color.includes('rgb')) {
		return getRGBFromRGBString(color);
	}

	const hex = color.replace(
		'#',
		'',
	);

	if (hex.length === 3) {
		// Extract each color component directly from the shorthand
		const red = parseInt(
			hex.charAt(0),
			16,
		);
		const green = parseInt(
			hex.charAt(1),
			16,
		);
		const blue = parseInt(
			hex.charAt(2),
			16,
		);

		// Scale the color values. Since each value is from 0 to 15,
		// multiplying by 17 scales it to the 0 to 255 range.
		return [red * 17, green * 17, blue * 17];
	}

	// Process as before if not in shorthand
	const bigint = parseInt(
		hex,
		16,
	);
	// eslint-disable-next-line no-bitwise
	const red = (bigint >> 16) & 255;
	// eslint-disable-next-line no-bitwise
	const green = (bigint >> 8) & 255;
	// eslint-disable-next-line no-bitwise
	const blue = bigint & 255;

	return [red, green, blue];
}

export function validateColor(
	color?: string,
): boolean {
	if (typeof color === 'undefined') {
		return false;
	}
	if (/^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/i.test(color)) {
		return true;
	}
	if (/^rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\)$/i.test(color)) {
		return true;
	}
	if (/^rgba\(\d{1,3},\s*\d{1,3},\s*\d{1,3},\s*(0(\.\d+)?|1(\.0+)?)\)$/i.test(color)) {
		return true;
	}

	return false;
}
