export function appendParameter(
	url: string,
	key: string,
	value?: string,
): string {
	if (url.indexOf('?') >= 0) {
		url += '&';
	} else {
		url += '?';
	}

	url += encodeURIComponent(key);

	if (value) {
		url += `=${encodeURIComponent(value)}`;
	}

	return url;
}
// Source: https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
export function getJSONFromQueryParams(url: string) {
	const question = url.indexOf('?');
	let hash = url.indexOf('#');

	if (
		hash === -1
		&& question === -1
	) {
		return {};
	}

	if (hash === -1) {
		hash = url.length;
	}

	const query = (
		(
			question == -1
			|| hash == question + 1
		)
			? url.substring(hash)
			: url.substring(
				question + 1,
				hash,
			)
	);
	const result: Record<string, any> = {};
	query
		.split('&')
		.forEach((part) => {
			if (!part) {
				return;
			}

			part = part
				.split('+')
				.join(' '); // replace every + with space, regexp-free version
			const eq = part.indexOf('=');
			let key = (
				eq > -1
					? part.substr(
						0,
						eq,
					)
					: part);
			const val = (
				eq > -1
					? decodeURIComponent(part.substr(eq + 1))
					: ''
			);
			const from = key.indexOf('[');

			if (from === -1) {
				result[decodeURIComponent(key)] = val;
			} else {
				const to = key.indexOf(
					']',
					from,
				);
				const index = decodeURIComponent(key.substring(
					from + 1,
					to,
				));
				key = decodeURIComponent(key.substring(
					0,
					from,
				));

				if (!result[key]) {
					result[key] = [];
				}
				if (!index) {
					result[key].push(val);
				} else {
					result[key][index] = val;
				}
			}
		});

	return result;
}

// Source: https://gist.github.com/jlong/2428561
export function parse(url: string) {
	const parser = document.createElement('a');
	parser.href = url;
	const parameters = getJSONFromQueryParams(url);

	return {
		protocol: parser.protocol, // => "http:"
		hostname: parser.hostname, // => "example.com"
		port: parser.port, // => "3000"
		path: parser.pathname, // => "/pathname/"
		query: parser.search, // => "?search=test"
		hash: parser.hash, // => "#hash"
		host: parser.host, // => "example.com:3000"
		parameters,
		fileName: url.substring(url.lastIndexOf('/') + 1),
		extension: url.substring(url.lastIndexOf('.') + 1),
	};
}
