import './defines';
import DialogComponent from 'components/dialog-new';
import ToolbarComponent from 'components/toolbar';
import TooltipComponent from 'components/tooltip';
import {
	EditorPreviewPageObjectModels,
	FullOfferingModels,
	FullOfferingOptionIntersectModel,
	FullOfferingOptionVirtualIntersectModel,
	OfferingOptionsSummaryToolbarCurrencyModel,
	OfferingOptionsSummaryToolbarFilterTag,
	OfferingOptionsSummaryToolbarFilterTags,
	OfferingOptionsSummaryToolbarFullOfferingModel,
	OfferingOptionsSummaryToolbarFullOfferingModels,
	OfferingOptionsSummaryToolbarFullOfferingOptionModel,
	OfferingOptionsSummaryToolbarOfferingOptionValueModel,
} from 'interfaces/app';
import { Model2DModel } from 'interfaces/database';
import { PageModel } from 'interfaces/project';
import { AppDataModule } from 'store';
import { mobile as mobileTools } from 'tools';
import OfferingOptionsToolbarView from 'views/offering-options-toolbar';
import PriceView from 'views/price';
import {
	VueConstructor,
	VueConstructorExtended,
} from 'vue';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'OfferingOptionsSummaryToolbarView',
	components: {
		PriceView,
	},
})
export default class OfferingOptionsSummaryToolbarView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the full offering model active for the current project',
			required: true,
			schema: 'OfferingOptionsSummaryToolbarFullOfferingModel',
			type: Object,
		},
	)
	public readonly value!: OfferingOptionsSummaryToolbarFullOfferingModel;

	@Prop({
		default: undefined,
		description: "Defines the bleed margin for the current project to be used to show the product's preview by the `OfferingOptionsToolbarView` component",
		type: Number,
	})
	public readonly bleedMargin?: number;

	@Prop({
		description: 'Defines the currency model to be used to show the price',
		required: true,
		schema: 'OfferingOptionsSummaryToolbarCurrencyModel',
		type: Object,
	})
	public readonly currencyModel!: OfferingOptionsSummaryToolbarCurrencyModel;

	@Prop({
		default: undefined,
		description: 'Defines the filter tags to filter out the offering models, the order of the tags will define the order the tags will be shown in the toolbar',
		schema: 'OfferingOptionsSummaryToolbarFilterTags',
		type: Array,
	})
	public readonly filterTags?: OfferingOptionsSummaryToolbarFilterTags;

	@Prop({
		description: 'Defines the full offering models available for the current project',
		required: true,
		schema: 'OfferingOptionsSummaryToolbarFullOfferingModels',
		type: Array,
	})
	public readonly fullOfferingModels!: OfferingOptionsSummaryToolbarFullOfferingModels;

	@Prop({
		description: 'Indicates if the project is a single page project, used to determine whether to show the pricing or not',
		required: true,
		type: Boolean,
	})
	public readonly isProjectSinglePage!: boolean;

	@Prop({
		description: 'Indicates if the component should only show the offering name without showing the summary of the selected options',
		default: false,
		type: Boolean,
	})
	public readonly onlyOfferingName?: boolean;

	@Prop({
		description: 'Indicates if the component should only show the summary without the ability to change the selected options',
		default: false,
		type: Boolean,
	})
	public readonly onlySummary?: boolean;

	@Prop({
		default: undefined,
		description: "Defines the page model to be used to show the product's preview by the `OfferingOptionsToolbarView` component",
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel?: PageModel;

	@Prop({
		default: undefined,
		description: "Defines the page objects to be used to show the product's preview by the `OfferingOptionsToolbarView` component",
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects?: EditorPreviewPageObjectModels;

	@Prop({
		default: undefined,
		description: "Defines the 2D preview model to be use to show the product's preview by the `OfferingOptionsToolbarView` component",
		schema: 'Model2DModel',
		type: Object,
	})
	public readonly previewModel?: Model2DModel;

	private get isSingleOption(): boolean {
		return this.selectedOptionsFilteredToShow.length === 1;
	}

	private get isVirtualOffering(): boolean {
		if (this.internalValue) {
			return !!AppDataModule.findOffering({
				flexgroupid: this.internalValue.flexgroupid,
				groupid: this.internalValue.groupid,
				virtual: 1,
			}).length;
		}

		return false;
	}

	private get noOfferingOptions(): boolean {
		return (
			this.selectedOptionsFilteredToShow.length === 0
			|| (
				this.isSingleOption
				&& this.productOptions
					.find((option) => option.id === this.selectedOptionsFilteredToShow[0].id)
					?.items.length === 1
			)
		);
	}

	protected get offeringModelPrice(): number {
		if (!this.currencyModel) {
			return 0;
		}

		const pricingModel = AppDataModule.findPricingWhere({
			offeringid: this.internalValue.id,
			currency: this.currencyModel.id,
		});

		return pricingModel?.price_base || 0;
	}

	protected get productOptions(): (FullOfferingOptionVirtualIntersectModel | FullOfferingOptionIntersectModel)[] {
		if (this.internalValue) {
			return AppDataModule.getFullOfferingOptionIntersectModels({
				offeringModels: this.fullOfferingModels as FullOfferingModels,
				variantid: (
					this.isVirtualOffering
						? this.internalValue.variantid
						: undefined
				),
			});
		}

		return [];
	}

	protected get selectedOptionsFiltered(): OfferingOptionsSummaryToolbarFullOfferingOptionModel[] {
		if (!this.internalValue.options) {
			return [];
		}

		if (!this.filterTags) {
			return this.internalValue.options;
		}

		const { filterTags } = this;
		const valueOptions = [...this.internalValue.options];

		valueOptions.sort(
			(a, b) => {
				if (!a.tag) {
					return 1;
				}
				if (!b.tag) {
					return -1;
				}

				let aTagIndex: number;
				let bTagIndex: number;

				if (typeof filterTags[0] === 'string') {
					aTagIndex = (filterTags as string[]).indexOf(a.tag);
					bTagIndex = (filterTags as string[]).indexOf(b.tag);
				} else {
					aTagIndex = (filterTags as OfferingOptionsSummaryToolbarFilterTag[]).findIndex((filterTag) => filterTag.tag === a.tag);
					bTagIndex = (filterTags as OfferingOptionsSummaryToolbarFilterTag[]).findIndex((filterTag) => filterTag.tag === b.tag);
				}

				if (aTagIndex === -1) {
					return 1;
				}
				if (bTagIndex === -1) {
					return -1;
				}

				return aTagIndex - bTagIndex;
			},
		);

		return valueOptions.reduce(
			(options, option) => {
				if (option.tag) {
					if (
						typeof filterTags[0] === 'string'
						&& (filterTags as string[]).includes(option.tag)
					) {
						options.push(option);
					} else if (
						typeof filterTags[0] === 'object'
						&& (filterTags as OfferingOptionsSummaryToolbarFilterTag[]).find((filterTag) => filterTag.tag === option.tag)
					) {
						options.push(option);
					}
				}

				return options;
			},
			[] as OfferingOptionsSummaryToolbarFullOfferingOptionModel[],
		);
	}

	protected get selectedOptionsFilteredToShow(): OfferingOptionsSummaryToolbarFullOfferingOptionModel[] {
		if (!this.filterTags) {
			return this.selectedOptionsFiltered;
		}

		const { filterTags } = this;
		return this.selectedOptionsFiltered.reduce(
			(options, option) => {
				if (option.tag) {
					if (typeof filterTags[0] === 'string') {
						options.push(option);
					} else if (
						typeof filterTags[0] === 'object'
						&& (filterTags as OfferingOptionsSummaryToolbarFilterTag[]).find((filterTag) => (
							filterTag.tag === option.tag
							&& (
								typeof filterTag.show === 'undefined'
								|| filterTag.show
							)
						))
					) {
						options.push(option);
					}
				}

				return options;
			},
			[] as OfferingOptionsSummaryToolbarFullOfferingOptionModel[],
		);
	}

	protected get showPricing(): boolean {
		return Boolean(this.internalValue.showPricing);
	}

	private closeDialog?: () => void;

	private closeToolbar?: () => void;

	private closeTooltip?: () => void;

	private internalValue: OfferingOptionsSummaryToolbarFullOfferingModel = {} as OfferingOptionsSummaryToolbarFullOfferingModel;

	private isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	/* eslint-disable @typescript-eslint/indent */
	private offeringOptionsDialogToolbarTooltipInstanceAPI?: ServiceOpenReturn<
		DialogComponent<typeof OfferingOptionsToolbarView, VueConstructor<Vue>, VueConstructor<Vue>>
		| ToolbarComponent<typeof OfferingOptionsToolbarView>
		| TooltipComponent<typeof OfferingOptionsToolbarView>
	>['api'];
	/* eslint-enable @typescript-eslint/indent */

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.closeDialog?.();
		this.closeToolbar?.();
		this.closeTooltip?.();
		this.isMobileUnwatch = undefined;
		this.closeToolbar = undefined;
		this.closeTooltip = undefined;
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;

			if (
				this.closeToolbar
				|| this.closeTooltip
				|| this.closeDialog
			) {
				this.closeDialog?.();
				this.closeToolbar?.();
				this.closeTooltip?.();
				this.offeringOptionsDialogToolbarTooltipInstanceAPI = undefined;
				this.closeToolbar = undefined;
				this.closeTooltip = undefined;
			}
		});
	}

	protected mounted(): void {
		const classConstructor = this.constructor as VueConstructorExtended;

		if (classConstructor.options.props) {
			const props = Object.keys(classConstructor.options.props) as Array<keyof PublicNonFunctionProps<OfferingOptionsSummaryToolbarView>>;

			// eslint-disable-next-line no-restricted-syntax
			for (const prop of props) {
				if (
					prop !== 'isProjectSinglePage'
					&& prop !== 'onlyOfferingName'
					&& prop !== 'onlySummary'
				) {
					this.$watch(
						prop,
						() => this.onPropChange(prop),
					);
				}
			}
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = {
				...this.value,
			};
		});
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		if (this.internalValue.id !== this.value.id) {
			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	protected getOfferingOptionName(offeringOption: OfferingOptionsSummaryToolbarFullOfferingOptionModel): string {
		return this.$t(
			`offeringOptions:${offeringOption.id}`,
			offeringOption.name,
		);
	}

	protected getOfferingOptionValueLabel(productOptionValue: OfferingOptionsSummaryToolbarOfferingOptionValueModel): string {
		if (productOptionValue.id) {
			return this.$t(
				`offeringOptionValues:${productOptionValue.id}`,
				productOptionValue.value,
			);
		}

		if (productOptionValue.offeringid) {
			return (
				this.fullOfferingModels.find((offeringModel) => offeringModel.id === productOptionValue.offeringid)?.size
				|| productOptionValue.value
			);
		}

		return productOptionValue.value;
	}

	protected onClick(): void {
		if (
			!this.onlySummary
			&& !this.noOfferingOptions
		) {
			let internalValue: OfferingOptionsSummaryToolbarFullOfferingModel = {
				...this.internalValue,
				options: this.internalValue.options.map((option) => ({
					...option,
					value: {
						...option.value,
					},
				})),
			};
			type BodyComponent = typeof OfferingOptionsToolbarView;
			const tooltipToolbarDialogBody: (
				TooltipServiceOptionsBody<BodyComponent>
				| ToolbarServiceOptionsBody<BodyComponent>
				| DialogServiceOptionsBody<BodyComponent>
			) = {
				component: OfferingOptionsToolbarView,
				props: {
					bleedMargin: this.bleedMargin,
					currencyModel: this.currencyModel,
					filterTags: this.filterTags,
					fullOfferingModels: this.fullOfferingModels,
					pageModel: this.pageModel,
					pageObjects: this.pageObjects,
					previewModel: this.previewModel,
					value: internalValue,
				},
				listeners: {
					apply: () => {
						this.internalValue = internalValue;
					},
					change: (value: OfferingOptionsSummaryToolbarFullOfferingModel) => {
						internalValue = value;
					},
					close: () => {
						this.closeDialog?.();
						this.closeToolbar?.();
						this.closeTooltip?.();
					},
				},
			};

			if (!this.isMobile) {
				let title: string | undefined;

				if (this.previewModel) {
					title = AppDataModule.getOfferingName(this.value.id);
				}

				const {
					close: closeTooltip,
					api: offeringOptionsTooltipInstanceAPI,
				} = this.$openTooltip({
					anchor: this.$el as HTMLElement,
					body: tooltipToolbarDialogBody,
					borderRadius: 24,
					distance: 24,
					hasCloseButton: true,
					isModal: true,
					listeners: {
						close: () => {
							this.offeringOptionsDialogToolbarTooltipInstanceAPI = undefined;
							this.closeTooltip = undefined;
						},
					},
					theme: this.internalTheme,
					title,
					tooltipStyles: {
						padding: (
							!this.previewModel
								? '16px 20px'
								: '24px'
						),
					},
				});
				this.closeTooltip = closeTooltip;
				this.offeringOptionsDialogToolbarTooltipInstanceAPI = offeringOptionsTooltipInstanceAPI;
			} else if (
				this.selectedOptionsFiltered.length < 3
				|| !this.previewModel
			) {
				const {
					close: closeToolbar,
					api: offeringOptionsToolbarInstanceAPI,
				} = this.$openToolbar({
					body: tooltipToolbarDialogBody,
					bodyStyles: (
						this.previewModel
							? {
								marginTop: '8px',
							}
							: undefined
					),
					listeners: {
						close: () => {
							this.offeringOptionsDialogToolbarTooltipInstanceAPI = undefined;
							this.closeToolbar = undefined;
						},
					},
					toolbarStyles: (
						this.previewModel
							? {
								'--toolbar-component-max-height': '97vh',
							}
							: undefined
					),
					theme: this.internalTheme,
				});
				this.closeToolbar = closeToolbar;
				this.offeringOptionsDialogToolbarTooltipInstanceAPI = offeringOptionsToolbarInstanceAPI;
			} else {
				const {
					close: closeDialog,
					api: offeringOptionsDialogInstanceAPI,
				} = this.$openDialogNew({
					body: {
						...tooltipToolbarDialogBody,
						styles: {
							'--offering-options-toolbar-view-background-color': 'var(--background-primary)',
							height: 'var(--dialog-component-body-max-height)',
							maxHeight: 'var(--dialog-component-body-max-height)',
						},
					},
					header: {
						title: AppDataModule.getOfferingName(this.value.id),
					},
					listeners: {
						close: () => {
							this.offeringOptionsDialogToolbarTooltipInstanceAPI = undefined;
							this.closeDialog = undefined;
						},
					},
					borderRadius: 0,
					styles: {
						height: `${window.innerHeight}px`,
					},
					theme: this.internalTheme,
					width: '100%',
				});
				this.closeDialog = closeDialog;
				this.offeringOptionsDialogToolbarTooltipInstanceAPI = offeringOptionsDialogInstanceAPI;
			}
		}
	}

	protected onPropChange(propName: keyof NonVuePublicProps<OfferingOptionsToolbarView>): void {
		if (
			!this.closeToolbar
			&& !this.closeTooltip
		) {
			return;
		}

		const bodyComponent = this.offeringOptionsDialogToolbarTooltipInstanceAPI?.bodyComponent();

		if (bodyComponent) {
			this.$nextTick(() => {
				/**
				 * We know that the property being watched and triggered this method
				 * is a public property in the offering options toolbar view component,
				 * so we can safely ignore the TS error.
				 */
				// @ts-ignore
				bodyComponent[propName] = this[propName];
			});
		}
	}
}
