import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import * as faceapi from 'face-api.js';
import * as PI from 'interfaces/project';
import {
	ChannelModel,
	ExternalCartItemModel,
	NativeObjectLocation,
	NativePlatform,
} from 'interfaces/app';
import checkConnection from 'services/check-connection';
import User from 'classes/user';
import ProductState from 'classes/productstate';
import EventBus from 'components/event-bus';
import ajax from 'controllers/ajax';
import { ProductStateModule, UserModule } from 'store/index';

interface FaceDetectModel {
	width: number;
	height: number;
	image: HTMLImageElement|null;
	detections: faceapi.FaceDetection[];
}
interface NativeSettingsMemory {
	removeUnselected?: boolean;
}

const supportButtonBottom = 20;
const supportButtonRight = 20;

@Module({ namespaced: true, name: 'appstate' })
export default class AppState extends VuexModule {
	// Auto saving of product under construction enabled?
	public autoSave = false;

	// Items in the user's shopping cart that are not from this module
	// Used for white labels that use external shopping carts
	// Allows us to show the full content in the cart widget
	public externalCartItems: ExternalCartItemModel[] = [];

	// This stores information about face detection in progress
	// By storing it here, we can display the information anywhere we want
	public faceDetection: FaceDetectModel = {
		width: 0,
		height: 0,
		image: null,
		detections: [],
	};

	// Is the app header collapsed?
	public headerCollapsed = false;

	// flag activated when expecting heavy rendering operations, so that UI can clear during operation
	public heavyLoad = false;

	// keep count of outstanding image load requests - used to cap number of parallel requests
	public imageLoadCount = 0;

	public mask: PI.PageObjectModel | null = null;

	// Save last location in native file picker, this so we can reopen at the same position
	public nativeObjectLocation: {
		source?: ChannelModel['id'] | NativePlatform; // The source of the image (e.g. iOS, Android, Facebook, Instagram e.t.c.)
		location?: string; // e.g. "albumId" or "/folder/subfolder/image.png"
		section?: number; // The section in the native list where the image is located
		row?: number; // The row in the section of the native list where the image is located
	} = {};

	// We can use this as a memory storage for how the native picker was initialized
	// This is useful in the onFilePickerClicked callback event, so we can recognize context
	public nativeSettingsMemory: NativeSettingsMemory = {};

	// used to store status of lock in object toolbar
	public objectLock = true;

	public online = false;

	public pingInterval: number | null = null;

	// Save vertical scroll positions when switching between modules
	public scrollPosition = 0;

	// Show bleed margin in editor?
	public showBleed = false;

	public showBleedDialog = true;

	// Show cross-sell dialog when continueing from product confirm screen?
	public showCrossSellDialog = true;

	public showSupportButton = true;

	// When uploads error occur, do we want to show them in a dialog to the user?
	// Useful to disable this when viewing the upload module (which has its own error handling)
	public showUploadErrorDialog = true;

	public shownAuthDialog = false;

	// Used when the interface requires a movement of the support button
	public supportButtonPosition = {
		bottom: supportButtonBottom,
		right: supportButtonRight,
	};

	// used to check if saving operation is in progress
	public sync = false;

	// Used to show number of unread message in the inbox link (native app only)
	public unreadMessageCount = 0;

	// Indicates if the app is running in upload only mode
	// (when the user opened the session through scanning the QR code to upload from an external device)
	public uploadOnly = false;

	public webPushAvailable = false;

	@Mutation
	public changeFaceDetection(data: Partial<FaceDetectModel>) {
		Object.assign(
			this.faceDetection,
			data,
		);
	}

	@Mutation
	public changeSupportButtonPosition(
		bottom: number,
		right?: number,
	) {
		this.supportButtonPosition.bottom = bottom;
		if (typeof right !== 'undefined') {
			this.supportButtonPosition.right = right;
		}
	}

	@Mutation
	public changeSupportVisibility(value: boolean) {
		this.showSupportButton = value;
	}

	@Mutation
	public decreaseImageLoad() {
		this.imageLoadCount -= 1;
	}

	@Mutation
	public disableAutoSave() {
		this.autoSave = false;
	}

	@Mutation
	public disableBleedDialog() {
		this.showBleedDialog = false;
	}

	@Mutation
	public disableCrossSellDialog() {
		this.showCrossSellDialog = false;
	}

	@Mutation
	public disableUploadErrorDialog() {
		this.showUploadErrorDialog = false;
	}

	@Mutation
	public enableAutoSave() {
		this.autoSave = true;
	}

	@Mutation
	public enableBleed() {
		this.showBleed = true;
	}

	@Mutation
	public enableWebPushAvailability() {
		this.webPushAvailable = true;
	}

	@Mutation
	public enableUploadErrorDialog() {
		this.showUploadErrorDialog = true;
	}

	@Mutation
	public flagAuthDialog() {
		this.shownAuthDialog = true;
	}

	@Mutation
	public hideBleed() {
		this.showBleed = false;
	}

	@Mutation
	public increaseImageLoad() {
		this.imageLoadCount += 1;
	}

	@Mutation
	public resetSupportButtonPosition() {
		this.supportButtonPosition.bottom = supportButtonBottom;
		this.supportButtonPosition.right = supportButtonRight;
	}

	@Mutation
	public setExternalCartItems(items: ExternalCartItemModel[]) {
		this.externalCartItems = items;
	}

	@Mutation
	public setHeaderCollapse(val: boolean) {
		this.headerCollapsed = val;
	}

	@Mutation
	public setHeavyLoad() {
		this.heavyLoad = true;
	}

	@Mutation
	public setMask(mask: PI.PageObjectModel | null) {
		this.mask = mask;
	}

	@Mutation
	public setNativeObjectLocation(location: NativeObjectLocation) {
		this.nativeObjectLocation = location;
	}

	@Mutation
	public setNativeSettingsMemory(settings: NativeSettingsMemory) {
		this.nativeSettingsMemory = settings;
	}

	@Mutation
	public setObjectLock() {
		this.objectLock = true;
	}

	@Mutation
	private _setOffline() {
		this.online = false;
	}

	@Mutation
	private _setOnline() {
		this.online = true;
	}

	@Mutation
	private _setPingInterval(val: number | null) {
		this.pingInterval = val;
	}

	@Mutation
	public setScrollPosition(val: number) {
		this.scrollPosition = val;
	}

	@Mutation
	public setSync(value: boolean) {
		this.sync = value;
	}

	@Mutation
	public setUnreadMessageCount(value: number) {
		this.unreadMessageCount = value;
	}

	@Mutation
	public setUploadOnly(value: boolean) {
		this.uploadOnly = value;
	}

	@Mutation
	public toggleHeaderCollapse() {
		this.headerCollapsed = !this.headerCollapsed;
	}

	@Mutation
	public toggleBleed() {
		this.showBleed = !this.showBleed;
	}

	@Mutation
	public toggleObjectLock() {
		this.objectLock = !this.objectLock;
	}

	@Mutation
	public unsetHeavyLoad() {
		this.heavyLoad = false;
	}

	@Mutation
	public unsetObjectLock() {
		this.objectLock = false;
	}

	@Action
	public setOffline() {
		const { commit } = this.context;

		commit('_setOffline');
		ajax.online = false;

		if (!this.pingInterval) {
			const int = window.setInterval(
				() => {
					checkConnection().catch(() => {
					// Swallow error: no action required
					});
				},
				30000,
			);

			commit(
				'_setPingInterval',
				int,
			);
		}
	}

	@Action
	public setOnline() {
		const { commit, dispatch } = this.context;

		commit('_setOnline');
		ajax.online = true;

		if (this.autoSave) {
			dispatch('save').catch(() => {
				// Swallow error: no action required
			});
		}

		if (this.pingInterval) {
			// Kill interval
			window.clearInterval(this.pingInterval);
			// Reset ping interval reference
			commit(
				'_setPingInterval',
				null,
			);
		}
	}

	/**
	 * Save the user data and the product data
	 * @returns {Promise<void>}
	 */
	@Action({ rawError: true })
	public save(): Promise<void> {
		return User.save()
			.then(() => {
				const productModel = ProductStateModule.getProduct;
				if (!productModel) {
					// There is no active product to save, so we can return early
					return undefined;
				}

				if (productModel.userid != UserModule.id) {
					// The user is not the owner of the product, so we can return early (viewing a shared product)
					return undefined;
				}

				return new Promise<void>((resolve, reject) => {
					// Bind event to end of save process
					EventBus.$once(
						'product:save:finished',
						(err: Error) => {
							if (err) {
								reject(err);
							} else {
								resolve();
							}
						},
					);

					// Start save if not already in progress
					if (!this.sync) {
						// Save product data to server
						ProductState.save();
					}
				});
			});
	}
}
