import i18next, { i18n } from 'i18next';
import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import {
	AjaxOptions,
	FontModel,
	FullOfferingModel,
	FullOfferingModels,
	FullOfferingOptionIntersectModel,
	FullOfferingOptionVirtualIntersectModel,
	FullOfferingOptionModel,
	FullOfferingOptionValueIntersectModel,
	FullOfferingOptionValueIntersectModels,
	FullOfferingOptionValueVirtualIntersectModel,
	OfferingFrameModel,
	PartialOfferingModel,
	PartialOfferingOptionIntersectModel,
	PartialOfferingOptionModel,
	PartialOfferingOptionValueIntersectModel,
	StartAppDataToExclude,
} from 'interfaces/app';
import * as API from 'interfaces/api';
import * as DB from 'interfaces/database';
import {
	AppDataModule,
	ChannelsModule,
	ConfigModule,
	FontModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import { array as arrayUtils } from 'utils';
import Vue from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

function findAndSetOffering(searchProps: PublicOptionalProps<Pick<DB.OfferingModel, 'externalid' | 'flexgroupid' | 'groupid' | 'id' | 'pdpid' | 'typeid' | 'variantid'>>): Promise<void> {
	return AppDataModule.fetchOfferingsData({
		searchProps,
	});
}

@Module({ namespaced: true, name: 'appdata' })
export default class AppData extends VuexModule {
	badges: DB.BadgeModel[] = [];

	badgeimages: DB.BadgeImageModel[] = [];

	badgeofferinglinks: DB.BadgeOfferingModel[] = [];

	badgepdplinks: DB.BadgePDPModel[] = [];

	badgeproductcategorylinks: DB.BadgeProductCategoryModel[] = [];

	bulks: DB.BulkModel[] = [];

	bulkproducttypes: DB.BulkProductTypeModel[] = [];

	bulkquantities: DB.BulkQuantityModel[] = [];

	buckets: DB.BucketModel[] = [];

	countries: DB.CountryModel[] = [];

	currencies: DB.CurrencyModel[] = [];

	fonts: FontModel[] = [];

	flexgroups: API.FlexGroupModel[] = [];

	handling: DB.HandlingModel[] = [];

	hyperlinks: DB.HyperlinkModel[] = [];

	languages: DB.LanguageModel[] = [];

	models2d: DB.Model2DModel[] = [];

	models3d: DB.Model3DModel[] = [];

	offeringframes: DB.OfferingFrameModel[] = [];

	offeringframeimages: DB.OfferingFrameImageModel[] = [];

	offeringframetemplates: DB.OfferingFrameTemplateModel[] = [];

	_offeringframesimages: Record<DB.OfferingFrameImageModel['id'], HTMLImageElement> = {};

	_offeringmasksimages: Record<DB.OfferingModel['id'], HTMLImageElement> = {};

	_offeringoverlaysimages: Record<DB.OfferingModel['id'], HTMLImageElement> = {};

	offerings: DB.OfferingModel[] = [];

	offeringofferingframelinks: DB.OfferingOfferingFrameLinkModel[] = [];

	offeringoptions: DB.OfferingOptionModel[] = [];

	offeringoptionvalues: DB.OfferingOptionValueModel[] = [];

	offeringoptionvalueofferinglinks: DB.OfferingOptionValueOfferingModel[] = [];

	pdps: API.PDPModel[] = [];

	pdpfilters: DB.PDPFilterModel[] = [];

	pdpfiltervalues: DB.PDPFilterValueModel[] = [];

	pdpfiltervalueofferinglinks: DB.PDPFilterValueOfferingModel[] = [];

	pdpimages: DB.PDPImageModel[] = [];

	pricing: DB.PricingModel[] = [];

	productcategories: API.ProductCategoryModel[] = [];

	productgroups: DB.ProductGroupModel[] = [];

	regioncurrencylinks: DB.RegionCurrencyModel[] = [];

	regionofferinglinks: DB.RegionOfferingModel[] = [];

	regions: DB.RegionModel[] = [];

	stateprovs: DB.StateProvModel[] = [];

	upsells: DB.UpsellModel[] = [];

	private get collectionUrl(): string {
		let url = `${window.glAppUrl}api/app/start`;

		if (window.glStack !== 'live') {
			// We always want to get the latest data from the server when in dev/test/staging mode
			url += `?version=${new Date().getTime() / 1000}`;
		} else if (window.appDataVersion) {
			// In production we want to make use of cloudFront caching, so we need a version number
			url += `?version=${window.appDataVersion}`;
		}

		return url;
	}

	get getAvailableOfferings() {
		const items: DB.OfferingModel[] = [];
		const userCountryId = this.context.rootState.user.countryid;
		const countryModel = (
			userCountryId
				? this.countries.find((country) => country.id === userCountryId)
				: undefined
		);

		if (countryModel) {
			this.offerings.forEach((offeringModel) => {
				const isAvailable = this.regionofferinglinks
					.find((regionofferinglink) => (
						regionofferinglink.regionid === countryModel.regionid
						&& regionofferinglink.offeringid === offeringModel.id
					));

				if (isAvailable) {
					items.push(offeringModel);
				}
			});
		}

		return items;
	}

	get getBucket() {
		return (id: string) => _.findWhere(
			this.buckets,
			{ id },
		);
	}

	get getBulk() {
		return (id: number) => _.findWhere(
			this.bulks,
			{ id },
		);
	}

	get getCountry() {
		return (id: number) => _.findWhere(
			this.countries,
			{ id },
		);
	}

	get getCountryName() {
		return (id: number) => {
			const countryModel = _.findWhere(
				this.countries,
				{ id },
			);
			if (countryModel) {
				let i18nextModule: i18n;

				if (window.App?.router.$i18next) {
					i18nextModule = window.App.router.$i18next;
				} else {
					i18nextModule = i18next;
				}

				return i18nextModule.exists(`countries.${countryModel.iso}`)
					? i18nextModule.t(`countries.${countryModel.iso}`)
					: countryModel.name;
			}

			return '';
		};
	}

	get getCurrency() {
		return (id: string): DB.CurrencyModel | undefined => this.currencies.find((currency) => currency.id === id);
	}

	get getCurrencyByCountryId() {
		return (countryId: number) => {
			const models: DB.CurrencyModel[] = [];
			const countryModel = this.getCountry(countryId);
			if (countryModel) {
				const { regionid } = countryModel;

				_.each(
					this.currencies,
					(currencyModel) => {
						if (this.findRegionCurrencyLinkWhere({
							regionid,
							currencyid: currencyModel.id,
						})) {
							models.push(currencyModel);
						}
					},
				);
			}

			return models;
		};
	}

	get getCurrencyByISO() {
		return (iso: DB.CurrencyModel['iso']): DB.CurrencyModel | undefined => this.currencies.find((currency) => currency.iso === iso);
	}

	get getOffering() {
		return (offeringid: number) => {
			const offeringFound = this.offerings.find((offering) => offering.id === offeringid);

			if (offeringFound) {
				return offeringFound;
			}

			findAndSetOffering({
				id: offeringid,
			});

			return undefined;
		};
	}

	get getOfferingDescription() {
		return (
			offeringId: DB.OfferingModel['id'],
		): string => {
			let description = '';

			const lineOne = window.App.router.$i18next.t(
				`offerings:${offeringId}.lines.0`,
				'',
			);
			if (lineOne?.length) {
				description += lineOne;
			}

			const lineTwo = window.App.router.$i18next.t(
				`offerings:${offeringId}.lines.1`,
				'',
			);
			if (lineTwo?.length) {
				if (description.length) {
					description += ' | ';
				}
				description += lineTwo;
			}

			return description;
		};
	}

	get getOfferingName() {
		return (
			offeringId: DB.OfferingModel['id'],
			addDescription?: boolean,
		): string => {
			let name = window.App.router.$i18next.t(
				`offerings:${offeringId}.name`,
				'',
			);

			if (addDescription) {
				const description = this.getOfferingDescription(offeringId);
				if (description.length) {
					name += ` | ${description}`;
				}
			}

			return name;
		};
	}

	get getOfferingOptionModels() {
		return (
			offeringid: DB.OfferingModel['id'],
			regionid?: DB.RegionModel['id'],
		) => {
			const offeringOptionModels: DB.OfferingOptionModel[] = [];
			this
				.getOfferingOptionValueModels(
					offeringid,
					regionid,
				)
				.forEach((offeringOptionValueModel) => {
					const offeringOptionModel = this.offeringoptions
						.find((offeringoption) => offeringoption.id === offeringOptionValueModel.offeringoptionid);

					if (
						offeringOptionModel
						&& !offeringOptionModels.includes(offeringOptionModel)
					) {
						offeringOptionModels.push(offeringOptionModel);
					}
				});

			return offeringOptionModels.sort((a, b) => a.serialnumber - b.serialnumber);
		};
	}

	/**
	 * Given a set of offering models or offering ids, return each offering model with its
	 * corresponding offering option models and their values.
	 * @param offeringIds - A list of offering ids.
	 * @param offeringModels - A list of offering models.
	 * @param regionid - The region id to filter the offerings by.
	 * @param filter - A function to filter the each offering model before pushing
	 * it to the final list of offering models.
	 * The filter must return either the full offering model (with the new desired
	 * structure/properties) or false to not include it in the final list.
	 * @param virtual - Whether to treat the offering models as virtual and add the
	 * offering model itself as an offering option.
	 * @returns {FullOfferingModel} - The final list of offering models filtered
	 * by regionid (if it was provided) with their corresponding offering
	 * option models and their values.
	 */
	public get getFullOfferingOptionModels(): (
		options: {
			offeringIds?: DB.OfferingModel['id'][];
			offeringModels?: DB.OfferingModel[];
			regionid?: DB.RegionModel['id'];
			filter?: (fullOfferingModel: FullOfferingModel) => FullOfferingModel | false;
			virtual?: boolean;
		},
	) => FullOfferingModels {
		return ({
			offeringIds,
			offeringModels: offeringsModels,
			regionid,
			filter,
			virtual,
		}) => {
			if (
				!offeringIds?.length
				&& !offeringsModels?.length
			) {
				return [];
			}

			/**
			 * Defines where the set of offering ids will be stored
			 */
			let finalOfferingIds: number[] = [];
			/**
			 * Defines where the set of offering models will be stored, the key
			 * of each offering model will be its own id for easy access.
			 */
			let finalOfferingModels: Record<number, DB.OfferingModel> = {};

			if (offeringIds) {
				/**
				 * Normalized offering ids from the offerings ids already provided
				 */
				finalOfferingIds = offeringIds;
				/**
				 * Normalized offering models from the offering ids, got from the
				 * `AppData.getOffering()` method
				 */
				finalOfferingModels = finalOfferingIds
					.reduce(
						(offerings, offeringid) => {
							const offeringModel = this.getOffering(offeringid);

							if (offeringModel) {
								offerings[offeringid] = offeringModel;
							}

							return offerings;
						},
						{} as Record<number, DB.OfferingModel>,
					);
			} else if (offeringsModels) {
				/**
				 * Normalized offering ids from the offering models
				 */
				finalOfferingIds = offeringsModels.map((offering) => (offering as DB.OfferingModel).id);
				/**
				 * Normalized offering models from the offering models already provided
				 */
				finalOfferingModels = offeringsModels.reduce(
					(offerings, offering) => {
						offerings[offering.id] = offering;

						return offerings;
					},
					{} as Record<number, DB.OfferingModel>,
				);
			}

			/**
			 * If a region id was provided, filter the offering ids by the region id
			 * by matching the region id and the offering id with the region offering links
			 */
			if (regionid) {
				finalOfferingIds = finalOfferingIds
					.filter((offeringid) => (
						this.regionofferinglinks
							.find((regionofferinglink) => (
								regionofferinglink.regionid === regionid
								&& regionofferinglink.offeringid === offeringid
							))
					));
			}

			/**
			 * If there are no offering ids available after the filter, return an empty list
			 */
			if (!finalOfferingIds.length) {
				return [];
			}

			/**
			 * Iterate over the offering value offering links to return the final list of
			 * offering option models and their values, this model comprehens a match of
			 * an offering id and an offering option value id.
			 *
			 * This means a match of every offering option value with its corresponding
			 * offering id, for example: values ids (e.g. landscape, 60x40 and white frame)
			 * and the offering id that match all these values together.
			 */
			const fullOfferingModels = this.offeringoptionvalueofferinglinks
				.reduce(
					(fullOfferingWithOptions, offeringOptionValueOfferingLink) => {
						/**
						 * If the offering id from the link model is not in the list
						 * of offering ids provided, then we skip it
						 */
						if (finalOfferingIds.includes(offeringOptionValueOfferingLink.offeringid)) {
							/**
							 * Get the offering option value models by matching the offering
							 * option value id with the link model offering option value id,
							 * this model comprehends the match of an offering option value
							 * with its corresponding offering option id. It also provides
							 * the serialnumber (display order of the offering option value)
							 *
							 * This means a match of every offering option value with its
							 * corresponding offering option id, for example:
							 * values (e.g. landscape, portrait and square) and the offering
							 * option id that owns all these values.
							 */
							const offeringOptionValues = this.offeringoptionvalues
								.filter((offeringOptionValue) => (
									offeringOptionValue.id === offeringOptionValueOfferingLink.offeringoptionvalueid
								));

							if (offeringOptionValues.length) {
								/**
								 * List of each offering option value model with its corresponding
								 * offering option model, though the structure being returned
								 * is a list of offering option models, but each model has a
								 * `value` property that contains the offering option value.
								 *
								 * This is made this way since the hierarchy of the offerings are
								 * offering options containing offering option values.
								 */
								const offeringOptions = offeringOptionValues
									.reduce(
										(finalOfferingOptions, offeringOptionValue) => {
											/**
											 * Get the offering option model by matching the offering
											 * option id with the offering option value offering option id
											 */
											const offeringOption = this.offeringoptions
												.find((offeringOptionModel) => (
													offeringOptionModel.id === offeringOptionValue.offeringoptionid
												));

											if (offeringOption) {
												/**
												 * Add the offering option model with its corresponding
												 * offering option value model to the list of offering
												 * option models
												 */
												finalOfferingOptions.push({
													...offeringOption,
													value: offeringOptionValue,
												});
											}

											return finalOfferingOptions;
										},
										[] as FullOfferingOptionModel[],
									);

								/**
								 * Search for an existing full offering model with options
								 * in the working final list to return  by matching the
								 * offering id with the offering id from the offering option
								 * value offering link model
								 */
								let fullOfferingFound = fullOfferingWithOptions
									.find((fullOfferingWithOption) => (
										fullOfferingWithOption.id === offeringOptionValueOfferingLink.offeringid
									));
								/**
								 * Variable to store the filtered full offering model or in the case
								 * that the filter indicates that the full offering model should be
								 * removed, then it will be false
								 */
								let filteredFullOfferingModel: FullOfferingModel | undefined | false;

								/**
								 * If the full offering model with options is not found,
								 * then we create it by getting the offering model with the
								 * list of offering models from the beginning of the function
								 * using the offering id from the offering option value offering
								 * link model and adding an empty list of options, and then we
								 * add it to the working final list to return
								 */
								if (!fullOfferingFound) {
									fullOfferingFound = {
										...finalOfferingModels[offeringOptionValueOfferingLink.offeringid],
										options: [],
									};
									fullOfferingWithOptions.push(fullOfferingFound);
								}

								/**
								 * Search for a potential virtual offering option if the variantid option
								 * was provided
								 */
								const virtualOptionFound = !!(
									virtual
									&& fullOfferingFound.options.find((option) => option.variantid)
								);

								/**
								 * If the variantid option was provided and the virtual option was not
								 * found, then we add it to the full offering options
								 */
								if (
									virtual
									&& !virtualOptionFound
									&& !fullOfferingFound.options.find((option) => option.tag === 'size')
								) {
									fullOfferingFound.options.push({
										name: 'size',
										serialnumber: -1,
										tag: 'size',
										value: {
											offeringid: offeringOptionValueOfferingLink.offeringid,
											serialnumber: -1,
											tag: null,
											value: finalOfferingModels[offeringOptionValueOfferingLink.offeringid].description as string,
										},
										variantid: finalOfferingModels[offeringOptionValueOfferingLink.offeringid].variantid,
									});
								}

								if (virtual) {
									const realSizeOption = offeringOptions.find((option) => option.tag === 'size');

									if (realSizeOption) {
										const manualSizeOption = fullOfferingFound.options.find((option) => (
											option.tag === 'size'
											&& option.serialnumber === -1
										));

										if (manualSizeOption) {
											fullOfferingFound.options.splice(
												fullOfferingFound.options.indexOf(manualSizeOption),
												1,
											);
											realSizeOption.tag = 'size';
										}
									}
								}

								/**
								 * Add the full offering options to the full offering model
								 */
								fullOfferingFound.options.push(
									...offeringOptions,
								);

								/**
								 * Sort the full offering options by its serial number
								 */
								fullOfferingFound.options.sort((a, b) => a.serialnumber - b.serialnumber);

								/**
								 * If the filter param is provided, then we check if the
								 * filter passed or not by calling the filter function
								 * passing the full offering model with options
								 */
								if (filter) {
									filteredFullOfferingModel = filter(fullOfferingFound);
								}

								/**
								 * If the filter function returns a full offering model,
								 * then we replace the full offering model in the working
								 * final list with the filtered full offering model, otherwise
								 * if the filter function returns false, then we remove the
								 * full offering model from the working final list
								 */
								if (filteredFullOfferingModel) {
									fullOfferingWithOptions.splice(
										fullOfferingWithOptions.indexOf(fullOfferingFound),
										1,
										filteredFullOfferingModel,
									);
								} else if (filteredFullOfferingModel === false) {
									fullOfferingWithOptions.splice(
										fullOfferingWithOptions.indexOf(fullOfferingFound),
										1,
									);
								}
							}
						}

						return fullOfferingWithOptions;
					},
					[] as FullOfferingModels,
				);

			/**
			 * If the full offering models with options are not empty, then we
			 * return them, otherwise we return the list of offering models with
			 * an empty list of options
			 */
			if (fullOfferingModels.length > 0) {
				return fullOfferingModels;
			}

			/**
			 * Return the list of offering models with an empty list of options
			 * in the case that the full offering models with options are empty
			 * because it means that the offering models do not have offering
			 * option value offering link models associated with them
			 */
			return Object
				.values(finalOfferingModels)
				.map((offeringModel) => ({
					...offeringModel,
					options: [],
				}));
		};
	}

	public get getFullOfferingOptionIntersectModels(): (
		options: {
			offeringModels: FullOfferingModels,
			variantid?: number,
		},
	) => (FullOfferingOptionVirtualIntersectModel | FullOfferingOptionIntersectModel)[] {
		return ({
			offeringModels,
			variantid,
		}) => {
			const offeringOptionIntersectModels = offeringModels
				.reduce(
					(options, fullOfferingWithOptions) => {
						/**
						 * Check if the virtual mode is set and if the virtual option already exists
						 * in the options list
						 */
						const virtualOptionFound = (
							variantid
							&& options.find((optionModel) => (
								'variantid' in optionModel
								&& optionModel.variantid === fullOfferingWithOptions.variantid
							))
						);
						let virtualOption: FullOfferingOptionVirtualIntersectModel | undefined;

						if (
							variantid
							&& !virtualOptionFound
							&& variantid === fullOfferingWithOptions.variantid
							&& !options.find((optionModel) => optionModel.tag === 'size')
						) {
							virtualOption = {
								items: [],
								name: 'size',
								serialnumber: -1,
								tag: 'size',
								value: {
									offeringid: fullOfferingWithOptions.id,
									serialnumber: -1,
									tag: null,
									value: fullOfferingWithOptions.description as string,
								},
								variantid: fullOfferingWithOptions.variantid,
							};
							options.push(virtualOption);
						} else if (virtualOptionFound) {
							virtualOption = virtualOptionFound;
						}

						if (virtualOption) {
							const lastItem = virtualOption.items.at(-1);
							virtualOption.items.push({
								image: null,
								matchingOfferingIds: [],
								offeringid: fullOfferingWithOptions.id,
								serialnumber: lastItem ? lastItem.serialnumber + 1 : 1,
								tag: null,
								value: fullOfferingWithOptions.description as string,
							});
						}

						// eslint-disable-next-line no-restricted-syntax
						for (const fullOfferingOption of fullOfferingWithOptions.options) {
							/**
							 * Check if the current option in the iteration already exists in options list
							 */
							const optionFound = options
								.find((optionModel) => optionModel.id === fullOfferingOption.id);
							/**
							 * Create a new option in case it doesn't exist in the options list
							 */
							let option = {
								...fullOfferingOption,
								items: [],
							} as FullOfferingOptionVirtualIntersectModel | FullOfferingOptionIntersectModel;

							/**
							 * If the option already exists in the options list, use the existing one
							 * instead of using the new one created above
							 */
							if (optionFound) {
								option = optionFound;
							}

							/**
							 * Check if the current option value in the iteration already exists
							 * in the option values list, this is made because some options values
							 * can be repeated in different offerings, for example an orientation option
							 * value will be repeated for each size option value.
							 *
							 * E.g. being "landscape" the option value of "orientation":
							 * - A set with orientation "landscape" and size "30x20" will have the same
							 * option value "landscape" as in another set of orientation "landscape" and
							 * option value "45x30"
							 */
							const optionValueFound = (option.items as (FullOfferingOptionValueIntersectModel | FullOfferingOptionValueVirtualIntersectModel)[])
								.find((optionValueModel) => optionValueModel.id === fullOfferingOption.value.id);

							/**
							 * If the option value doesn't exist in the option values list, add it
							 * to the option values list
							 */
							if (!optionValueFound) {
								option.items.push({
									...fullOfferingOption.value as any,
									/**
									 * Add all the offering ids where the current option value is available,
									 * this is needed in a later point to filter the offering option values
									 * to show only the ones that are available for the current set of
									 * offering option values selected by the user.
									 *
									 * For example if the user selects the orientation "square", we only
									 * want to show the size option values that are available for the
									 * orientation "square"
									 */
									matchingOfferingIds: offeringModels
										.filter((fullOfferingModel) => {
											const optionValue = fullOfferingModel.options
												.find((optionModel) => optionModel.id === fullOfferingOption.id)
												?.value;

											if (!optionValue) {
												return false;
											}

											return optionValue.id === fullOfferingOption.value.id;
										})
										.map((fullOfferingModel) => fullOfferingModel.id),
								});
								/**
								 * Sort the option values by serial number, this is needed because
								 * the option values are not always returned in the correct order
								 */
								option.items.sort((a, b) => a.serialnumber - b.serialnumber);

								if (virtualOption) {
									const virtualOptionItem = virtualOption.items[virtualOption.items.length - 1];
									virtualOptionItem.matchingOfferingIds = Array.from(
										new Set(
											options
												.filter((currentOption) => 'variantid' in currentOption)
												.reduce(
													(matchingOfferingIds, currentOption) => {
														const currentOptionItems = currentOption.items as FullOfferingOptionValueVirtualIntersectModel[];

														return [
															...matchingOfferingIds,
															...currentOptionItems
																.filter((currentOptionItem) => currentOptionItem.value === fullOfferingWithOptions.description)
																.map((currentOptionItem) => currentOptionItem.offeringid) as number[],
														];
													},
													virtualOptionItem.matchingOfferingIds,
												),
										),
									);
								}
							} else if (virtualOption) {
								const virtualOptionItem = virtualOption.items[virtualOption.items.length - 1];
								virtualOptionItem.matchingOfferingIds = Array.from(
									new Set(
										options
											.filter((currentOption) => 'variantid' in currentOption)
											.reduce(
												(matchingOfferingIds, currentOption) => {
													const currentOptionItems = currentOption.items as FullOfferingOptionValueVirtualIntersectModel[];

													return [
														...matchingOfferingIds,
														...currentOptionItems
															.filter((currentOptionItem) => currentOptionItem.value === fullOfferingWithOptions.description)
															.map((currentOptionItem) => currentOptionItem.offeringid) as number[],
													];
												},
												virtualOptionItem.matchingOfferingIds,
											),
									),
								);
							}

							/**
							 * If the option doesn't exist in the options list, add it to the
							 * options list
							 */
							if (!optionFound) {
								if (option.tag === 'size') {
									const manualSizeOption = options.find((optionIteration) => (
										optionIteration.tag === 'size'
										&& optionIteration.serialnumber === -1
									));

									if (manualSizeOption) {
										options.splice(
											options.indexOf(manualSizeOption),
											1,
										);
										option.tag = 'size';
									}
								}

								options.push(option);
							}
						}

						return options;
					},
					[] as (FullOfferingOptionVirtualIntersectModel | FullOfferingOptionIntersectModel)[],
				);

			if (!variantid) {
				return offeringOptionIntersectModels;
			}

			return offeringOptionIntersectModels.filter((option) => (
				(
					'variantid' in option
					&& option.variantid === variantid
				)
				|| !('variantid' in option)
			));
		};
	}

	public get getFullOfferingOptionValueIntersectModels(): (
		offeringOptions: PartialOfferingOptionIntersectModel[],
		offeringOption: PartialOfferingOptionIntersectModel,
		currentOfferingId?: number,
		offeringOptionValueFilter?: (
			offeringOptionValue: FullOfferingOptionValueIntersectModel,
			offeringOption: FullOfferingOptionIntersectModel,
		) => boolean,
	) => FullOfferingOptionValueIntersectModels {
		return (
			offeringOptions,
			offeringOption,
			currentOfferingId,
			offeringOptionValueFilter,
		) => {
			const productOption = offeringOptions
				.find((productOptionItem) => productOptionItem.id === offeringOption.id) as FullOfferingOptionIntersectModel;

			if (currentOfferingId) {
				/**
				 * Get the index of the product option in the list of product options
				 */
				const productOptionIndex = offeringOptions.indexOf(productOption);
				/**
				 * Get the list of product options that are previous to the current product option
				 * and that passes the filter (if any)
				 */
				const previousProductOptions = offeringOptions
					.filter((previousSelectOption, index) => {
						if (index < productOptionIndex) {
							let previousFilter = true;

							if (offeringOptionValueFilter) {
								previousFilter = previousSelectOption.items.some((productOptionValue) => (
									offeringOptionValueFilter(
										productOptionValue as FullOfferingOptionValueIntersectModel,
										previousSelectOption as FullOfferingOptionIntersectModel,
									)
								));
							}

							return previousFilter;
						}

						return false;
					});

				/**
				 * If there are no previous product options it's because it's the first product option,
				 * so return the full list of product option values
				 */
				if (previousProductOptions.length === 0) {
					return productOption.items;
				}

				/**
				 * Get the list of offering ids that are available for the previous product options
				 * but only the ones that include the current offering model selected
				 */
				const availableOfferingIdsVariations = Array.from(
					new Set(
						previousProductOptions
							.map((previousSelectOption) => (
								previousSelectOption.items
									.filter((productOptionValue) => {
										let previousFilter = true;

										if (offeringOptionValueFilter) {
											previousFilter = offeringOptionValueFilter(
												productOptionValue as FullOfferingOptionValueIntersectModel,
												previousSelectOption as FullOfferingOptionIntersectModel,
											);
										}

										return (
											previousFilter
											&& productOptionValue.matchingOfferingIds.includes(currentOfferingId)
										);
									})
									.map((item) => item.matchingOfferingIds)
							))
							.flat(),
					),
				);
				const [
					firstAvailableOfferingId,
					...remainingAvailableOfferingIds
				] = availableOfferingIdsVariations;
				const intersectedSetOfferingIds = new Set(firstAvailableOfferingId);

				/**
				 * Efficiently get the intersection of the offering ids between all the
				 * previous product options
				 */
				// eslint-disable-next-line no-restricted-syntax
				for (const remainingAvailableOfferingId of remainingAvailableOfferingIds) {
					// eslint-disable-next-line no-restricted-syntax
					for (const offeringId of intersectedSetOfferingIds) {
						if (!remainingAvailableOfferingId.includes(offeringId)) {
							intersectedSetOfferingIds.delete(offeringId);
						}
					}
				}

				const intersectedOfferingIds = Array.from(intersectedSetOfferingIds);
				/**
				 * Get the list of product option values for the current product option
				 * that match the offering ids from the intersection
				 */
				const productOptionItems = productOption.items
					.filter((productOptionValue) => (
						productOptionValue.matchingOfferingIds
							.find((matchingOfferingId) => (
								intersectedOfferingIds.includes(matchingOfferingId)
							))
					));

				/**
				 * Return the option values that match the intersection of the offering ids (can be empty if no matches were found)
				 */
				return productOptionItems;
			}

			return productOption.items;
		};
	}

	public get getMatchingFullOfferingModel(): (
		options: {
			offeringOption: PartialOfferingOptionIntersectModel,
			offeringOptionValue: PartialOfferingOptionValueIntersectModel,
			offeringModel: PartialOfferingModel,
			offeringModels: PartialOfferingModel[],
		},
	) => FullOfferingModel | undefined {
		return ({
			offeringOption,
			offeringOptionValue,
			offeringModel,
			offeringModels,
		}) => {
			/**
			 * Get all the product options models except the one that was changed
			 */
			const internalFullOfferingModelProductOptionsExceptCurrentOne = offeringModel.options
				.filter((option) => option.id !== offeringOption.id);
			/**
			 * Get all the product option values models except the one that was changed
			 */
			const optionValuesExceptCurrentOne = internalFullOfferingModelProductOptionsExceptCurrentOne
				.map((option) => option.value);
			/**
			 * Create a set of all the product option values ids that are
			 * currently selected, including the one that was changed
			 */
			const newOptionValuesForOfferingModel = [
				...optionValuesExceptCurrentOne,
				offeringOptionValue,
			];
			/**
			 * Create a potential set of product options combinations
			 * that could not match any offering model available for
			 * this PDP
			 */
			const failedOptions: PartialOfferingOptionModel[][] = [];
			let matchingOfferingModel = offeringModels
				.find((fullOfferingModel) => {
					const fullOfferingModelOptionsFailedToMatch: PartialOfferingOptionModel[] = [];
					const optionsMatching = fullOfferingModel.options
						.filter((option) => {
							/**
							 * Check if the current product option value matches the set of all
							 * product option values ids that are currently selected
							 */
							const optionFound = newOptionValuesForOfferingModel
								.find((newOption) => (
									(
										newOption.id
										&& newOption.id === option.value.id
									)
									|| (
										newOption.offeringid
										&& newOption.offeringid === option.value.offeringid
									)
								));

							/**
							 * If the current product option value does not match the set of all
							 * product option values ids that are currently selected, we add it
							 * to the list of product options that failed to match
							 */
							if (!optionFound) {
								fullOfferingModelOptionsFailedToMatch.push(option);
							}

							return optionFound;
						});

					const virtualOfferingOptionValueMatches = (
						offeringOptionValue.offeringid
						&& fullOfferingModel.id === offeringOptionValue.offeringid
					);

					if (
						optionsMatching.length !== Math.max(
							fullOfferingModel.options.length,
							newOptionValuesForOfferingModel.length,
						)
						&& !virtualOfferingOptionValueMatches
					) {
						/**
						 * Check if the current product option id is in the list of product options
						 * that failed to match
						 */
						const productOptionChangedFound = fullOfferingModelOptionsFailedToMatch
							.find((option) => option.id === offeringOption.id);

						/**
						 * If the current product option id is not in the list of product options
						 * that failed to match, we add it to the set of product options combinations
						 * that could not match any offering model available for this PDP
						 *
						 * We do this because for example if we had previously selected a set of
						 * product options orientation "square", size "20x20" and frame "white",
						 * and we changed orientation "square" for "portrait", we already know that
						 * the combination "portrait", "20x20" and "white" will not match any offering
						 * model available for this PDP, the orientation "sizes" are not
						 * shared between the two orientations, so we only care about the product
						 * options (except the current one) that failed to match, which in this case
						 * is "size", since "white" is matching in this example and in the set of
						 * all product option values that are currently selected
						 */
						if (!productOptionChangedFound) {
							failedOptions.push(fullOfferingModelOptionsFailedToMatch);
						}

						return false;
					}

					return true;
				});

			/**
			 * If we did not find any matching offering model, we try to find an
			 * intersection of the failed option ids between all the previous product options,
			 * if we find one, we know that the combination of product options that failed to
			 * match is not possible, so we remove it from the list of product options that
			 * needs to match in order to find a matching offering model
			 */
			if (!matchingOfferingModel) {
				const failedOptionIds = failedOptions
					.map((failedOptionList) => failedOptionList.map((failedOption) => failedOption.id));
				const [
					firstFailedOptionId,
					...remainingFailedOptionIds
				] = failedOptionIds;
				const intersectedFailedSetOptionIds = new Set(firstFailedOptionId);

				/**
				 * Efficiently get the intersection of the failed option ids between all the
				 * previous product options
				 */
				// eslint-disable-next-line no-restricted-syntax
				for (const remainingFailedOptionId of remainingFailedOptionIds) {
					// eslint-disable-next-line no-restricted-syntax
					for (const optionId of intersectedFailedSetOptionIds) {
						if (!remainingFailedOptionId.includes(optionId)) {
							intersectedFailedSetOptionIds.delete(optionId);
						}
					}
				}

				/**
				 * Convert the set of intersected failed option ids to an array
				 */
				const intersectedFailedOptionIds = Array.from(intersectedFailedSetOptionIds);

				/**
				 * If we found an intersection of the failed option ids between all the
				 * previous product options, we try to find a matching offering model
				 * that matches the set of all product option values ids that are currently
				 * selected, except for the intersected failed option ids by doing a filter
				 * on the options of each offering model
				 */
				matchingOfferingModel = offeringModels
					.find((fullOfferingModel) => (
						fullOfferingModel.options
							.filter((option) => !intersectedFailedOptionIds.find((failedOptionId) => failedOptionId === option.id))
							.every((option) => (
								newOptionValuesForOfferingModel.find((newOption) => newOption.id === option.value.id)
							))
					));
			}

			/**
			 * If we did not find any matching offering model, we try to find a matching
			 * offering model that matches only the product option value id that was changed
			 */
			if (!matchingOfferingModel) {
				matchingOfferingModel = offeringModels
					.find((fullOfferingModel) => (
						fullOfferingModel.options.find((option) => offeringOptionValue.id === option.value.id)
					));
			}

			return matchingOfferingModel as FullOfferingModel | undefined;
		};
	}

	get getOfferingOptionValueModels() {
		return (
			offeringid: DB.OfferingModel['id'],
			regionid?: DB.RegionModel['id'],
		) => {
			const offeringOptionValueModels: DB.OfferingOptionValueModel[] = [];
			const offeringModel = this.getOffering(offeringid);

			if (offeringModel) {
				// eslint-disable-next-line no-restricted-syntax
				for (const offering of this.offerings) {
					let matches = true;

					if (offeringModel.flexgroupid) {
						matches = offering.flexgroupid === offeringModel.flexgroupid;
					} else {
						matches = (
							offering.groupid === offeringModel.groupid
							&& offering.typeid === offeringModel.typeid
						);
					}

					if (!matches) {
						// eslint-disable-next-line no-continue
						continue;
					}

					if (regionid) {
						const regionOfferingLinkModel = this.findRegionOfferingLinkWhere({
							offeringid: offering.id,
							regionid,
						});
						if (!regionOfferingLinkModel) {
							matches = false;
						} else {
							const m = this.getOffering(regionOfferingLinkModel.offeringid);
							if (m
								&& !m.instock
							) {
								matches = false;
							}
						}
					}

					if (!matches) {
						// eslint-disable-next-line no-continue
						continue;
					}

					// eslint-disable-next-line no-restricted-syntax
					for (const offeringoptionvalueofferinglink of this.offeringoptionvalueofferinglinks) {
						if (offeringoptionvalueofferinglink.offeringid === offering.id) {
							const offeringOptionValueModel = this.offeringoptionvalues
								.find((offeringoptionvalue) => offeringoptionvalue.id === offeringoptionvalueofferinglink.offeringoptionvalueid);

							if (
								offeringOptionValueModel
								&& !offeringOptionValueModels.includes(offeringOptionValueModel)
							) {
								offeringOptionValueModels.push(offeringOptionValueModel);
							}
						}
					}
				}
			}

			return offeringOptionValueModels;
		};
	}

	get getOfferingVariantName() {
		return (offeringId: number) => window.App.router.$i18next.t(
			`offerings:${offeringId}.variantname`,
			'',
		);
	}

	get getPDP() {
		return (id: DB.PDPModel['id']) => _.findWhere(
			this.pdps,
			{ id },
		);
	}

	get getPDPImages() {
		return (pdpid: DB.PDPModel['id']) => _.where(
			this.pdpimages,
			{ pdpid },
		);
	}

	get getListerItem() {
		return (id: number) => this.productcategories.find(
			(listerItem) => listerItem.id === id,
		);
	}

	get getProductCategoryName() {
		return (id: number) => {
			const categoryModel = _.findWhere(
				this.productcategories,
				{ id },
			);
			const defaultName = categoryModel ? categoryModel.name : '';
			const name = window.App.router.$i18next.t(
				`productcategories:${id}.name`,
				defaultName,
			);
			return name;
		};
	}

	get getUpsellDescription() {
		return (id: number) => {
			const upsellModel = _.findWhere(
				this.upsells,
				{ id },
			);
			const defaultUpsellDescription = upsellModel && upsellModel.description ? upsellModel.description : '';
			return window.App.router.$i18next.exists(`upsells.${id}`)
				&& window.App.router.$i18next.exists(`upsells.${id}.description`)
				? window.App.router.$i18next.t(`upsells.${id}.description`)
				: defaultUpsellDescription;
		};
	}

	get getProductGroup() {
		return (groupid: number) => _.findWhere(
			this.productgroups,
			{ id: groupid },
		);
	}

	get getRegion() {
		return (id: number) => _.findWhere(
			this.regions,
			{ id },
		);
	}

	get findBulkProductTypeWhere() {
		return (properties: Partial<DB.BulkProductTypeModel>) => _.findWhere(
			this.bulkproducttypes,
			properties,
		);
	}

	get findBulkQuantity() {
		return (properties: Partial<DB.BulkQuantityModel>) => _.where(
			this.bulkquantities,
			properties,
		);
	}

	get findBulkQuantityWhere() {
		return (properties: Partial<DB.BulkQuantityModel>) => _.findWhere(
			this.bulkquantities,
			properties,
		);
	}

	get findCountry() {
		return (properties: Partial<DB.CountryModel>) => _.where(
			this.countries,
			properties,
		);
	}

	get findCountryWhere() {
		return (properties: Partial<DB.CountryModel>) => _.findWhere(
			this.countries,
			properties,
		);
	}

	get defaultCurrency() {
		return this.currencies.find((currencyModel) => currencyModel.default);
	}

	get findHandlingWhere() {
		return (properties: Partial<DB.HandlingModel>) => _.findWhere(
			this.handling,
			properties,
		);
	}

	get findHyperlink() {
		return (
			searchProps: Partial<DB.HyperlinkModel>,
			strict = false,
		) => {
			const userLanguage = this.context.rootState.user.language;

			if (userLanguage) {
				searchProps.languageid = userLanguage;
			}

			const userCountryId = this.context.rootState.user.countryid;
			const countryModel = userCountryId
				? this.countries.find((country) => country.id === userCountryId)
				: undefined;

			if (countryModel) {
				searchProps.regionid = countryModel.regionid;
			}

			let hyperlinkModel = this.hyperlinks
				.find((hyperlink) => {
					// eslint-disable-next-line no-restricted-syntax
					for (const property in searchProps) {
						if (
							Object.prototype.hasOwnProperty.call(
								searchProps,
								property,
							)
						) {
							const castedProperty = property as keyof DB.HyperlinkModel;

							if (hyperlink[castedProperty] !== searchProps[castedProperty]) {
								return false;
							}
						}
					}

					return true;
				});

			if (
				!strict
				&& !hyperlinkModel
				&& searchProps.languageid
			) {
				// Search again without the language constraint
				delete searchProps.languageid;
				hyperlinkModel = this.hyperlinks
					.find((hyperlink) => {
						// eslint-disable-next-line no-restricted-syntax
						for (const property in searchProps) {
							if (
								Object.prototype.hasOwnProperty.call(
									searchProps,
									property,
								)
							) {
								const castedProperty = property as keyof DB.HyperlinkModel;

								if (hyperlink[castedProperty] !== searchProps[castedProperty]) {
									return false;
								}
							}
						}

						return true;
					});
			}

			if (
				!strict
				&& !hyperlinkModel
				&& searchProps.regionid
			) {
				// Search again without the region constraint
				delete searchProps.regionid;
				hyperlinkModel = this.hyperlinks
					.find((hyperlink) => {
						// eslint-disable-next-line no-restricted-syntax
						for (const property in searchProps) {
							if (
								Object.prototype.hasOwnProperty.call(
									searchProps,
									property,
								)
							) {
								const castedProperty = property as keyof DB.HyperlinkModel;

								if (hyperlink[castedProperty] !== searchProps[castedProperty]) {
									return false;
								}
							}
						}

						return true;
					});
			}

			return hyperlinkModel;
		};
	}

	get findLanguageWhere() {
		return (properties: Partial<DB.LanguageModel>) => _.findWhere(
			this.languages,
			properties,
		);
	}

	get findLocalizedBadgeImages() {
		return (
			badgeIds: DB.BadgeImageModel['id'][],
		): DB.BadgeImageModel[] => {
			const badgeImageModels: DB.BadgeImageModel[] = [];
			badgeIds.forEach((badgeId) => {
				const searchProps: Partial<DB.BadgeImageModel> = {
					badgeid: badgeId,
					languageid: null,
					regionid: null,
				};

				const userLanguage = this.context.rootState.user.language;

				if (userLanguage) {
					searchProps.languageid = userLanguage;
				}

				const userCountryId = this.context.rootState.user.countryid;

				const countryModel = userCountryId
					? this.countries.find((country) => country.id === userCountryId)
					: undefined;

				if (countryModel) {
					searchProps.regionid = countryModel.regionid;
				}

				let badgeImageModel = this.badgeimages
					.find((badgeimage) => {
						// eslint-disable-next-line no-restricted-syntax
						for (const property in searchProps) {
							if (
								Object.prototype.hasOwnProperty.call(
									searchProps,
									property,
								)
							) {
								const castedProperty = property as keyof DB.BadgeImageModel;

								if (badgeimage[castedProperty] !== searchProps[castedProperty]) {
									return false;
								}
							}
						}

						return true;
					});

				if (
					!badgeImageModel
					&& searchProps.languageid
				) {
					// Search again without the language constraint
					badgeImageModel = this.badgeimages
						.find((badgeimage) => {
							const searchPropsWithoutLanguageId = {
								...searchProps,
								languageid: null,
							};

							// eslint-disable-next-line no-restricted-syntax
							for (const property in searchPropsWithoutLanguageId) {
								if (
									Object.prototype.hasOwnProperty.call(
										searchPropsWithoutLanguageId,
										property,
									)
								) {
									const castedProperty = property as keyof DB.BadgeImageModel;

									if (badgeimage[castedProperty] !== searchPropsWithoutLanguageId[castedProperty]) {
										return false;
									}
								}
							}

							return true;
						});
				}

				if (
					!badgeImageModel
					&& searchProps.regionid
				) {
					// Search again without the region constraint
					badgeImageModel = this.badgeimages
						.find((badgeimage) => {
							const searchPropsWithoutRegionId = {
								...searchProps,
								regionid: null,
							};

							// eslint-disable-next-line no-restricted-syntax
							for (const property in searchPropsWithoutRegionId) {
								if (
									Object.prototype.hasOwnProperty.call(
										searchPropsWithoutRegionId,
										property,
									)
								) {
									const castedProperty = property as keyof DB.BadgeImageModel;

									if (badgeimage[castedProperty] !== searchPropsWithoutRegionId[castedProperty]) {
										return false;
									}
								}
							}

							return true;
						});
				}

				if (!badgeImageModel) {
					// Search again without the region and language constraints
					badgeImageModel = this.badgeimages
						.find((badgeimage) => {
							const searchPropsWithoutLanguageIdAndRegionId = {
								...searchProps,
								regionid: null,
								languageid: null,
							};

							// eslint-disable-next-line no-restricted-syntax
							for (const property in searchPropsWithoutLanguageIdAndRegionId) {
								if (
									Object.prototype.hasOwnProperty.call(
										searchPropsWithoutLanguageIdAndRegionId,
										property,
									)
								) {
									const castedProperty = property as keyof DB.BadgeImageModel;

									if (badgeimage[castedProperty] !== searchPropsWithoutLanguageIdAndRegionId[castedProperty]) {
										return false;
									}
								}
							}

							return true;
						});
				}

				if (badgeImageModel) {
					badgeImageModels.push(badgeImageModel);
				}
			});

			return badgeImageModels;
		};
	}

	public get findOfferingFrameImage() {
		return (offeringframeimage: DB.OfferingFrameImageModel): HTMLImageElement | undefined => (
			this._offeringframesimages[offeringframeimage.id]
		);
	}

	public get findOfferingMaskImage() {
		return (offering: DB.OfferingModel): HTMLImageElement | undefined => (
			this._offeringmasksimages[offering.id]
		);
	}

	public get findOfferingOverlayImage() {
		return (offering: DB.OfferingModel): HTMLImageElement | undefined => (
			this._offeringoverlaysimages[offering.id]
		);
	}

	get findOffering() {
		return (properties: Partial<DB.OfferingModel>) => _.where(
			this.offerings,
			properties,
		);
	}

	get findOfferingBadges() {
		return (
			offeringid: DB.OfferingModel['id'],
		) => {
			const linkModels = _.where(
				this.badgeofferinglinks,
				{ offeringid },
			);
			const badgeIds = linkModels.map(
				(linkModel) => linkModel.badgeid,
			);
			return this.findLocalizedBadgeImages(
				badgeIds,
			);
		};
	}

	@Action({ rawError: true })
	public async findOfferings(offeringIds: number[]): Promise<void> {
		const offeringIdsNotFound = offeringIds
			.map((offeringId) => ({
				offeringId,
				offeringModel: this.offerings.find((offering) => offering.id === offeringId),
			}))
			.filter(({ offeringModel }) => !offeringModel)
			.map(({ offeringId }) => offeringId);

		if (offeringIdsNotFound.length) {
			await this.fetchOfferingsData({
				offeringIds: offeringIdsNotFound,
			});
		}
	}

	get findAndGetOfferingWhere() {
		return async (properties: Partial<DB.OfferingModel>): Promise<DB.OfferingModel | undefined> => {
			const offeringModelFound = this.findOfferingWhere(properties);

			if (!offeringModelFound) {
				await findAndSetOffering(properties);
			}

			return this.findOfferingWhere(properties);
		};
	}

	get findOfferingWhere() {
		return (properties: Partial<DB.OfferingModel>): DB.OfferingModel | undefined => (
			this.offerings.find((offering) => {
				// eslint-disable-next-line no-restricted-syntax
				for (const property in properties) {
					if (
						Object.prototype.hasOwnProperty.call(
							properties,
							property,
						)
					) {
						const castedProperty = property as keyof DB.OfferingModel;

						if (
							castedProperty === 'externalid'
							&& `${offering.externalid}` !== properties.externalid?.toString()
						) {
							return false;
						}

						if (offering[castedProperty] !== properties[castedProperty]) {
							return false;
						}
					}
				}

				return true;
			})
		);
	}

	get findPDPBadges() {
		return (pdpid: DB.PDPModel['id']) => {
			const linkModels = (this.badgepdplinks || []).filter((badgepdplink) => badgepdplink.pdpid === pdpid);
			const badgeIds = linkModels.map((linkModel) => linkModel.badgeid);

			return this.findLocalizedBadgeImages(
				badgeIds,
			);
		};
	}

	get findPricingWhere() {
		return (properties: Partial<DB.PricingModel>) => this.pricing.find((pricing) => {
			// eslint-disable-next-line no-restricted-syntax
			for (const property in properties) {
				if (
					Object.prototype.hasOwnProperty.call(
						properties,
						property,
					)
				) {
					const castedProperty = property as keyof DB.PricingModel;

					if (pricing[castedProperty] !== properties[castedProperty]) {
						return false;
					}
				}
			}

			return true;
		});
	}

	get findListerItems() {
		return (properties: Partial<DB.ProductCategoryModel>) => _.where(
			this.productcategories,
			properties,
		);
	}

	get findProductCategoryBadges() {
		return (
			listerItemId: DB.ProductCategoryModel['id'],
		) => {
			const linkModels = _.where(
				this.badgeproductcategorylinks,
				{ productcategoryid: listerItemId },
			);
			const badgeIds = linkModels.map(
				(linkModel) => linkModel.badgeid,
			);
			return this.findLocalizedBadgeImages(
				badgeIds,
			);
		};
	}

	get findRegion() {
		return (properties: Partial<DB.RegionModel>) => _.where(
			this.regions,
			properties,
		);
	}

	get findRegionCurrencyLink() {
		return (properties: Partial<DB.RegionCurrencyModel>) => _.where(
			this.regioncurrencylinks,
			properties,
		);
	}

	get findRegionCurrencyLinkWhere() {
		return (properties: Partial<DB.RegionCurrencyModel>) => _.findWhere(
			this.regioncurrencylinks,
			properties,
		);
	}

	get findRegionOfferingLink() {
		return (properties: Partial<DB.RegionOfferingModel>) => _.where(
			this.regionofferinglinks,
			properties,
		);
	}

	get findRegionOfferingLinkWhere() {
		return (properties: Partial<DB.RegionOfferingModel>) => this.regionofferinglinks.find((regionofferinglink) => {
			// eslint-disable-next-line no-restricted-syntax
			for (const property in properties) {
				if (
					Object.prototype.hasOwnProperty.call(
						properties,
						property,
					)
				) {
					const castedProperty = property as keyof DB.RegionOfferingModel;

					if (regionofferinglink[castedProperty] !== properties[castedProperty]) {
						return false;
					}
				}
			}

			return true;
		});
	}

	get findStateProv() {
		return (properties: Partial<DB.StateProvModel>) => _.where(
			this.stateprovs,
			properties,
		);
	}

	get findStateProvWhere() {
		return (properties: Partial<DB.StateProvModel>) => _.findWhere(
			this.stateprovs,
			properties,
		);
	}

	get findWhereUpsell() {
		return (properties: Partial<DB.UpsellModel>) => _.findWhere(
			this.upsells,
			properties,
		);
	}

	get getOfferingFrame() {
		return (
			offeringId: DB.OfferingModel['id'],
			pageIndex = 0,
		): OfferingFrameModel | undefined => {
			const linkModels = this.offeringofferingframelinks.filter(
				(m) => m.offeringid === offeringId,
			);
			const linkModel = linkModels.find((m) => m.pageindex === pageIndex)
				?? linkModels[0];
			if (!linkModel) {
				return undefined;
			}

			const offeringFrameModel = this.offeringframes.find(
				(m) => m.id === linkModel.offeringframeid,
			);
			if (!offeringFrameModel) {
				return undefined;
			}

			const templateModel = this.offeringframetemplates.find(
				(offeringFrameTemplateModel) => (offeringFrameTemplateModel.id === offeringFrameModel.offeringframetemplateid),
			);
			const imageModel = this.offeringframeimages.find(
				(offeringFrameImageModel) => (offeringFrameImageModel.id === offeringFrameModel.offeringframeimageid),
			);

			if (!imageModel || !templateModel) {
				return undefined;
			}

			return {
				id: offeringFrameModel.id,
				overpage: Boolean(offeringFrameModel.overpage),
				required: Boolean(offeringFrameModel.required),
				imageModel,
				templateModel,
			};
		};
	}

	get productGroupName() {
		return (
			groupid: DB.ProductGroupModel['id'],
		): string => {
			const productGroupModel = _.findWhere(
				this.productgroups,
				{ id: groupid },
			);
			if (productGroupModel) {
				return window.App.router.$i18next.exists(`productLabels.${groupid}`)
					? window.App.router.$i18next.t(`productLabels.${groupid}`)
					: productGroupModel.name;
			}

			return '';
		};
	}

	get whereUpsell() {
		return (properties: Partial<DB.UpsellModel>) => _.where(
			this.upsells,
			properties,
		);
	}

	/* @Mutation
	public changeOffering(
		data: OptionalExceptFor<OfferingModel, 'id'>,
	) {
		const i = _.findIndex(this.offerings, { id: data.id });
		if (i >= 0) {
			const model = this.offerings[i];
			Vue.set(
				this.offerings,
				i,
				_.extend(model, data),
			);
		}
	} */

	@Mutation
	setBadges(models: DB.BadgeModel[]) {
		this.badges = models;
	}

	@Mutation
	setBadgeImages(models: DB.BadgeImageModel[]) {
		this.badgeimages = models || [];
	}

	@Mutation
	setBadgeOfferingLinks(models: DB.BadgeOfferingModel[]) {
		this.badgeofferinglinks = models;
	}

	@Mutation
	setBadgePDPLinks(models: DB.BadgePDPModel[]) {
		this.badgepdplinks = models;
	}

	@Mutation
	setBadgeProductCategoryLinks(models: DB.BadgeProductCategoryModel[]) {
		this.badgeproductcategorylinks = models;
	}

	@Mutation
	setBuckets(buckets: DB.BucketModel[]) {
		this.buckets = buckets;
	}

	@Mutation
	setBulks(models: DB.BulkModel[]) {
		this.bulks = models;
	}

	@Mutation
	setBulkProductTypes(models: DB.BulkProductTypeModel[]) {
		this.bulkproducttypes = models;
	}

	@Mutation
	setBulkQuantities(models: DB.BulkQuantityModel[]) {
		this.bulkquantities = _.sortBy(
			models,
			'from',
		);
	}

	@Mutation
	setCountries(countries: DB.CountryModel[]) {
		this.countries = countries;
	}

	@Mutation
	setCurrencies(currencies: DB.CurrencyModel[]) {
		this.currencies = currencies;
	}

	@Mutation
	setFlexgroups(flexgroups: API.FlexGroupModel[]) {
		this.flexgroups = flexgroups;
	}

	@Mutation
	setHandling(handling: DB.HandlingModel[]) {
		this.handling = handling;
	}

	@Mutation
	setHyperlinks(hyperlinks: DB.HyperlinkModel[]) {
		this.hyperlinks = hyperlinks;
	}

	@Mutation
	setLanguages(languages: DB.LanguageModel[]) {
		this.languages = languages;
	}

	@Mutation
	setModels2D(models: DB.Model2DModel[]) {
		this.models2d = models;
	}

	@Mutation
	setModels3D(models: DB.Model3DModel[]) {
		this.models3d = models;
	}

	@Mutation
	setOfferingFrames(offeringframes: DB.OfferingFrameModel[]) {
		this.offeringframes = offeringframes;
	}

	@Mutation
	setOfferingFrameImages(offeringframeimages: DB.OfferingFrameImageModel[]) {
		this.offeringframeimages = offeringframeimages;
	}

	@Mutation
	setOfferingFrameTemplates(offeringframetemplates: DB.OfferingFrameTemplateModel[]) {
		this.offeringframetemplates = offeringframetemplates;
	}

	@Mutation
	protected _setOfferingFrameImage(
		payload: {
			offeringframeimageid: DB.OfferingFrameImageModel['id'];
			image: HTMLImageElement;
		},
	) {
		Vue.set(
			this._offeringframesimages,
			payload.offeringframeimageid,
			payload.image,
		);
	}

	@Mutation
	protected _setOfferingMaskImage(
		payload: {
			offeringid: DB.OfferingModel['id'];
			image: HTMLImageElement;
		},
	) {
		Vue.set(
			this._offeringmasksimages,
			payload.offeringid,
			payload.image,
		);
	}

	@Mutation
	setOfferingOfferingFrameLinks(models: DB.OfferingOfferingFrameLinkModel[]) {
		this.offeringofferingframelinks = models;
	}

	@Mutation
	protected _setOfferingOverlayImage(
		payload: {
			offeringid: DB.OfferingModel['id'];
			image: HTMLImageElement;
		},
	) {
		Vue.set(
			this._offeringoverlaysimages,
			payload.offeringid,
			payload.image,
		);
	}

	@Mutation
	setOfferings(offerings: DB.OfferingModel[]) {
		const parsedOfferings = offerings.map((offering) => ({
			...offering,
			externalid: offering.externalid ? offering.externalid.toString() : '',
		}));
		this.offerings = _.sortBy(
			parsedOfferings,
			'cost_base',
		);
	}

	@Mutation
	setOfferingOptions(offeringoptions: DB.OfferingOptionModel[]) {
		this.offeringoptions = _.sortBy(
			offeringoptions,
			'serialnumber',
		);
	}

	@Mutation
	setOfferingOptionValues(offeringoptionvalues: DB.OfferingOptionValueModel[]) {
		this.offeringoptionvalues = _.sortBy(
			offeringoptionvalues,
			'serialnumber',
		);
	}

	@Mutation
	setOfferingOptionValueOfferingLinks(offeringoptionvalueofferinglinks: DB.OfferingOptionValueOfferingModel[]) {
		this.offeringoptionvalueofferinglinks = offeringoptionvalueofferinglinks;
	}

	@Mutation
	setPDPs(pdps: API.PDPModel[]) {
		this.pdps = pdps;
	}

	@Mutation
	setPDPFilters(pdpfilters: DB.PDPFilterModel[]) {
		this.pdpfilters = pdpfilters;
	}

	@Mutation
	setPDPFilterValues(pdpfiltervalues: DB.PDPFilterValueModel[]) {
		this.pdpfiltervalues = pdpfiltervalues;
	}

	@Mutation
	setPDPFilterValueOfferingLinks(pdpfiltervalueofferinglinks: DB.PDPFilterValueOfferingModel[]) {
		this.pdpfiltervalueofferinglinks = pdpfiltervalueofferinglinks;
	}

	@Mutation
	setPDPImages(pdpimages: DB.PDPImageModel[]) {
		this.pdpimages = pdpimages;
	}

	@Mutation
	setPricing(pricing: DB.PricingModel[]) {
		this.pricing = pricing;
	}

	@Mutation
	setProductCategories(categories: API.ProductCategoryModel[]) {
		this.productcategories = _.sortBy(
			categories,
			'serialnumber',
		);
	}

	@Mutation
	setProductGroups(productgroups: DB.ProductGroupModel[]) {
		this.productgroups = productgroups;
	}

	@Mutation
	setRegionCurrencyLinks(models: DB.RegionCurrencyModel[]) {
		this.regioncurrencylinks = models;
	}

	@Mutation
	setRegionOfferingLinks(models: DB.RegionOfferingModel[]) {
		this.regionofferinglinks = models;
	}

	@Mutation
	setRegions(regions: DB.RegionModel[]) {
		this.regions = regions;
	}

	@Mutation
	setStateProvs(stateprovs: DB.StateProvModel[]) {
		this.stateprovs = _.sortBy(
			stateprovs,
			'name',
		);
	}

	@Mutation
	setUpsells(models: DB.UpsellModel[]) {
		this.upsells = models;
	}

	@Mutation
	protected _unsetOfferingFrameImage(
		offeringframeimageid: DB.OfferingFrameImageModel['id'],
	) {
		delete this._offeringframesimages[offeringframeimageid];
	}

	@Mutation
	protected _unsetOfferingMaskImage(
		offeringid: DB.OfferingModel['id'],
	) {
		delete this._offeringmasksimages[offeringid];
	}

	@Mutation
	protected _unsetOfferingOverlayImage(
		offeringid: DB.OfferingModel['id'],
	) {
		delete this._offeringoverlaysimages[offeringid];
	}

	@Action({ rawError: true })
	public async resetOfferingData(): Promise<void> {
		this.setOfferings([]);
		this.setOfferingOptions([]);
		this.setOfferingOptionValues([]);
		this.setOfferingOptionValueOfferingLinks([]);
		this.setRegionOfferingLinks([]);
		this.setOfferingFrames([]);
		this.setOfferingFrameImages([]);
		this.setOfferingFrameTemplates([]);
		this.setOfferingOfferingFrameLinks([]);
	}

	@Action({ rawError: true })
	public async fetchProductCategoriesData({
		requestOptions,
		methodOptions,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	} = {}): Promise<void> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${window.glAppUrl}api/app/productcategoriesdata`,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: false,
			debug: {
				dialog: true,
			},
		};

		requestOptions = (
			requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions
		);
		methodOptions = (
			methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions
		);

		if (requestOptions.url) {
			const url = new URL(requestOptions.url);

			if (window.glStack !== 'live') {
				// We always want to get the latest data from the server when in dev/test/staging mode
				url.searchParams.set(
					'version',
					(new Date().getTime() / 1000).toString(),
				);
			} else if (window.appDataVersion) {
				// In production we want to make use of cloudFront caching, so we need a version number
				url.searchParams.set(
					'version',
					window.appDataVersion,
				);
			}

			if (UserModule.countryid) {
				url.searchParams.set(
					'countryid',
					UserModule.countryid.toString(),
				);
			}

			if (UserModule.currency) {
				url.searchParams.set(
					'usercurrency',
					UserModule.currency,
				);
			}

			if (UserModule.language) {
				url.searchParams.set(
					'userlanguage',
					UserModule.language,
				);
			}

			requestOptions.url = url.toString();
		}

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				const responseData = response.data;
				return this.setProductCategories(_.toArray(responseData.productcategories));
			})
			.then(() => undefined);
	}

	@Action({ rawError: true })
	public async fetchOfferingsData({
		requestOptions,
		methodOptions,
		offeringIds,
		searchProps,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
		offeringIds?: number[];
		searchProps?: PublicOptionalProps<Pick<DB.OfferingModel, 'id' | 'pdpid' | 'groupid' | 'typeid' | 'variantid' | 'flexgroupid'>> & { get_from_flexgroupid?: boolean };
	}): Promise<void> {
		if (window.glPlatform === 'server') {
			return undefined;
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${window.glAppUrl}api/app/offeringsdata`,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: false,
			debug: {
				dialog: true,
			},
		};

		if (
			typeof offeringIds === 'undefined'
			&& typeof searchProps === 'undefined'
		) {
			throw new Error('You must provide either `offeringIds` or `searchProps` to fetch the offerings data');
		}

		requestOptions = (
			requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions
		);
		methodOptions = (
			methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions
		);

		if (requestOptions.url) {
			const url = new URL(requestOptions.url);

			if (window.glStack !== 'live') {
				// We always want to get the latest data from the server when in dev/test/staging mode
				url.searchParams.set(
					'version',
					(new Date().getTime() / 1000).toString(),
				);
			} else if (window.appDataVersion) {
				// In production we want to make use of cloudFront caching, so we need a version number
				url.searchParams.set(
					'version',
					window.appDataVersion,
				);
			}

			if (offeringIds) {
				url.searchParams.set(
					'ids',
					offeringIds.join(','),
				);
			} else if (searchProps) {
				if (typeof searchProps.get_from_flexgroupid !== 'undefined') {
					url.searchParams.set(
						'get_from_flexgroupid',
						searchProps.get_from_flexgroupid.toString(),
					);
					delete searchProps.get_from_flexgroupid;
				}

				const searchPropsKeys = Object.keys(searchProps) as (keyof typeof searchProps)[];
				searchPropsKeys.sort();
				const searchParam = searchPropsKeys.reduce(
					(object, key) => ({
						...object,
						[key]: searchProps[key],
					}),
					{},
				);
				url.searchParams.set(
					'where',
					JSON.stringify(searchParam),
				);
			}

			requestOptions.url = url.toString();
		}

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then(async (response) => {
				const responseData = response.data;

				if (!this.regionofferinglinks) {
					this.setRegionOfferingLinks([]);
				}
				if (!this.offeringframes) {
					this.setOfferingFrames([]);
				}
				if (!this.offeringframetemplates) {
					this.setOfferingFrameTemplates([]);
				}
				if (!this.offeringframeimages) {
					this.setOfferingFrameImages([]);
				}
				if (!this.offeringofferingframelinks) {
					this.setOfferingOfferingFrameLinks([]);
				}
				if (!this.offerings) {
					this.setOfferings([]);
				}
				if (!this.offeringoptions) {
					this.setOfferingOptions([]);
				}
				if (!this.offeringoptionvalues) {
					this.setOfferingOptionValues([]);
				}
				if (!this.offeringoptionvalueofferinglinks) {
					this.setOfferingOptionValueOfferingLinks([]);
				}

				return Promise.all([
					this.setRegionOfferingLinks(
						arrayUtils.mergeAndExcludeByKey(
							this.regionofferinglinks,
							_.toArray<DB.RegionOfferingModel[]>(responseData.region_offerings),
							'id',
						),
					),
					this.setOfferingFrames(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringframes,
							_.toArray<DB.OfferingFrameModel[]>(responseData.offeringframes),
							'id',
						),
					),
					this.setOfferingFrameImages(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringframeimages,
							_.toArray<DB.OfferingFrameImageModel[]>(responseData.offeringframeimages),
							'id',
						),
					),
					this.setOfferingFrameTemplates(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringframetemplates,
							_.toArray<DB.OfferingFrameTemplateModel[]>(responseData.offeringframetemplates),
							'id',
						),
					),
					this.setOfferingOfferingFrameLinks(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringofferingframelinks,
							_.toArray<DB.OfferingOfferingFrameLinkModel[]>(responseData.offering_offeringframes),
							'id',
						),
					),
					this.setOfferings(
						arrayUtils.mergeAndExcludeByKey(
							this.offerings,
							_.toArray<DB.OfferingModel[]>(responseData.offerings),
							'id',
						),
					),
					this.setOfferingOptions(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringoptions,
							responseData.offeringoptions as DB.OfferingOptionModel[],
							'id',
						),
					),
					this.setOfferingOptionValues(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringoptionvalues,
							responseData.offeringoptionvalues as DB.OfferingOptionValueModel[],
							'id',
						),
					),
					this.setOfferingOptionValueOfferingLinks(
						arrayUtils.mergeAndExcludeByKey(
							this.offeringoptionvalueofferinglinks,
							responseData.offeringoptionvalue_offerings as DB.OfferingOptionValueOfferingModel[],
							'id',
						),
					),
				]);
			})
			.then(() => undefined);
	}

	@Action({ rawError: true })
	public fetch({
		requestOptions,
		methodOptions,
		dataToExclude,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
		dataToExclude?: StartAppDataToExclude[];
	} = {}): Promise<void> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: this.collectionUrl,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: false,
			debug: {
				dialog: true,
			},
		};

		requestOptions = (
			requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions
		);
		methodOptions = (
			methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions
		);

		if (
			dataToExclude?.length
			&& requestOptions.url
		) {
			const url = new URL(requestOptions.url);
			url.searchParams.set(
				'exclude',
				dataToExclude.join(','),
			);

			if (UserModule.countryid) {
				url.searchParams.set(
					'countryid',
					UserModule.countryid.toString(),
				);
			}

			if (UserModule.currency) {
				url.searchParams.set(
					'usercurrency',
					UserModule.currency,
				);
			}

			if (UserModule.language) {
				url.searchParams.set(
					'userlanguage',
					UserModule.language,
				);
			}

			requestOptions.url = url.toString();
		}

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				const responseData = response.data;

				ConfigModule.addModels(responseData.settings);

				if (responseData.languages) {
					this.setLanguages(_.toArray(responseData.languages));
				}
				if (responseData.countries) {
					this.setCountries(_.toArray(responseData.countries));
				}
				if (responseData.stateprovs) {
					this.setStateProvs(_.toArray(responseData.stateprovs));
				}
				if (responseData.pricing) {
					this.setPricing(_.toArray(responseData.pricing));
				}
				if (responseData.photosources) {
					ChannelsModule.addModels(_.toArray(responseData.photosources));
				}
				if (responseData.productgroups) {
					this.setProductGroups(_.toArray(responseData.productgroups));
				}
				if (responseData.regions) {
					this.setRegions(_.toArray(responseData.regions));
				}
				if (responseData.region_offerings) {
					this.setRegionOfferingLinks(_.toArray(responseData.region_offerings));
				}
				if (responseData.upsell) {
					this.setUpsells(_.toArray(responseData.upsell));
				}
				if (responseData.flexgroups) {
					this.setFlexgroups(responseData.flexgroups);
				}
				if (responseData.offeringframes) {
					this.setOfferingFrames(_.toArray(responseData.offeringframes));
				}
				if (responseData.productcategories) {
					this.setProductCategories(_.toArray(responseData.productcategories));
				}
				if (responseData.handling) {
					this.setHandling(_.toArray(responseData.handling));
				}

				this.setBulks(_.toArray(responseData.bulk));
				this.setBulkQuantities(_.toArray(responseData.bulkquantity));
				this.setBulkProductTypes(_.toArray(responseData.bulkproducttypes));

				if (responseData.buckets) {
					this.setBuckets(_.toArray(responseData.buckets));
				}
				if (responseData.currencies) {
					this.setCurrencies(_.toArray(responseData.currencies));
				}
				if (responseData.region_currencies) {
					this.setRegionCurrencyLinks(_.toArray(responseData.region_currencies));
				}
				if (responseData.offerings) {
					this.setOfferings(_.toArray(responseData.offerings));
				}
				if (responseData.offeringoptions) {
					this.setOfferingOptions(responseData.offeringoptions);
				}
				if (responseData.offeringoptionvalues) {
					this.setOfferingOptionValues(responseData.offeringoptionvalues);
				}
				if (responseData.offeringoptionvalue_offerings) {
					this.setOfferingOptionValueOfferingLinks(responseData.offeringoptionvalue_offerings);
				}
				if (responseData.model2ds) {
					this.setModels2D(responseData.model2ds);
				}
				if (responseData.model3ds) {
					this.setModels3D(responseData.model3ds);
				}
				if (responseData.hyperlinks) {
					this.setHyperlinks(_.toArray(responseData.hyperlinks));
				}
				if (responseData.pdps) {
					this.setPDPs(responseData.pdps);
				}
				if (responseData.pdpfilters) {
					this.setPDPFilters(responseData.pdpfilters);
				}
				if (responseData.pdpfiltervalues) {
					this.setPDPFilterValues(responseData.pdpfiltervalues);
				}
				if (responseData.pdpfiltervalue_offerings) {
					this.setPDPFilterValueOfferingLinks(responseData.pdpfiltervalue_offerings);
				}
				if (responseData.pdpimages) {
					this.setPDPImages(responseData.pdpimages);
				}

				this.setBadges(responseData.badges);
				this.setBadgeImages(responseData.badgeimages);
				this.setBadgeOfferingLinks(responseData.badge_offerings);
				this.setBadgePDPLinks(responseData.badge_pdp);
				this.setBadgeProductCategoryLinks(responseData.badge_productcategory);

				if (responseData.fonts) {
					// Fonts need to be parsed after the buckets, as they need the url path
					FontModule
						.addModels(_.toArray(responseData.fonts))
						.catch(() => {
							// Swallow error: no action required
						});
				}

				if (responseData.translations) {
					responseData.translations.forEach((translationRecord: DB.TranslationModel) => {
						window.App.router.$i18next.addResourceBundle(
							translationRecord.language,
							translationRecord.namespace,
							translationRecord.bundle,
						);
					});
				}

				// Load the Webfont to display the Material Icons used in the interface of this app
				// We return a promise so we can handle a failure in loading these required fonts
				return FontModule
					.addModels([
						{
							id: 'Material Icons',
							provider: 'google',
							print: false,
							autoload: true,
						},
						{
							id: 'Material Icons Round',
							provider: 'google',
							print: false,
							autoload: true,
						},
						{
							id: 'Material Icons Outlined',
							provider: 'google',
							print: false,
							autoload: true,
						},
					])
					.then(() => undefined);
			});
	}

	@Action({ rawError: true })
	public getAndSetOfferingFrameImage(offeringframeimage: DB.OfferingFrameImageModel): void {
		const offeringFrameImageFound = this._offeringframesimages[offeringframeimage.id];

		if (!offeringFrameImageFound) {
			const image = new Image();

			// This enumerated attribute indicates if the fetching of the related image must be done using CORS or not.
			// CORS-enabled images can be reused in the <canvas> element without being tainted.
			image.crossOrigin = 'Anonymous';

			image.onload = () => {
				this.setOfferingFrameImage({
					offeringframeimageid: offeringframeimage.id,
					image,
				});
			};
			image.src = offeringframeimage.url;
		}
	}

	@Action({ rawError: true })
	public getAndSetOfferingMaskImage(offering: DB.OfferingModel): void {
		const offeringMaskImageFound = this._offeringmasksimages[offering.id];

		if (!offeringMaskImageFound) {
			const image = new Image();

			// This enumerated attribute indicates if the fetching of the related image must be done using CORS or not.
			// CORS-enabled images can be reused in the <canvas> element without being tainted.
			image.crossOrigin = 'Anonymous';

			image.onload = () => {
				this.setOfferingMaskImage({
					offeringid: offering.id,
					image,
				});
			};
			image.src = offering.mask as string;
		}
	}

	@Action({ rawError: true })
	public getAndSetOfferingOverlayImage(offering: DB.OfferingModel): void {
		const offeringOverlayImageFound = this._offeringoverlaysimages[offering.id];

		if (!offeringOverlayImageFound) {
			const image = new Image();

			// This enumerated attribute indicates if the fetching of the related image must be done using CORS or not.
			// CORS-enabled images can be reused in the <canvas> element without being tainted.
			image.crossOrigin = 'Anonymous';

			image.onload = () => {
				this.setOfferingOverlayImage({
					offeringid: offering.id,
					image,
				});
			};
			image.src = offering.overlay as string;
		}
	}

	@Action({ rawError: true })
	public setOfferingFrameImage(
		payload: {
			offeringframeimageid: DB.OfferingFrameImageModel['id'],
			image: HTMLImageElement,
		},
	) {
		this._setOfferingFrameImage(payload);
	}

	@Action({ rawError: true })
	public setOfferingMaskImage(
		payload: {
			offeringid: DB.OfferingModel['id'],
			image: HTMLImageElement,
		},
	) {
		this._setOfferingMaskImage(payload);
	}

	@Action({ rawError: true })
	public setOfferingOverlayImage(
		payload: {
			offeringid: DB.OfferingModel['id'],
			image: HTMLImageElement,
		},
	) {
		this._setOfferingOverlayImage(payload);
	}

	@Action({ rawError: true })
	public unsetOfferingFrameImage(offeringframe: OfferingFrameModel): void {
		const offeringFrameImageFound = this._offeringframesimages[offeringframe.id];

		if (offeringFrameImageFound) {
			this._unsetOfferingFrameImage(offeringframe.id);
		}
	}

	@Action({ rawError: true })
	public unsetOfferingMaskImage(offering: DB.OfferingModel): void {
		const offeringMaskImageFound = this._offeringmasksimages[offering.id];

		if (offeringMaskImageFound) {
			this._unsetOfferingMaskImage(offering.id);
		}
	}

	@Action({ rawError: true })
	public unsetOfferingOverlayImage(offering: DB.OfferingModel): void {
		const offeringOverlayImageFound = this._offeringoverlaysimages[offering.id];

		if (offeringOverlayImageFound) {
			this._unsetOfferingOverlayImage(offering.id);
		}
	}
}
