import merge from 'deepmerge';
import logBreadcrumb from 'services/log-breadcrumb';
import * as DB from 'interfaces/database';
import {
	PricingObject,
	AnalyticsSubController,
	AnalyticsEcommerceProduct,
	AnalyticsUserProperties,
	AnalyticsSessionProperties,
} from 'interfaces/app';
import {
	UserModule,
	AppDataModule,
	ProductsModule,
	ConfigModule,
	ExternalUsersModule,
} from '../../store/index';

export default class SegmentController implements AnalyticsSubController {
	private userProperties: AnalyticsUserProperties = {};

	public init() {
		if (ConfigModule['analytics.segment.id']
			&& ConfigModule['analytics.segment.enabled']
		) {
			this.loadLibrary(ConfigModule['analytics.segment.id']);

			// Create breadcrumb for debugging
			logBreadcrumb(
				'Segment Analytics: Load',
				{
					writeKey: ConfigModule['analytics.segment.id'],
				},
				{
					console: ConfigModule['analytics.segment.debug'],
				},
			);

			window.analytics.page();

			this.identifyUser();

			return true;
		}
		return false;
	}

	private loadLibrary(
		key: string,
	) {
		/* eslint-disable */
		// Create a queue, but don't obliterate an existing one!
		const analytics = window.analytics = window.analytics || [];
		// If the real analytics.js is already on the page return.
		// @ts-ignore
		if (analytics.initialize) return;
		// If the snippet was invoked already show an error.
		// @ts-ignore
		if (analytics.invoked) {
			if (window.console && console.error) {
				console.error('Segment snippet included twice.');
			}
			return;
		}
		// Invoked flag, to make sure the snippet
		// is never invoked twice.
		// @ts-ignore
		analytics.invoked = true;
		// A list of the methods in Analytics.js to stub.
		// @ts-ignore
		analytics.methods = [
			'trackSubmit',
			'trackClick',
			'trackLink',
			'trackForm',
			'pageview',
			'identify',
			'reset',
			'group',
			'track',
			'ready',
			'alias',
			'debug',
			'page',
			'once',
			'off',
			'on',
			'addSourceMiddleware',
			'addIntegrationMiddleware',
			'setAnonymousId',
			'addDestinationMiddleware',
		];
		// Define a factory to create stubs. These are placeholders
		// for methods in Analytics.js so that you never have to wait
		// for it to load to actually record data. The `method` is
		// stored as the first argument, so we can replay the data.
		// @ts-ignore
		analytics.factory = function (method) {
			return function () {
				const args = Array.prototype.slice.call(arguments);
				args.unshift(method);
				// @ts-ignore
				analytics.push(args);
				return analytics;
			};
		};
		// For each of our methods, generate a queueing stub.
		// @ts-ignore
		for (let i = 0; i < analytics.methods.length; i++) {
			// @ts-ignore
			const key = analytics.methods[i];
			// @ts-ignore
			analytics[key] = analytics.factory(key);
		}
		// Define a method to load Analytics.js from our CDN,
		// and that will be sure to only ever load it once.
		// @ts-ignore
		analytics.load = function (key, options) {
			// Create an async script element based on your key.
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
			// Insert our script next to the first script element.
			const first = document.getElementsByTagName('script')[0];
			// @ts-ignore
			first.parentNode.insertBefore(script, first);
			// @ts-ignore
			analytics._loadOptions = options;
		};
		// @ts-ignore
		analytics._writeKey = key;
		// Add a version to keep track of what's in the wild.
		// @ts-ignore
		analytics.SNIPPET_VERSION = '4.15.2';
		/* eslint-disable */

		// Load Analytics.js with your key, which will automatically
		// load the tools you've enabled for your account. Boosh!
		analytics.load(key);
	}

	public setExperimentFlags(
		flags: Record<string, string | number | boolean | null>,
	) {
		window.analytics.identify(
			flags,
		);

		// Create breadcrumb for debugging
		logBreadcrumb(
			'Segment Analytics: Set Experiment Flag',
			flags,
			{
				console: ConfigModule['analytics.segment.debug'],
			},
		);
	}

	public setUserProperties(
		objProperties: AnalyticsUserProperties,
	) {
		this.userProperties = merge(
			this.userProperties,
			objProperties,
		);

		this.identifyUser();
	}

	public logout() {
		window.analytics.reset();
	}

	private track(
		event: string,
		properties: Record<string, any>,
		options?: SegmentAnalytics.SegmentOpts,
	) {
		window.analytics.track(
			event,
			properties,
			options,
		);

		// Create breadcrumb for debugging
		logBreadcrumb(
			'Segment Analytics: Track Event',
			{
				eventName: event,
				eventProperties: properties,
				eventOptions: options,
			},
			{
				console: ConfigModule['analytics.segment.debug'],
			},
		);
	}

	public aliasUser(
		oldUserId: DB.UserModel['id'],
		newUserId: DB.UserModel['id'],
	) {
		if (newUserId) {
			window.analytics.alias(
				newUserId.toString(),
			);
		}
	}

	public registerUser() {
		this.identifyUser();
	}

	public identifyUser() {
		// Standardize user properties
		const stdUserProperties: {
			[key: string]: string | number | undefined | null;
		} = {};
		(Object.keys(this.userProperties) as (keyof AnalyticsUserProperties)[]).forEach((key) => {
			const val = this.userProperties[key];

			if (key.toLowerCase() == 'account created') {
				stdUserProperties.createdAt = val;
			} else if (key.toLowerCase() == 'first_name') {
				stdUserProperties.firstName = val;
			} else if (key.toLowerCase() == 'last_name') {
				stdUserProperties.lastName = val;
			} else {
				stdUserProperties[key.toLowerCase()] = val;
			}
		});

		if (UserModule.id) {
			let userIdentifier: string | undefined;

			if (ConfigModule['analytics.segment.externalUserId']) {
				const externalUser = ExternalUsersModule.findWhere({
					source: 'app',
				});
				if (externalUser
					&& externalUser.externalId
				) {
					userIdentifier = externalUser.externalId;
				}
			} else {
				userIdentifier = UserModule.id.toString();
			}

			if (!userIdentifier) {
				throw new Error(
					'Missing user identifier for Segment analytics'
				);
			}

			// Identify user in Segment
			window.analytics.identify(
				userIdentifier,
				stdUserProperties,
			);

			if (window.glPlatform == 'native') {
				if (!window.webToNative) {
					throw new Error('Missing WebToNative on window');
				}

				window.webToNative.identifyUser(
					userIdentifier,
					{
						segment: stdUserProperties,
					},
				);
			}
		} else {
			window.analytics.identify(
				stdUserProperties,
			);
		}

		// Create breadcrumb for debugging
		logBreadcrumb(
			'Segment Analytics: Identify User',
			{
				userId: UserModule.id?.toString(),
				userProperties: stdUserProperties,
			},
			{
				console: ConfigModule['analytics.segment.debug'],
			},
		);
	}

	public trackPageView(
		route: string,
		title: string,
		objProperties: Record<string, any>,
	) {
		const trackProperties = {
			...objProperties,
			title,
			path: route,
		};
		window.analytics.page(
			title,
			trackProperties,
		);

		// Create breadcrumb for debugging
		logBreadcrumb(
			'Segment Analytics: Page View',
			trackProperties,
			{
				console: ConfigModule['analytics.segment.debug'],
			},
		);
	}

	public trackEvent(
		action: string,
		objProperties: Record<string, any>,
	) {
		this.track(
			action,
			objProperties,
		);
	}

	public trackProductReady(
		productModel: DB.ProductModel | null,
		objPrice: PricingObject | null,
		objProperties: AnalyticsSessionProperties & {
			pageCount: number;
			photoCount: number;
		},
	) {
		const properties = {
			page_count: objProperties.pageCount,
			photo_count: objProperties.photoCount,
			groupid: productModel ? productModel.group : undefined,
			typeid: productModel ? productModel.typeid : undefined,
			price: objPrice ? Math.round(objPrice.subTotal) / 100 : undefined,
			theme_id: productModel ? productModel.themeid : undefined,
			layout_id: productModel ? productModel.layoutid : undefined,
			product_origin: productModel ? productModel.origin : undefined,
		};

		this.track('Product ready', properties);
	}

	public trackAddToCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objPrice: PricingObject,
		objProperties: Record<string, any>,
	) {
		const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
		if (offeringModel) {
			const productModel = ProductsModule.getById(cartItemModel.productid);

			const baseUrl = `${window.location.protocol}//${window.location.host}`;
			const productUrl = `${baseUrl}/app/${cartItemModel.productid}/open`;

			this.track('Product Added', {
				...objProperties,
				// Semantic properties
				cart_id: cartItemModel.cartid,
				product_id: cartItemModel.productid,
				sku: offeringModel.id,
				category: AppDataModule.getProductCategoryName(offeringModel.groupid),
				name: AppDataModule.getOfferingName(offeringModel.id),
				variant: offeringModel.variantid,
				price: Math.round(objPrice.subTotal) / 100,
				quantity: cartItemModel.quantity,
				url: productUrl,
				// Custom properties
				groupid: cartItemModel.groupid,
				product_origin: productModel
					? productModel.origin
					: undefined,
			});
		}
	}

	public trackRemoveFromCart(
		cartItemModel: DB.ShoppingCartItemModel,
		objProperties: Record<string, any>,
	) {
		const offeringModel = AppDataModule.getOffering(cartItemModel.offeringid);
		if (offeringModel) {
			this.track('Removed Product', {
				...objProperties,
				cart_id: cartItemModel.cartid,
				product_id: cartItemModel.productid,
				sku: offeringModel.id,
				category: AppDataModule.getProductCategoryName(offeringModel.groupid),
				name: AppDataModule.getOfferingName(offeringModel.id),
				variant: offeringModel.variantid,
				quantity: cartItemModel.quantity,
			});
		}
	}

	public trackPerformance(
		action: string,
		value: number,
		objProperties: Record<string, any>,
	) {
		this.track(
			`Performance: ${action}`,
			{
				...objProperties,
				performanceValue: value,
			},
		);
	}

	public trackCreateProduct(
		productModel: DB.ProductModel,
		objProperties: Record<string, any>,
	) {
		this.track(
			'Create Product',
			objProperties,
		);
	}

	public trackOpenProduct(
		productModel: DB.ProductModel,
		objProperties: Record<string, any>,
	) {
		this.track(
			'Open Product',
			objProperties,
		);
	}

	public trackTransaction(
		orderModel: DB.OrderModel,
		orderItems: DB.OrderItemModel[],
		objProperties: Record<string, any>,
	) {
		const arrProducts: AnalyticsEcommerceProduct[] = [];
		orderItems.forEach((orderItemModel) => {
			const offeringModel = AppDataModule.getOffering(orderItemModel.offeringid);
			if (offeringModel) {
				arrProducts.push({
					...objProperties,
					product_id: orderItemModel.productid.toString(),
					sku: offeringModel.id.toString(),
					category: AppDataModule.getProductCategoryName(offeringModel.groupid),
					name: AppDataModule.getOfferingName(offeringModel.id),
					variant: offeringModel.variantid.toString(),
					price: Math.round(orderItemModel.salesvalue) / 100,
					quantity: orderItemModel.quantity,
				});
			}
		});

		this.track('Order Completed', {
			...objProperties,
			order_id: orderModel.id,
			total: Math.round(orderModel.salesvalue) / 100,
			shipping: Math.round(orderModel.price_shipping) / 100,
			tax: Math.round(orderModel.total_tax) / 100,
			discount: Math.round(orderModel.discount_voucher) / 100,
			coupon: orderModel.voucherid ? `${orderModel.voucherid}` : null,
			currency: orderModel.currency,
			products: arrProducts,
		}, {
			integrations: {
				Amplitude: false,
				Mixpanel: false,
			},
		});
	}
}
