import './defines';
import IconComponent from 'components/icon';
import {
	ColorPickerColor,
	ColorPickerColors,
	ColorPickerPattern,
	ColorPickerPatterns,
} from 'interfaces/app';
import {
	mobile as mobileTools,
	url as urlTools,
} from 'tools';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import { color as colorUtils } from 'utils';
import { Public } from 'utils/decorators';
import Template from './template.vue';

@Component({
	name: 'ColorPickerComponent',
})
export default class ColorPicker extends Vue.extend(Template) {
	@Model(
		'change',
		{
			default: undefined,
			description: 'Defines the color that is selected',
			type: String,
		},
	)
	public readonly value?: ColorPickerColor['colors']['primary'] | ColorPickerColor['colors']['pallet'][number];

	@Prop({
		description: 'Defines the canvas element that is used to get the color from (to use with the eye dropper)',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop({
		description: 'Defines the magnification of the eye dropper',
		required: true,
		type: Number,
	})
	public readonly magnification!: number;

	@Prop({
		default: false,
		description: 'Indicates if the color picker would use the large (not thick and compact) variant',
		type: Boolean,
	})
	public readonly large?: boolean;

	@Prop({
		default: undefined,
		description: 'Defines the patterns images available for selection',
		type: Array,
		schema: 'ColorPickerPatterns',
	})
	public readonly patterns?: ColorPickerPatterns;

	@Prop({
		default: false,
		description: 'Indicates if the color picker has transparent color available for selection',
		type: Boolean,
	})
	public readonly transparent?: boolean;

	protected get colorPalletEyeDropperStyles(): Partial<CSSStyleDeclaration> & Record<string, string> {
		if (
			this.isColorFromEyeDropperSelected()
			&& this.internalValue
		) {
			return {
				backgroundColor: this.internalValue,
				'--color-pallet-eyedropper-border-color': 'var(--white1)',
				'--color-pallet-eyedropper-box-shadow-color': this.internalValue,
			};
		}

		return {};
	}

	protected get colorPalletEyeDropperIconStyles(): Partial<CSSStyleDeclaration> {
		if (
			this.isColorFromEyeDropperSelected()
			&& this.internalValue
			&& this.$el
		) {
			const black1Color = getComputedStyle(this.$el).getPropertyValue('--icon-neutral-black1');
			const white1Color = getComputedStyle(this.$el).getPropertyValue('--icon-neutral-white1');
			const contrastBlack1 = colorUtils.getContrastRatio(
				black1Color,
				this.internalValue,
			);
			const contrastWhite1 = colorUtils.getContrastRatio(
				white1Color,
				this.internalValue,
			);

			if (contrastBlack1 < contrastWhite1) {
				return {
					color: white1Color,
				};
			}
		}

		return {};
	}

	private get colors(): ColorPickerColors {
		const colors = [
			'neutral',
			'red',
			'orange',
			'yellow',
			'green',
			'turquoise',
			'blue',
			'purple',
			'pink',
		];

		return colors.map((color) => ({
			id: color,
			colors: {
				primary: `var(--color-picker-${color}-slider)`,
				pallet: [...Array(6).keys()].map((index) => `var(--color-picker-${color}-${index + 1})`),
			},
		}));
	}

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get selectedColor(): ColorPickerColor {
		return (
			this.colors.find((color) => color.colors.primary === this.activeColor)
			|| this.colors[0]
		);
	}

	protected get selectedColorPallet(): string[] {
		const colorPallets = [...this.selectedColor.colors.pallet];

		if (this.transparent) {
			colorPallets.unshift('transparent');
		}
		if (
			this.patterns
			&& this.activePattern
		) {
			colorPallets.splice(
				(
					this.transparent
						? 1
						: 0
				),
				colorPallets.length,
				...this.patterns.map((pattern) => pattern.image),
			);
		}

		return colorPallets;
	}

	@Ref('colorSlider')
	private readonly colorSliderElement!: HTMLDivElement;

	private activeColor: ColorPickerColor['colors']['primary'] | ColorPickerColor['colors']['pallet'][number] | null = null;

	private activePattern = false;

	private elementObserver?: MutationObserver;

	private eyeDropperElement?: HTMLDivElement;

	private internalValue: string | null = null;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private isMouseDown!: boolean;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.destroyEyeDropper();
		this.computedCanvas.style.touchAction = '';
		this.computedCanvas.removeEventListener(
			'draw',
			this.onCanvasDraw,
		);

		if (this.elementObserver) {
			this.elementObserver.disconnect();
		}

		this.colorSliderElement?.removeEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.colorSliderElement?.removeEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
	}

	protected created(): void {
		this.isMouseDown = false;

		if (
			!this.internalValue
			|| this.isColorFromEyeDropperSelected()
		) {
			this.activeColor = this.colors[0].colors.primary;
		}

		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected mounted(): void {
		this.elementObserver = new MutationObserver(() => {
			if ((this.$el as HTMLElement).style.display === 'none') {
				this.destroyEyeDropper();
			}
		});
		this.elementObserver.observe(
			this.$el,
			{
				attributes: true,
			},
		);
		this.$forceUpdate();
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		let isPattern = false;

		if (this.internalValue) {
			const colorFound = this.getColor(this.internalValue);

			if (colorFound) {
				this.activeColor = colorFound.colors.primary;
				this.activePattern = false;
			} else if (
				this.patterns
				&& this.internalValue !== 'transparent'
				&& !this.isColorFromEyeDropperSelected()
			) {
				this.activePattern = !!this.patterns.find((pattern) => pattern.image === this.internalValue);

				if (this.activePattern) {
					isPattern = true;
					this.activeColor = null;
				}
			}
		}

		if (this.internalValue !== this.value) {
			this.$emit(
				'change',
				this.internalValue,
				(
					isPattern
						? 'pattern'
						: 'color'
				),
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = this.value || null;
		});
	}

	private destroyEyeDropper(): void {
		if (this.eyeDropperElement) {
			window.removeEventListener(
				'click',
				this.onWindowClick,
			);
			this.computedCanvas.removeEventListener(
				'mousemove',
				this.onCanvasMouseMove,
			);
			this.computedCanvas.removeEventListener(
				'touchstart',
				this.onCanvasMouseMove,
			);
			this.computedCanvas.removeEventListener(
				'touchmove',
				this.onCanvasMouseMove,
			);
			this.computedCanvas.removeEventListener(
				'touchend',
				this.onCanvasMouseUp,
			);
			this.computedCanvas.removeEventListener(
				'mouseup',
				this.onCanvasMouseUp,
			);
			this.eyeDropperElement.remove();
			this.eyeDropperElement = undefined;
			this.computedCanvas.style.touchAction = '';
			this.$nextTick(() => {
				requestAnimationFrame(() => this.$emit('eye-dropper-end'));
			});
		}
	}

	protected getColor(color: string): ColorPickerColor {
		return this.colors.find(
			(currentColor) => {
				if (this.getColorValue(currentColor.colors.primary).toLocaleLowerCase() === color.toLocaleLowerCase()) {
					return true;
				}

				return currentColor.colors.pallet
					.find((palletColor) => this.getColorValue(palletColor).toLocaleLowerCase() === color.toLocaleLowerCase());
			},
		) as ColorPickerColor;
	}

	private getColorFromMousePosition(event: MouseEvent | TouchEvent): ColorPickerColor | 'pattern' | undefined {
		let primaryColor: string | undefined;
		let isPattern = false;
		let eventTarget = event.target as HTMLElement;
		const touch = (
			'touches' in event
			&& event.touches[0]
		);

		if (touch) {
			eventTarget = document.elementFromPoint(
				touch.clientX,
				touch.clientY,
			) as HTMLElement;
		}

		if (eventTarget) {
			if (eventTarget.classList.contains('color-slider-item')) {
				if (!eventTarget.classList.contains('color-slider-item-pattern')) {
					primaryColor = eventTarget.getAttribute('data-color') || undefined;
				} else {
					isPattern = true;
				}
			} else {
				const childFound = Array
					.from(eventTarget.children)
					.find((element) => element.classList.contains('color-slider-item'));
				const parentFound = (
					eventTarget.parentElement?.classList.contains('color-slider-item')
						? eventTarget.parentElement
						: undefined
				);
				const elementFound = childFound || parentFound;

				if (elementFound) {
					if (!elementFound.classList.contains('color-slider-item-pattern')) {
						primaryColor = elementFound.getAttribute('data-color') || undefined;
					} else {
						isPattern = true;
					}
				}
			}
		}

		if (primaryColor) {
			return this.colors.find((color) => color.colors.primary === primaryColor);
		}
		if (isPattern) {
			return 'pattern';
		}

		return undefined;
	}

	protected getColorSliderItemClasses(colorIndex: number): Record<string, boolean> {
		return {
			first: colorIndex === 0,
			last: (
				colorIndex === (this.colors.length - 1)
				&& !this.patterns
			),
		};
	}

	protected getColorSliderItemStyles(colorOrPattern: ColorPickerColor | ColorPickerPattern | undefined): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (colorOrPattern) {
			if ('colors' in colorOrPattern) {
				styles.backgroundColor = colorOrPattern.colors.primary;
			} else if (
				this.patterns?.length
				&& 'image' in colorOrPattern
			) {
				styles.backgroundColor = colorOrPattern.color;
				styles.backgroundImage = `url(${urlTools.appendParameter(
					colorOrPattern.image,
					'noCorsHeader',
				)})`;
			}
		}

		return styles;
	}

	protected getColorPalletItemClasses(color: string): Record<string, boolean> {
		return {
			'color-pallet-item-selected': this.isColorPalletSelected(color),
			'color-pallet-item-transparent': color === 'transparent',
		};
	}

	protected getColorPalletItemDataColor(color: string): string | undefined {
		if (
			!this.activePattern
			|| color === 'transparent'
		) {
			return this.selectedColor.id;
		}

		return undefined;
	}

	protected getColorPalletItemIcon(color: string): string | undefined {
		if (color === 'transparent') {
			return 'no_color';
		}
		if (this.isColorPalletSelected(color)) {
			return 'check';
		}

		return undefined;
	}

	protected getColorPalletStyles(color: string): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (this.$el) {
			if (color === 'transparent') {
				styles.backgroundColor = getComputedStyle(this.$el).getPropertyValue('--white1');
			} else if (!this.activePattern) {
				styles.backgroundColor = color;
				styles['--color-pallet-item-box-shadow-color'] = color;
			} else if (this.patterns) {
				const patternFound = this.patterns.find((pattern) => pattern.image === color) as ColorPickerPattern;
				styles.backgroundColor = patternFound.color;
				styles.backgroundImage = `url(${urlTools.appendParameter(
					color,
					'noCorsHeader',
				)})`;
			}
		}

		return styles;
	}

	private getColorValue(color: string): string {
		if (!this.$el) {
			return color;
		}

		if (
			color.substring(
				0,
				3,
			) === 'var'
		) {
			return getComputedStyle(this.$el)
				.getPropertyValue(
					color.substring(
						4,
						color.length - 1,
					),
				);
		}
		if (
			color.substring(
				0,
				2,
			) === '--'
		) {
			return getComputedStyle(this.$el)
				.getPropertyValue(color);
		}

		return color;
	}

	protected isColorFromEyeDropperSelected(): boolean {
		return !!(
			this.internalValue
			&& !this.colors.find((color) => this.isColorSelected(color))
			&& !this.patterns?.find((pattern) => this.isColorPalletSelected(pattern.image))
			&& this.internalValue !== 'transparent'
		);
	}

	protected isColorSliderActive(color: ColorPickerColor): boolean {
		return color.colors.primary === this.activeColor;
	}

	protected isColorPalletSelected(color: string): boolean {
		return (
			this.getColorValue(color).toLocaleLowerCase() === this.internalValue?.toLocaleLowerCase()
			|| (
				colorUtils.validateColor(this.getColorValue(color).toLocaleLowerCase())
				&& colorUtils.validateColor(this.internalValue?.toLocaleLowerCase())
				&& colorUtils.compareColors(
					this.getColorValue(color).toLocaleLowerCase(),
					this.internalValue?.toLocaleLowerCase(),
				)
			)
			|| (
				(
					!this.internalValue
					|| this.internalValue === 'transparent'
				)
				&& color === 'transparent'
			)
		);
	}

	protected isColorSelected(color: ColorPickerColor): boolean {
		if (this.getColorValue(color.colors.primary).toLocaleLowerCase() === this.internalValue?.toLocaleLowerCase()) {
			return true;
		}

		return !!color.colors.pallet
			.find((palletColor) => this.getColorValue(palletColor).toLocaleLowerCase() === this.internalValue?.toLocaleLowerCase());
	}

	@Public()
	public isEyeDropperActive(): boolean {
		return !!this.eyeDropperElement;
	}

	protected isTransparentSelected(): boolean {
		return (
			!this.internalValue
			|| this.internalValue === 'transparent'
		);
	}

	private onCanvasDraw(): void {
		if (this.eyeDropperElement) {
			this.destroyEyeDropper();
			this.onEyeDropperClick();
		}
	}

	private onCanvasMouseMove(event: MouseEvent | TouchEvent): void {
		if (this.eyeDropperElement) {
			if ('preventDefault' in event) {
				event.preventDefault();
			}

			let finalEvent: MouseEvent | Touch;

			if ('touches' in event) {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];

				if (
					finalEvent.pageX
					&& finalEvent.pageY
					&& !document
						.elementFromPoint(
							finalEvent.pageX,
							finalEvent.pageY,
						)
						?.isSameNode(this.computedCanvas)
				) {
					return;
				}
			} else {
				finalEvent = event;
			}

			const canvasRect = this.computedCanvas.getBoundingClientRect();
			let canvasClientX = finalEvent.clientX - canvasRect.left;
			let canvasClientY = finalEvent.clientY - canvasRect.top;

			if (canvasClientX < 0) {
				canvasClientX = 0;
			}
			if (canvasClientY < 0) {
				canvasClientY = 0;
			}

			const magnifierCanvas = this.eyeDropperElement.querySelector('.color-picker-eyedropper-canvas') as HTMLCanvasElement;
			const magnifierCanvasContext = magnifierCanvas.getContext('2d') as CanvasRenderingContext2D;
			const magnifierElement = this.eyeDropperElement.querySelector('.color-picker-eyedropper-magnifier') as HTMLDivElement;
			const previewElement = this.eyeDropperElement.querySelector('.color-picker-eyedropper-preview') as HTMLDivElement;

			const previewStyles = getComputedStyle(previewElement);
			const magnifierHeight = magnifierElement.clientHeight;
			const magnifierWidth = magnifierElement.clientWidth;
			const previewHeight = parseInt(
				previewStyles.getPropertyValue('--eyedropper-preview-height'),
				10,
			);
			const previewMarginTop = parseInt(
				previewStyles.getPropertyValue('--eyedropper-preview-margin-top'),
				10,
			);

			magnifierCanvasContext.clearRect(
				0,
				0,
				magnifierWidth,
				magnifierHeight,
			);
			magnifierCanvasContext.drawImage(
				this.computedCanvas,
				canvasClientX - magnifierWidth / (2 * this.magnification),
				canvasClientY - magnifierHeight / (2 * this.magnification),
				magnifierWidth / this.magnification,
				magnifierHeight / this.magnification,
				0,
				0,
				magnifierWidth,
				magnifierHeight,
			);

			const magnifierContainerElement = this.eyeDropperElement.querySelector('.color-picker-eyedropper-magnifier-container') as HTMLDivElement;
			const canvasPixel = magnifierCanvasContext.getImageData(
				(magnifierWidth / 2),
				(magnifierHeight / 2),
				1,
				1,
			).data;
			const pixelColor = colorUtils.getHexFromRGB(
				canvasPixel[0],
				canvasPixel[1],
				canvasPixel[2],
			);
			previewElement.style.backgroundColor = pixelColor;
			magnifierContainerElement.style.backgroundColor = pixelColor;

			this.eyeDropperElement.style.top = `${finalEvent.clientY - ((previewHeight / 2) + previewMarginTop + magnifierHeight)}px`;
			this.eyeDropperElement.style.left = `${finalEvent.clientX - (magnifierWidth / 2)}px`;
		}
	}

	private onCanvasMouseUp(): void {
		if (this.eyeDropperElement) {
			const { eyeDropperElement } = this;
			const previewElement = eyeDropperElement.querySelector('.color-picker-eyedropper-preview') as HTMLDivElement;
			const pixelColor = previewElement.style.backgroundColor;
			this.onColorEyeDropperSelect(pixelColor);
			this.$nextTick(() => {
				requestAnimationFrame(() => {
					this.destroyEyeDropper();
				});
			});
		}
	}

	protected onColorClick(color: ColorPickerColor): void {
		this.activeColor = color.colors.primary;
	}

	private onColorEyeDropperSelect(color: string): void {
		if (color.includes('rgb')) {
			color = colorUtils.getHexFromRGB(...colorUtils.getRGBFromRGBString(color));
		}

		this.internalValue = color;
	}

	protected onColorPalletClick(color: string): void {
		this.internalValue = this.getColorValue(color);
	}

	protected onEyeDropperClick(): void {
		if (this.eyeDropperElement) {
			this.destroyEyeDropper();
			return;
		}

		this.computedCanvas.addEventListener(
			'draw',
			this.onCanvasDraw,
		);
		this.computedCanvas.style.touchAction = 'none';
		this.eyeDropperElement = document.createElement('div');
		this.eyeDropperElement.classList.add('color-picker-eyedropper-helper');
		const magnifierContainerElement = document.createElement('div');
		magnifierContainerElement.classList.add('color-picker-eyedropper-magnifier-container');
		const magnifierElement = document.createElement('div');
		magnifierElement.classList.add('color-picker-eyedropper-magnifier');
		const pixelElement = document.createElement('div');
		pixelElement.classList.add('color-picker-eyedropper-pixel');
		const previewElement = document.createElement('div');
		previewElement.classList.add('color-picker-eyedropper-preview');
		const previewIconComponent = new IconComponent({
			parent: this,
		});
		previewIconComponent.$slots.default = ['color_picker'] as any[];
		previewIconComponent.$mount();
		previewElement.appendChild(previewIconComponent.$el);
		magnifierContainerElement.appendChild(magnifierElement);
		this.eyeDropperElement.appendChild(magnifierContainerElement);
		magnifierElement.appendChild(pixelElement);
		this.eyeDropperElement.appendChild(previewElement);
		document.body.appendChild(this.eyeDropperElement);
		const magnifierCanvas = document.createElement('canvas');
		magnifierCanvas.classList.add('color-picker-eyedropper-canvas');
		magnifierCanvas.height = magnifierElement.clientHeight;
		magnifierCanvas.width = magnifierElement.clientWidth;
		magnifierElement.appendChild(magnifierCanvas);

		this.computedCanvas.addEventListener(
			'mousemove',
			this.onCanvasMouseMove,
		);
		this.computedCanvas.addEventListener(
			'touchstart',
			this.onCanvasMouseMove,
		);
		this.computedCanvas.addEventListener(
			'touchmove',
			this.onCanvasMouseMove,
		);
		this.computedCanvas.addEventListener(
			'touchend',
			this.onCanvasMouseUp,
		);
		this.computedCanvas.addEventListener(
			'mouseup',
			this.onCanvasMouseUp,
		);
		this.$nextTick(() => {
			requestAnimationFrame(() => {
				window.addEventListener(
					'click',
					this.onWindowClick,
				);
			});
		});
		const canvasRect = this.computedCanvas.getBoundingClientRect();
		const canvasClientX = (this.computedCanvas.clientWidth / 2) + canvasRect.left;
		const canvasClientY = (this.computedCanvas.clientHeight / 2) + canvasRect.top;

		if (this.isMobile) {
			const touchEvent = new TouchEvent(
				'touchmove',
				{
					touches: [
						new Touch({
							clientX: canvasClientX,
							clientY: canvasClientY,
							identifier: 0,
							target: this.computedCanvas,
						}),
					],
				},
			);
			this.onCanvasMouseMove(touchEvent);
		} else {
			this.onCanvasMouseMove({
				clientX: canvasClientX,
				clientY: canvasClientY,
			} as MouseEvent);
		}

		this.$emit('eye-dropper-start');
	}

	private onWindowClick(event: MouseEvent | TouchEvent): void {
		if (event.target !== this.computedCanvas) {
			this.destroyEyeDropper();
		}
	}

	protected onWindowMouseDown(event: MouseEvent | TouchEvent): void {
		if (
			this.$el === event.target
			|| this.$el.contains(event.target as Node)
		) {
			this.isMouseDown = true;
			this.colorSliderElement.addEventListener(
				'mousemove',
				this.onWindowMouseMove,
			);
			window.addEventListener(
				'mouseup',
				this.onWindowMouseUp,
			);
			window.addEventListener(
				'touchend',
				this.onWindowMouseUp,
			);
			this.colorSliderElement.addEventListener(
				'touchmove',
				this.onWindowMouseMove,
			);
			this.onWindowMouseMove(event);
		}
	}

	private onWindowMouseMove(event: MouseEvent | TouchEvent): void {
		if (this.isMouseDown) {
			const color = this.getColorFromMousePosition(event);

			if (color) {
				if (typeof color !== 'string') {
					this.activeColor = color.colors.primary;
					this.activePattern = false;
				} else {
					this.activeColor = null;
					this.activePattern = true;
				}
			}
		}
	}

	private onWindowMouseUp(): void {
		this.isMouseDown = false;
		this.colorSliderElement.removeEventListener(
			'mousemove',
			this.onWindowMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.colorSliderElement.removeEventListener(
			'touchmove',
			this.onWindowMouseMove,
		);
	}
}
