import { MimeTypeExtended } from 'interfaces/app';

export const MIME_TYPES_JPEG: MimeTypeExtended[] = ['image/jpeg'];
export const MIME_TYPES_PNG: MimeTypeExtended[] = ['image/png'];
export const MIME_TYPES_GIF: MimeTypeExtended[] = ['image/gif'];
export const MIME_TYPES_WEBP: MimeTypeExtended[] = ['image/webp'];
export const MIME_TYPES_HEIC: MimeTypeExtended[] = ['image/heic', 'image/heif'];
export const MIME_TYPES_SVG: MimeTypeExtended[] = ['image/svg+xml'];
export const MIME_TYPES_PDF: MimeTypeExtended[] = ['application/pdf'];
export const MIME_TYPES_ILLUSTRATOR: MimeTypeExtended[] = [
	'application/postscript',
];
export const MIME_TYPES_EPS: MimeTypeExtended[] = [
	'application/postscript',
	'application/eps',
];
export const MIME_TYPES_CORELDRAW: MimeTypeExtended[] = [];

export const MIME_TYPES_BROWSER_SUPPORTED: MimeTypeExtended[] = [
	...MIME_TYPES_JPEG,
	...MIME_TYPES_PNG,
	...MIME_TYPES_GIF,
	...MIME_TYPES_WEBP,
	...MIME_TYPES_SVG,
];

export const MIME_TYPES_REQUIRE_CONVERSION: MimeTypeExtended[] = [
	...MIME_TYPES_HEIC,
	...MIME_TYPES_PDF,
	...MIME_TYPES_EPS,
	...MIME_TYPES_ILLUSTRATOR,
	// MIME_TYPES_CORELDRAW,
];

export const MIME_TYPES_SHOWN_AS_PNG: MimeTypeExtended[] = [
	...MIME_TYPES_PNG,
	...MIME_TYPES_GIF,
	...MIME_TYPES_PDF,
	...MIME_TYPES_ILLUSTRATOR,
	...MIME_TYPES_EPS,
];

export const FILE_EXTENSIONS_SHOWN_AS_SVG: string[] = [
	'cdr',
	'ai',
	'eps',
	'pdf',
];
