import { mobile as mobileTools } from 'tools';
import {
	Component,
	Model,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'InputComponent',
})
export default class InputComponent extends Vue.extend(Template) {
	@Model(
		'input',
		{
			default: '',
			description: 'Defines the title to display in the input',
			type: String,
		},
	)
	public readonly value?: string;

	@Prop({
		default: false,
		description: 'Indicates if the input should be disabled',
		type: Boolean,
	})
	public readonly disabled!: boolean;

	@Prop({
		default: 'auto',
		description: 'Defines the font size of the input, when being `auto` the font size is going to be determined if the @media query is mobile or not',
		type: [Number, String],
	})
	public readonly fontSize?: number | string;

	@Prop({
		default: 'auto',
		description: 'Defines the height of the input',
		type: [Number, String],
	})
	public readonly height?: number | string;

	@Prop({
		default: undefined,
		description: 'Defines the label to display in the input',
		type: String,
	})
	public readonly label?: string;

	@Prop({
		default: () => ([10, 8]),
		description: 'Defines the padding of the input',
		type: [Array, String],
	})
	public readonly padding?: number[] | string | null;

	@Prop({
		default: 'auto',
		description: 'Defines the width of the input',
		type: [Number, String],
	})
	public readonly width?: number | string;

	protected get computedStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (typeof this.height === 'number') {
			styles.height = `${this.height}px`;
		} else if (this.height) {
			styles.height = this.height;
		}

		if (typeof this.width === 'number') {
			styles.width = `${this.width}px`;
		} else if (this.width) {
			styles.width = this.width;
		}

		if (typeof this.fontSize === 'number') {
			styles.fontSize = `${this.fontSize}px`;
		} else if (this.fontSize === 'auto') {
			let fontSize: string;

			if (this.isMobile) {
				fontSize = 'var(--font-size-xs2)';
			} else {
				fontSize = 'var(--font-size-s)';
			}

			styles.fontSize = fontSize;
		} else if (this.fontSize) {
			if (
				this.fontSize.substring(
					0,
					2,
				) === '--'
			) {
				styles.fontSize = `var(${this.fontSize})`;
			} else {
				styles.fontSize = this.fontSize;
			}
		}

		if (Array.isArray(this.padding)) {
			styles.padding = this.padding
				.map((value) => `${value}px`)
				.join(' ');
		} else if (this.padding) {
			styles.padding = this.padding;
		}

		return styles;
	}

	@Ref('input')
	private inputElement!: HTMLInputElement;

	private internalValue = '';

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange() {
		this.$nextTick(() => {
			this.internalValue = this.value || '';
		});
	}

	protected onChange() {
		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onInput(event: InputEvent) {
		const { value } = (event.target as HTMLInputElement);
		this.internalValue = value;

		this.$emit(
			'input',
			this.internalValue,
		);
	}

	protected onLabelClick(): void {
		this.inputElement.focus();
	}
}
