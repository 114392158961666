import * as DB from 'interfaces/database';

type OfferingGroup = 'Agendas' | 'BasicProducts' | 'BuildOptions' | 'BookTypes' | 'Cards' | 'CardGame' | 'DoubleSidePrints' | 'PhotoSheets' | 'PageSets' | 'PhotoBooks' | 'PhotoFrameBox' | 'PhotoPrints' | 'PrintTypes' | 'WallDecoration';

export function OfferingGroups(
	groupid: DB.OfferingModel['groupid'],
	groupOrGroups: OfferingGroup[] | OfferingGroup,
): boolean {
	const objGroups: Record<OfferingGroup, number[]> = {
		Agendas: [111, 120],
		// Basic products only require the editing interface, no pages overview module
		BasicProducts: [102, 104, 106, 107, 108, 109, 115, 116, 117, 118, 119, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 136, 138, 140, 141, 143, 144, 145, 146, 147, 149, 150, 151, 152, 153, 154, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174],
		// Product for which we want to show the build options dialog
		BuildOptions: [101, 111, 120, 121, 155],
		// Products with bundled pages, a right- and left pages, pages are edited on both sides
		BookTypes: [101, 111, 120, 121, 122],
		Cards: [102, 106],
		CardGame: [113],
		DoubleSidePrints: [105, 112, 155],
		PhotoSheets: [155],
		// Set of single pages, either with a fixed- or a flexible page count
		PageSets: [103, 105, 110, 112, 113, 114, 135, 137, 139, 148, 155],
		// BookType that is considered a "photo book" in the true sense of the word
		PhotoBooks: [101, 121, 122],
		// Special type of product, that is basically a print set with double sided top card
		PhotoFrameBox: [137],
		// Photo prints (meerdagenservice)
		PhotoPrints: [139],
		// Set of single pages with a flexible page count
		PrintTypes: [103, 105, 110, 135, 137, 139, 148, 155],
		// Canvas, posters, etc.
		WallDecoration: [107, 108, 123, 125, 126, 136, 143, 144, 146, 150, 153],
	};

	if (Array.isArray(groupOrGroups)) {
		return !!groupOrGroups.find((arrGroup) => objGroups[arrGroup].includes(groupid));
	}

	return objGroups[groupOrGroups].includes(groupid);
}

export function OfferingLaunchers(offeringId: number) {
	const mappings: {
		[key: number]: {
			before: boolean;
			title: string;
			once: boolean;
			route: string | null;
		};
	} = {
		12110011: {
			before: false, // Run launcher after product setup is completed
			title: 'baby-book', // Used to identify the appropriate launcher
			once: true, // Show launcher only once for each product
			route: null, // Route is dynamic, as it's based on the productid (before: false)
		},
	};

	return mappings.hasOwnProperty(offeringId) ? mappings[offeringId] : null;
}

export function OfferingCountForBulkDiscount(
	bulkModel: DB.BulkModel,
	offeringModel: DB.OfferingModel,
	printPageCount: number,
	itemCount: number,
): number {
	if (bulkModel.count === 'pages') {
		let totalPageCount = Math.max(
			offeringModel.minprintpages,
			printPageCount,
		);
		const rest = (totalPageCount - offeringModel.minprintpages + offeringModel.pageinterval) % offeringModel.pageinterval;
		totalPageCount += rest > 0 ? offeringModel.pageinterval - rest : 0;

		const packageCount = totalPageCount / (offeringModel.hasback ? 2 : 1);
		return packageCount * itemCount;
	}

	return itemCount;
}

/**
 * Function to check if an offering has a required conversion to another offering
 * This is used when a certain offering is no longer available
 * @param offeringId - The offering id to check
 * @returns The offering id to convert to, or null if no conversion is required
 */
export function OfferingConversions(offeringId: number): DB.OfferingModel['id'] | null {
	// Note: Ideally this mapping should come from the database, where you can set the new offeringid
	// in the old offering record. But for the speed of implementation for HEMA, we're using a hardcoded now
	const mappings: Record<DB.OfferingModel['id'], DB.OfferingModel['id']> = {
		10110021: 10111011,
		10110022:	10111012,
		10110031:	10111021,
		10110032:	10111022,
		10110081:	10110341,
		10110082:	10110343,
		10110131:	10110991,
		10110132:	10110992,
		10110151:	10111001,
		10110152:	10111002,
		10110351:	10111051,
		10110361:	10111061,
		10110561:	10111031,
		10110571:	10111042,
		10110611:	10110981,
		10110612:	10110982,
		10110691:	10110372,
		10110692:	10110374,
		10110701:	10110342,
		10110702:	10110344,
		10110703:	10110341,
		10110704:	10110343,
		10110711:	10110962,
		10110712:	10110964,
		10110721:	10110412,
		10110722:	10110414,
		10110731:	10110392,
		10110732:	10110394,
		10110741:	10110972,
		10110742:	10110974,
		10110751:	10110402,
		10110752:	10110404,
		10110761:	10110382,
		10110762:	10110384,
		10110763:	10110381,
		10110764:	10110383,
		10110771:	10110382,
		10110772:	10110384,
		10110781:	101103713,
		10110782:	101103722,
		10110783:	101103713,
		10110784:	101103722,
		10110785:	101103713,
		10110786:	101103722,
		10110787:	101103713,
		10110788:	101103722,
		10110789:	101103713,
		101107810:	101103722,
		101107811:	101103713,
		101107812:	101103722,
		101107813:	101103713,
		101107814:	101103722,
		101107815:	101103713,
		101107816:	101103722,
		10110791:	101103913,
		10110792:	101103922,
		10110793:	101103913,
		10110794:	101103922,
		10110795:	101103913,
		10110796:	101103922,
		10110797:	101103913,
		10110798:	101103922,
		10110799:	101103913,
		101107910:	101103922,
		101107911:	101103913,
		101107912:	101103922,
		101107913:	101103913,
		101107914:	101103922,
		101107915:	101103913,
		101107916:	101103922,
		10110801:	101109613,
		10110802:	101109622,
		10110803:	101109613,
		10110804:	101109622,
		10110805:	101109613,
		10110806:	101109622,
		10110807:	101109613,
		10110808:	101109622,
		10110809:	101109613,
		101108010:	101109622,
		101108011:	101109613,
		101108012:	101109622,
		101108013:	101109613,
		101108014:	101109622,
		101108015:	101109613,
		101108016:	101109622,
		10110811:	101104113,
		10110812:	101104122,
		10110813:	101104113,
		10110814:	101104122,
		10110815:	101104113,
		10110816:	101104122,
		10110817:	101104113,
		10110818:	101104122,
		10110819:	101104113,
		101108110:	101104122,
		101108111:	101104113,
		101108112:	101104122,
		101108113:	101104113,
		101108114:	101104122,
		101108115:	101104113,
		101108116:	101104122,
		10110821:	101103913,
		10110822:	101103922,
		10110823:	101103913,
		10110824:	101103922,
		10110825:	101103913,
		10110826:	101103922,
		10110827:	101103913,
		10110828:	101103922,
		10110829:	101103913,
		101108210:	101103922,
		101108211:	101103913,
		101108212:	101103922,
		101108213:	101103913,
		101108214:	101103922,
		101108215:	101103913,
		101108216:	101103922,
		10110831:	101109713,
		10110832:	101109722,
		10110833:	101109713,
		10110834:	101109722,
		10110835:	101109713,
		10110836:	101109722,
		10110837:	101109713,
		10110838:	101109722,
		10110839:	101109713,
		101108310:	101109722,
		101108311:	101109713,
		101108312:	101109722,
		101108313:	101109713,
		101108314:	101109722,
		101108315:	101109713,
		101108316:	101109722,
		10110841:	101104013,
		10110842:	101104022,
		10110843:	101104013,
		10110844:	101104022,
		10110845:	101104013,
		10110846:	101104022,
		10110847:	101104013,
		10110848:	101104022,
		10110849:	101104013,
		101108410:	101104022,
		101108411:	101104013,
		101108412:	101104022,
		101108413:	101104013,
		101108414:	101104022,
		101108415:	101104013,
		101108416:	101104022,
		10110851:	101103813,
		10110852:	101103822,
		10110853:	101103813,
		10110854:	101103822,
		10110855:	101103813,
		10110856:	101103822,
		10110857:	101103813,
		10110858:	101103822,
		10110859:	101103813,
		101108510:	101103822,
		101108511:	101103813,
		101108512:	101103822,
		101108513:	101103813,
		101108514:	101103822,
		101108515:	101103813,
		101108516:	101103822,
		10110861:	101103813,
		10110862:	101103822,
		10110863:	101103813,
		10110864:	101103822,
		10110865:	101103813,
		10110866:	101103822,
		10110867:	101103813,
		10110868:	101103822,
		10110869:	101103813,
		101108610:	101103822,
		101108611:	101103813,
		101108612:	101103822,
		101108613:	101103813,
		101108614:	101103822,
		101108615:	101103813,
		101108616:	101103822,
	};

	if (mappings.hasOwnProperty(offeringId)) {
		return mappings[offeringId];
	}

	return null;
}
