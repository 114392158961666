import { url as urlTools } from 'tools';

export default function loadSVG(
	input: string | File,
): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const img = document.createElement('img');
		img.crossOrigin = 'anonymous';

		const source = typeof input === 'string'
			? input
			: URL.createObjectURL(input);

		img.src = urlTools.appendParameter(
			source,
			'noCorsHeader',
		);
		img.onload = () => {
			resolve(img);

			if (typeof input !== 'string') {
				URL.revokeObjectURL(img.src);
			}
		};
		img.onerror = () => {
			reject(new Error('Could not load the SVG image'));
		};
	});
}
