import './defines';
import { ButtonComponentColor } from 'interfaces/app';
import { mobile as mobileTools } from 'tools';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'ButtonComponent',
})
export default class ButtonComponent extends Vue.extend(Template) {
	@Prop({
		acceptedValues: [
			'primary',
			'secondary',
			'tertiary',
		],
		default: 'primary',
		description: 'Defines the color of the button',
		schema: 'ButtonComponentColor',
		type: String,
	})
	public readonly color!: ButtonComponentColor;

	@Prop({
		default: false,
		description: 'Indicates if the button should be disabled',
		type: Boolean,
	})
	public readonly disabled!: boolean;

	@Prop({
		default: 'auto',
		description: 'Defines the font size of the button, when being `auto` the font size is going to be determined if the @media query is mobile or not',
		type: [Number, String],
	})
	public readonly fontSize?: number | string;

	@Prop({
		default: 'auto',
		description: 'Defines the height of the button',
		type: [Number, String],
	})
	public readonly height?: number | string;

	@Prop({
		default: () => ([10, 12]),
		description: 'Defines the padding of the button',
		type: [Array, String, Number],
	})
	public readonly padding?: number[] | number | string | null;

	@Prop({
		default: 'auto',
		description: 'Defines the width of the button',
		type: [Number, String],
	})
	public readonly width?: number | string;

	protected get computedClasses(): Record<string, boolean> {
		return {
			[this.color]: true,
			'button-component-disabled': this.disabled,
		};
	}

	protected get computedStyles(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};

		if (typeof this.height === 'number') {
			styles.height = `${this.height}px`;
		} else if (
			this.height?.substring(
				0,
				2,
			) === '--'
		) {
			styles.height = `var(${this.height})`;
		} else if (this.height) {
			styles.height = this.height;
		}

		if (typeof this.width === 'number') {
			styles.width = `${this.width}px`;
		} else if (
			this.width?.substring(
				0,
				2,
			) === '--'
		) {
			styles.width = `var(${this.width})`;
		} else if (this.width) {
			styles.width = this.width;
		}

		if (typeof this.fontSize === 'number') {
			styles.fontSize = `${this.fontSize}px`;
		} else if (this.fontSize === 'auto') {
			let fontSize: string;

			if (this.isMobile) {
				fontSize = 'var(--font-size-xs2)';
			} else {
				fontSize = 'var(--font-size-s)';
			}

			styles.fontSize = fontSize;
		} else if (
			this.fontSize?.substring(
				0,
				2,
			) === '--'
		) {
			styles.fontSize = `var(${this.fontSize})`;
		} else if (this.fontSize) {
			styles.fontSize = this.fontSize;
		}

		if (Array.isArray(this.padding)) {
			styles.padding = this.padding
				.map((value) => `${value}px`)
				.join(' ');
		} else if (typeof this.padding === 'number') {
			styles.padding = `${this.padding}px`;
		} else if (this.padding) {
			styles.padding = this.padding;
		}

		return styles;
	}

	private isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
	}

	protected mounted(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected onClick(event: MouseEvent): void {
		this.$emit(
			'click',
			event,
		);
	}
}
