import ColorPickerComponent from 'components/color-picker';
import { PageObjectModel } from 'interfaces/project';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		ColorPickerComponent,
	},
})
export default class ColorPickerTabsComponent extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the canvas element that is going to be passed to the color-picker (for the background color)',
		required: true,
		type: [HTMLCanvasElement, Function],
	})
	public readonly canvas!: HTMLCanvasElement | (() => HTMLCanvasElement);

	@Prop(
		{
			description: 'This defines the color of the picker depending on the active tab',
			required: true,
			schema: 'PageObjectModel',
			type: Object,
		},
	)
	public readonly value!: PageObjectModel;

	protected get computedCanvas(): HTMLCanvasElement {
		if (typeof this.canvas === 'function') {
			return this.canvas();
		}

		return this.canvas;
	}

	protected get computedColor(): string {
		if (this.isFontColorTabActive) {
			return this.internalValue?.fontcolor || '';
		}

		return this.internalValue?.bgcolor || '';
	}

	protected isBackgroundColorTabActive = false;

	private isFontColorTabActive = true;

	private internalValue: PageObjectModel = {} as PageObjectModel;

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = {
			...this.value,
		};
	}

	protected onColorChange(color: string): void {
		if (this.isFontColorTabActive) {
			this.internalValue.fontcolor = color;
		} else {
			this.internalValue.bgcolor = color;
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onColorTabClick(tab: 'font' | 'background'): void {
		if (tab === 'font') {
			this.isFontColorTabActive = true;
			this.isBackgroundColorTabActive = false;
		} else {
			this.isFontColorTabActive = false;
			this.isBackgroundColorTabActive = true;
		}
	}

	protected onEyeDropperEnd(): void {
		this.$emit('eye-dropper-end');
	}

	protected onEyeDropperStart(): void {
		this.$emit('eye-dropper-start');
	}
}
