import DialogComponent from 'components/dialog';
import DialogNewComponent from 'components/dialog-new';
import DialogLoaderComponent from 'components/dialog-loader';
import DialogProgressComponent from 'components/dialog-progress';
import DialogPromptComponent from 'components/dialog-prompt';
import { ConfigModule } from 'store';
import {
	object as objectUtils,
	service as serviceUtils,
	vue as vueUtils,
} from 'utils';
import LoginView from 'views/login';
import SignUpView from 'views/signup';
import Vue, {
	VueConstructor,
	type VueConstructorExtended,
	VueExtended,
} from 'vue';

export { ServiceEvent } from 'services/service-event';

let authDialogCloseInstance: (() => void) | undefined;
let loaderDialogCloseInstance: (() => void) | undefined;
let creatingAuthDialog = false;
let creatingLoaderDialog = false;

/* eslint-disable @typescript-eslint/indent */

export function closeAuthDialog(): void {
	if (authDialogCloseInstance) {
		authDialogCloseInstance();
		authDialogCloseInstance = undefined;
	}
}

export function closeLoaderDialog(): void {
	if (loaderDialogCloseInstance) {
		loaderDialogCloseInstance();
		loaderDialogCloseInstance = undefined;
	}
}

export function openAlertDialog<
	BodyComponent extends VueConstructor<Vue>,
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: ServiceOptions<
		DialogComponent<
			BodyComponent,
			FooterComponent,
			HeaderComponent
		>
	>,
): () => void {
	type DialogComponentType = DialogComponent<
		typeof DialogLoaderComponent,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			classes: [],
			hasCloseButton: true,
			listeners: {},
			styles: {},
		},
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
		footer: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let close!: () => void;

	if (dialogOptions.footer) {
		const { footer } = dialogOptions;

		if (
			!('component' in footer)
			&& !('buttons' in footer)
		) {
			(footer as DialogServiceButtons).buttons = [
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonOk'),
					click: () => {
						close();
					},
				},
			];
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	close = openDialog(dialogOptions).close;

	return close;
}

export function openAuthDialog<
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: (
		(
			Omit<
				ServiceOptions<
					DialogComponent<
						typeof SignUpView,
						FooterComponent,
						HeaderComponent
					>
				>,
				'body' | 'header'
			>
			& {
				header?: (
					ServiceSectionOptions
					& (
						Omit<
							DialogServiceTitle,
							'title'
						>
						| ServiceComponentProps<HeaderComponent>
					)
				);
				body?: (
					Omit<
						DialogServiceOptionsBody<typeof SignUpView>,
						'component' | 'content'
					>
					& {
						props?: PublicOptionalNonFunctionProps<SignUpView>;
					}
				);
				showSignUp: true;
			}
		)
		| (
			Omit<
				ServiceOptions<
					DialogComponent<
						typeof LoginView,
						FooterComponent,
						HeaderComponent
					>
				>,
				'body' | 'header'
			>
			& {
				header?: (
					ServiceSectionOptions
					& (
						Omit<
							DialogServiceTitle,
							'title'
						>
						| ServiceComponentProps<HeaderComponent>
					)
				);
				body?: (
					Omit<
						DialogServiceOptionsBody<typeof LoginView>,
						'component' | 'content'
					>
					& {
						props?: PublicOptionalNonFunctionProps<LoginView>;
					}
				);
				showLogin?: boolean;
			}
		)
	),
): () => void {
	if (authDialogCloseInstance) {
		authDialogCloseInstance();
	}
	if (creatingAuthDialog) {
		return () => closeAuthDialog();
	}

	creatingAuthDialog = true;
	type DialogComponentType = DialogComponent<
		typeof LoginView | typeof SignUpView,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			title: window.App.router.$t(
				'views.authDialog.welcomeUser',
				{
					label: ConfigModule.labelTagName,
				},
			),
		},
		body: {
			component: (
				(
					'showSignUp' in options
					&& options.showSignUp
				)
					? SignUpView
					: LoginView
			),
		},
		classes: 'auth',
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const { close } = openDialog(dialogOptions);
	authDialogCloseInstance = close;
	creatingAuthDialog = false;

	return () => {
		close();
		authDialogCloseInstance = undefined;
	};
}

export function openConfirmDialog<
	BodyComponent extends VueConstructor<Vue>,
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: ServiceOptions<
		DialogComponent<
			BodyComponent,
			FooterComponent,
			HeaderComponent
		>
	>,
): () => void {
	type DialogComponentType = DialogComponent<
		typeof DialogLoaderComponent,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			classes: [],
			hasCloseButton: false,
			listeners: {},
			styles: {},
		},
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
		footer: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const { close } = openDialog(dialogOptions);

	return close;
}

export function openDialog<
	BodyComponent extends VueConstructor<Vue>,
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: ServiceOptions<
		DialogComponent<
			BodyComponent,
			FooterComponent,
			HeaderComponent
		>
	>,
): ServiceOpenReturn<
	DialogComponent<
		BodyComponent,
		FooterComponent,
		HeaderComponent
	>
> {
	type DialogComponentType = DialogComponent<
		BodyComponent,
		FooterComponent,
		HeaderComponent
	>;
	type DialogComponentTypeVue = VueExtended<DialogComponentType>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			classes: [],
			hasCloseButton: true,
			listeners: {},
			styles: {},
		},
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
		footer: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	let instance!: DialogComponentTypeVue;
	let fromCloseListener = false;
	const dialogAPI: ServiceOpenReturn<DialogComponentTypeVue> = {
		api: {} as any,
		close() {
			if (!fromCloseListener) {
				instance.onCloseClick(true);
			} else {
				fromCloseListener = false;
			}

			dialogAPI.destroy(false);
		},
		destroy(force = true) {
			instance.$destroy(force);

			if (instance._isDestroyed) {
				instance.$el.remove();
			}
		},
		isOpen: false,
	};
	Object.defineProperty(
		dialogAPI,
		'isOpen',
		{
			enumerable: true,
			get() {
				return !instance._isDestroyed;
			},
		},
	);

	const finalOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let { listeners } = finalOptions;

	const closeListener = () => {
		fromCloseListener = true;
		dialogAPI.close();
	};
	const modalClickListener = () => {
		if (
			finalOptions.header
			&& 'hasCloseButton' in finalOptions.header
			&& finalOptions.header.hasCloseButton
		) {
			dialogAPI.close();
		}
	};

	listeners = serviceUtils.addListener(
		'click',
		modalClickListener,
		listeners,
	);
	listeners = serviceUtils.addListener(
		'close',
		closeListener,
		listeners,
	);

	finalOptions.listeners = listeners;

	instance = new DialogComponent({
		parent: options.parent || window.App.router,
		_parentListeners: listeners,
		propsData: finalOptions,
	});

	dialogAPI.api = vueUtils.getInstanceAPI(instance);

	if (
		options.body
		&& 'component' in options.body
		&& options.body.component
	) {
		vueUtils.createInstanceSlot(
			options.body.component as unknown as VueConstructorExtended,
			instance as Vue,
			'body',
			{
				props: options.body.props,
				listeners: options.body.listeners,
			},
		);
	}

	if (
		options.footer
		&& 'component' in options.footer
		&& options.footer.component
	) {
		vueUtils.createInstanceSlot(
			options.footer.component as unknown as VueConstructorExtended,
			instance as Vue,
			'footer',
			{
				props: options.footer.props,
				listeners: options.footer.listeners,
			},
		);
	}

	if (
		options.header
		&& 'component' in options.header
		&& options.header.component
	) {
		vueUtils.createInstanceSlot(
			options.header.component as unknown as VueConstructorExtended,
			instance as Vue,
			'header',
			{
				props: options.header.props,
				listeners: options.header.listeners,
			},
		);
	}

	const instanceElement = document.createElement('div');
	document.getElementById('dialogs')?.append(instanceElement);
	instance.$mount(instanceElement);

	return dialogAPI;
}

export function openDialogNew<
	BodyComponent extends VueConstructor<Vue>,
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: ServiceOptions<
		DialogNewComponent<
			BodyComponent,
			FooterComponent,
			HeaderComponent
		>
	>,
): ServiceOpenReturn<
	DialogNewComponent<
		BodyComponent,
		FooterComponent,
		HeaderComponent
	>
> {
	type DialogComponentType = DialogNewComponent<
		BodyComponent,
		FooterComponent,
		HeaderComponent
	>;
	type DialogComponentTypeVue = VueExtended<DialogComponentType>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			classes: [],
			hasCloseButton: true,
			listeners: {},
			styles: {},
		},
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
		footer: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	let instance!: DialogComponentTypeVue;
	let fromCloseListener = false;
	const dialogAPI: ServiceOpenReturn<DialogComponentTypeVue> = {
		api: {} as any,
		close() {
			if (!fromCloseListener) {
				instance.onCloseClick(true);
			} else {
				fromCloseListener = false;
			}

			dialogAPI.destroy(false);
		},
		destroy(force = true) {
			instance.$destroy(force);

			if (instance._isDestroyed) {
				instance.$el.remove();
			}
		},
		isOpen: false,
	};
	Object.defineProperty(
		dialogAPI,
		'isOpen',
		{
			enumerable: true,
			get() {
				return !instance._isDestroyed;
			},
		},
	);

	const finalOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let { listeners } = finalOptions;

	const closeListener = () => {
		fromCloseListener = true;
		dialogAPI.close();
	};
	const modalClickListener = () => {
		if (
			finalOptions.header
			&& 'hasCloseButton' in finalOptions.header
			&& finalOptions.header.hasCloseButton
		) {
			dialogAPI.close();
		}
	};

	listeners = serviceUtils.addListener(
		'click',
		modalClickListener,
		listeners,
	);
	listeners = serviceUtils.addListener(
		'close',
		closeListener,
		listeners,
	);

	finalOptions.listeners = listeners;

	instance = new DialogNewComponent({
		parent: options.parent || window.App.router,
		_parentListeners: listeners,
		propsData: finalOptions,
	});

	dialogAPI.api = vueUtils.getInstanceAPI(instance);

	if (
		options.body
		&& 'component' in options.body
		&& options.body.component
	) {
		vueUtils.createInstanceSlot(
			options.body.component as unknown as VueConstructorExtended,
			instance as Vue,
			'body',
			{
				props: options.body.props,
				listeners: options.body.listeners,
			},
		);
	}

	if (
		options.footer
		&& 'component' in options.footer
		&& options.footer.component
	) {
		vueUtils.createInstanceSlot(
			options.footer.component as unknown as VueConstructorExtended,
			instance as Vue,
			'footer',
			{
				props: options.footer.props,
				listeners: options.footer.listeners,
			},
		);
	}

	if (
		options.header
		&& 'component' in options.header
		&& options.header.component
	) {
		vueUtils.createInstanceSlot(
			options.header.component as unknown as VueConstructorExtended,
			instance as Vue,
			'header',
			{
				props: options.header.props,
				listeners: options.header.listeners,
			},
		);
	}

	const instanceElement = document.createElement('div');
	document.getElementById('dialogs')?.append(instanceElement);
	instance.$mount(instanceElement);

	return dialogAPI;
}

export function openErrorDialog<
	BodyComponent extends VueConstructor<Vue>,
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options?: (
		ServiceOptions<
			DialogComponent<
				BodyComponent,
				FooterComponent,
				HeaderComponent
			>
		>
		& {
			code?: number | null;
		}
	),
): () => void {
	type DialogComponentType = DialogComponent<
		typeof DialogLoaderComponent,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			classes: [],
			hasCloseButton: true,
			listeners: {},
			styles: {},
			title: window.App.router.$t('dialogHeaderError'),
		},
		body: {
			classes: [],
			content: window.App.router.$t('dialogTextError'),
			listeners: {},
			styles: {},
		},
		footer: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options || {},
	);
	let close!: () => void;

	if (
		dialogOptions.header
		&& dialogOptions.body
		&& dialogOptions.code
	) {
		const {
			body,
			header,
		} = dialogOptions;

		if ('content' in body) {
			body.content = (
				window.App.router.$i18next.exists(`errors.${dialogOptions.code}`)
					? window.App.router.$t(`errors.${dialogOptions.code}`)
					: body.content
			);
		}
		if ('title' in header) {
			header.title += `${header.title || ''} [${dialogOptions.code}]`;
		}
	}
	if (dialogOptions.footer) {
		const { footer } = dialogOptions;

		if (
			!('component' in footer)
			&& !('buttons' in footer)
		) {
			(footer as DialogServiceButtons).buttons = [
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonErrorOk'),
					click: () => {
						close();
					},
				},
			];
		}
	}

	close = openDialog(dialogOptions).close;

	return close;
}

export function openLoaderDialog<
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options?: (
		Omit<
			ServiceOptions<
				DialogComponent<
					typeof DialogLoaderComponent,
					FooterComponent,
					HeaderComponent
				>
			>,
			'body'
		>
		& {
			body?: (
				Omit<
					DialogServiceOptionsBody<typeof DialogLoaderComponent>,
					'component' | 'content'
				>
				& {
					props?: PublicOptionalNonFunctionProps<DialogLoaderComponent>;
				}
			);
		}
	),
): () => void {
	if (loaderDialogCloseInstance) {
		loaderDialogCloseInstance();
	}
	if (creatingLoaderDialog) {
		return () => closeLoaderDialog();
	}

	creatingLoaderDialog = true;
	type DialogComponentType = DialogComponent<
		typeof DialogLoaderComponent,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			hasCloseButton: false,
		},
		body: {
			component: DialogLoaderComponent,
			props: {
				content: window.App.router.$t('dialogTextLoad'),
			},
			classes: {
				body: false,
			},
		},
		classes: {
			chrome: false,
			dialog: false,
		},
		width: 'auto',
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options || {},
	);

	const { close } = openDialog(dialogOptions);
	loaderDialogCloseInstance = close;
	creatingLoaderDialog = false;

	return () => {
		close();
		loaderDialogCloseInstance = undefined;
	};
}

export function openProgressDialog<
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: (
		Omit<
			ServiceOptions<
				DialogComponent<
					typeof DialogProgressComponent,
					FooterComponent,
					HeaderComponent
				>
			>,
			'body'
		>
		& {
			body?: (
				Omit<
					DialogServiceOptionsBody<typeof DialogProgressComponent>,
					'component' | 'content'
				>
				& {
					props?: PublicOptionalNonFunctionProps<DialogProgressComponent>;
				}
			);
		}
	),
): ServiceOpenReturn<
	DialogComponent<
		typeof DialogProgressComponent,
		FooterComponent,
		HeaderComponent
	>
> {
	type DialogComponentType = DialogComponent<
		typeof DialogProgressComponent,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			hasCloseButton: false,
			title: window.App.router.$t('progress'),
		},
		body: {
			component: DialogProgressComponent,
			props: {
				label: window.App.router.$t('progress'),
			},
		},
		width: 500,
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options || {},
	);

	return openDialog(dialogOptions);
}

export function openPromptDialog<
	FooterComponent extends VueConstructor<Vue>,
	HeaderComponent extends VueConstructor<Vue>,
>(
	options: (
		Omit<
			ServiceOptions<
				DialogComponent<
					typeof DialogPromptComponent,
					FooterComponent,
					HeaderComponent
				>
			>,
			'body'
		>
		& {
			body?: (
				Omit<
					DialogServiceOptionsBody<typeof DialogPromptComponent>,
					'component' | 'content'
				>
				& {
					props?: PublicOptionalNonFunctionProps<DialogPromptComponent>;
				}
			);
		}
	),
): ServiceOpenReturn<
	DialogComponent<
		typeof DialogPromptComponent,
		FooterComponent,
		HeaderComponent
	>
	> {
	type DialogComponentType = DialogComponent<
		typeof DialogPromptComponent,
		FooterComponent,
		HeaderComponent
	>;
	const defaultOptions: ServiceOptions<DialogComponentType> = {
		header: {
			hasCloseButton: false,
		},
		body: {
			component: DialogPromptComponent,
		},
	};
	const dialogOptions = objectUtils.deepAssign(
		{},
		defaultOptions,
		options || {},
	);

	return openDialog(dialogOptions);
}

/* eslint-enable @typescript-eslint/indent */

const DialogService = {
	closeLoaderDialog,
	openAlertDialog,
	openConfirmDialog,
	openDialog,
	openErrorDialog,
	openLoaderDialog,
	openProgressDialog,
	openPromptDialog,
};

export default DialogService;
