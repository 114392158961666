import './defines';
import { DialogClosePayloadVirtalOfferingSelect, VirtualOfferingSelectOfferingModel } from 'interfaces/app';
import * as DB from 'interfaces/database';
import { ServiceEvent } from 'services/service-event';
import {
	AppDataModule,
	ProductsModule,
	ProductStateModule,
	ThemeStateModule,
	UserModule,
} from 'store';
import formatMoney from 'tools/format-money';
import _ from 'underscore';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component
export default class VirtualOfferingSelectView extends Vue.extend(Template) {
	@Prop({
		required: true,
		type: Number,
	})
	public readonly count!: number;

	@Prop({
		required: true,
		type: String,
	})
	public readonly modus!: 'change' | 'select';

	@Prop({
		required: true,
		type: Object,
	})
	public readonly offeringModel!: VirtualOfferingSelectOfferingModel;

	@Prop({
		default: null,
		type: Number,
	})
	public readonly preSelectedLayoutId!: DB.LayoutModel['id'];

	@Prop({
		default: null,
		type: [Number, String],
	})
	public readonly preSelectedOfferingId!: DB.OfferingModel['id'];

	@Prop({
		required: true,
		type: Number,
	})
	public readonly regionId!: DB.RegionModel['id'];

	@Prop({
		required: true,
		type: Boolean,
	})
	public readonly selectFinish!: boolean;

	@Prop({
		required: true,
		type: Boolean,
	})
	public readonly selectLayout!: boolean;

	@Prop({
		required: true,
		type: Boolean,
	})
	public readonly selectSize!: boolean;

	protected img: string | null = null;

	private selectedLayoutId: DB.LayoutModel['id'] | null = null;

	private selectedTypeId: DB.OfferingModel['typeid'] | 'bestFit' | null = null;

	private selectedVariantId: DB.OfferingModel['variantid'] | null = null;

	private step: 'size' | 'finish' | 'layout' = 'size';

	protected get buttonText() {
		if (this.step === 'finish') {
			return this.modus === 'change'
				? this.$t('buttonChangeFinish')
				: this.$t('buttonSelectFinish');
		}

		if (this.step === 'size') {
			return this.modus === 'change'
				? this.$t('buttonChangeSize')
				: this.$t('buttonSelectSize');
		}

		if (this.step === 'layout') {
			return this.modus === 'change'
				? this.$t('buttons.changeLayout')
				: this.$t('buttons.selectLayout');
		}

		return '';
	}

	private get currencyModel() {
		return UserModule.currency
			? AppDataModule.getCurrency(UserModule.currency)
			: undefined;
	}

	private get currencySeparator() {
		return this.currencyModel && this.currencyModel.separator
			? this.currencyModel.separator
			: '.';
	}

	protected get headerText() {
		if (this.step === 'finish') {
			return this.modus == 'change'
				? this.$t(
					'changeFinishForPhotos',
					{ count: this.count },
				)
				: this.$t(
					'selectFinishForPhotos',
					{ count: this.count },
				);
		}

		if (this.step === 'size') {
			return this.modus == 'change'
				? this.$t(
					'changeSizeForPhotos',
					{ count: this.count },
				)
				: this.$t(
					'selectSizeForPhotos',
					{ count: this.count },
				);
		}

		if (this.step === 'layout') {
			return this.modus === 'change'
				? this.$t(
					'changeLayoutForPhotos',
					{ count: this.count },
				)
				: this.$t(
					'selectLayoutForPhotos',
					{ count: this.count },
				);
		}

		return '';
	}

	private get layoutModels() {
		return ThemeStateModule.layoutCollection;
	}

	private get offeringModels() {
		if (!this.offeringModel.flexgroupid) {
			return [];
		}

		let offeringModels: DB.OfferingModel[] = [];

		if (this.step === 'layout') {
			if (!this.selectedTypeId) {
				throw new Error('Missing required type id');
			}
			if (!this.selectedVariantId) {
				throw new Error('Missing required variant id');
			}

			offeringModels = AppDataModule.offerings.filter(
				(offeringModel) => offeringModel.groupid === this.offeringModel.groupid
					&& offeringModel.typeid === this.selectedTypeId
					&& offeringModel.variantid === this.selectedVariantId,
			);
		} else if (this.step === 'finish') {
			if (!this.selectedTypeId) {
				throw new Error('Missing required type id');
			}

			if (this.selectedTypeId === 'bestFit') {
				const groupOfferingModels = AppDataModule.findOffering({
					flexgroupid: this.offeringModel.flexgroupid,
					groupid: this.offeringModel.groupid,
					instock: 1,
					virtual: 0,
				});
				const typeIds = _.uniq(_.pluck(
					groupOfferingModels,
					'typeid',
				));
				const variantIds = _.uniq(_.pluck(
					groupOfferingModels,
					'variantid',
				));
				const showVariantIds: DB.OfferingModel['variantid'][] = [];

				variantIds.forEach((variantId) => {
					let available = true;
					typeIds.forEach((typeId) => {
						if (!_.findWhere(
							groupOfferingModels,
							{
								typeid: typeId,
								variantid: variantId,
							},
						)) {
							available = false;
						}
					});
					if (available) {
						showVariantIds.push(variantId);
					}
				});

				showVariantIds.forEach((variantId) => {
					const offeringModel = _.findWhere(
						groupOfferingModels,
						{
							typeid: typeIds[0],
							variantid: variantId,
						},
					);

					if (offeringModel) {
						offeringModels.push(offeringModel);
					}
				});
			} else {
				offeringModels = AppDataModule.findOffering({
					groupid: this.offeringModel.groupid,
					typeid: this.selectedTypeId,
					instock: 1,
				});
			}
		} else {
			offeringModels = AppDataModule.findOffering({
				flexgroupid: this.offeringModel.flexgroupid,
				variantid: 1,
				instock: 1,
			});
		}

		return offeringModels.filter(
			(offeringModel) => offeringModel.virtual === 0 && this.regionId && !!AppDataModule.findRegionOfferingLinkWhere({
				regionid: this.regionId,
				offeringid: offeringModel.id,
			}),
		);
	}

	private get productModel() {
		return ProductStateModule.getProduct;
	}

	private get selectedLayoutModel() {
		if (!this.selectedLayoutId) {
			return undefined;
		}

		return this.layoutModels.find(
			(layoutModel) => layoutModel.id === this.selectedLayoutId,
		);
	}

	private get selectedOfferingImage() {
		if (this.step === 'finish' && this.selectedOfferingModel) {
			return this.selectedOfferingModel.variantthumbnail;
		}

		if (this.selectedTypeId === 'bestFit') {
			if (this.offeringModel.thumbnail) {
				return this.offeringModel.thumbnail;
			}

			if (this.offeringModel.pdpid) {
				const listerItemModels = AppDataModule.findListerItems({
					pdpid: this.offeringModel.pdpid,
				});

				if (listerItemModels.length) {
					return listerItemModels[0].thumbnail;
				}
			}
		}

		return this.selectedOfferingModel?.thumbnail;
	}

	private get selectedOfferingModel() {
		if (!this.selectedTypeId) {
			return undefined;
		}

		if (this.selectedTypeId === 'bestFit') {
			if (this.step === 'finish' && this.selectedVariantId) {
				return _.findWhere(
					this.offeringModels,
					{ variantid: this.selectedVariantId },
				);
			}

			return this.offeringModels[0];
		}

		if (this.selectedVariantId) {
			return AppDataModule.findOfferingWhere({
				groupid: this.offeringModel.groupid,
				typeid: this.selectedTypeId,
				variantid: this.selectedVariantId,
			});
		}

		return AppDataModule.findOfferingWhere({
			groupid: this.offeringModel.groupid,
			typeid: this.selectedTypeId,
		});
	}

	private get showBestFit() {
		return this.step === 'size';
	}

	@Watch('img')
	protected updateDialog() {
		this.$emit('updateDialog');
	}

	@Watch('selectedOfferingImage')
	@Watch('step')
	@Watch('selectedLayoutModel')
	protected loadImage() {
		if (this.step === 'layout') {
			if (!this.selectedLayoutModel) {
				this.img = null;
			} else {
				const newImg = new Image();
				newImg.onload = () => {
					this.img = newImg.src;
				};
				newImg.src = this.selectedLayoutModel.icon;
			}
		} else if (this.selectedOfferingImage) {
			const newImg = new Image();
			newImg.onload = () => {
				this.img = newImg.src;
			};
			newImg.src = this.selectedOfferingImage;
		} else {
			this.img = null;
		}
	}

	protected created() {
		if (this.preSelectedOfferingId) {
			const offeringModel = AppDataModule.getOffering(this.preSelectedOfferingId);
			if (offeringModel) {
				this.selectedTypeId = offeringModel.typeid;
				this.selectedVariantId = offeringModel.variantid;
			}
		}

		if (!this.selectedTypeId) {
			if (this.showBestFit) {
				this.selectedTypeId = 'bestFit';
			} else {
				const firstNonSquare = this.offeringModels.find(
					(offeringModel) => offeringModel.width != offeringModel.height,
				);
				if (firstNonSquare) {
					this.selectedTypeId = firstNonSquare.typeid;
				}
			}
		}

		if (!this.selectSize && !this.selectFinish) {
			this.step = 'layout';
		} else if (!this.selectSize && this.selectedOfferingModel) {
			this.selectedTypeId = this.selectedOfferingModel.typeid;
			this.selectedVariantId = this.selectedOfferingModel.variantid;
			this.step = 'finish';
		}

		if (this.preSelectedLayoutId) {
			this.selectedLayoutId = this.preSelectedLayoutId;
		} else if (this.productModel && this.productModel.layoutid) {
			this.selectedLayoutId = this.productModel.layoutid;
		}
	}

	protected clickSelect() {
		if (this.step === 'finish') {
			this.proceedFromFinish();
		} else if (this.step === 'layout') {
			this.proceedFromLayout();
		} else if (this.step === 'size') {
			this.proceedFromSize();
		}
	}

	private closeDialog() {
		if (!this.selectedTypeId) {
			throw new Error('Missing required selected type id');
		}

		const payload: DialogClosePayloadVirtalOfferingSelect = {
			typeid: this.selectedTypeId,
			variantid: this.selectedVariantId || undefined,
			layoutid: this.selectedLayoutId || undefined,
		};
		if (this.selectedTypeId != 'bestFit' && this.selectedOfferingModel) {
			payload.offeringid = this.selectedOfferingModel.id;
		}

		this.$emit(
			'closeDialog',
			new ServiceEvent({
				type: 'closeDialog',
				payload,
			}),
		);
	}

	protected getLayoutLabel(
		layoutModel: DB.LayoutModel,
	) {
		return this.$t(
			`layouts:${layoutModel.id}.name`,
		);
	}

	protected getOfferingBadges(
		offeringId: DB.OfferingModel['id'],
	) {
		if (this.step === 'finish') {
			return [];
		}

		return AppDataModule.findOfferingBadges(
			offeringId,
		);
	}

	protected getOfferingLabel(
		offeringModel: DB.OfferingModel,
	) {
		if (this.step === 'finish') {
			return AppDataModule.getOfferingVariantName(
				offeringModel.id,
			);
		}

		return offeringModel.size;
	}

	protected getPrice(
		offeringModel: DB.OfferingModel,
	) {
		if (this.step === 'finish') {
			return undefined;
		}

		if (!this.currencyModel) {
			return undefined;
		}

		const pricingModel = AppDataModule.findPricingWhere({
			offeringid: offeringModel.id,
			currency: this.currencyModel.id,
		});

		if (!pricingModel) {
			return undefined;
		}

		return formatMoney(
			pricingModel.price_page,
			this.currencySeparator,
		);
	}

	private proceedFromFinish() {
		ProductStateModule.changeProductSettings({
			childOfferingTypeId: this.selectedTypeId,
			childOfferingVariantId: this.selectedVariantId || this.selectedOfferingModel?.variantid,
		});

		if (this.layoutModels.length === 1 || !this.selectLayout) {
			this.closeDialog();
		} else {
			this.img = null;

			if (!this.selectedLayoutId) {
				this.selectedLayoutId = this.layoutModels[0].id;
			}

			this.step = 'layout';
		}
	}

	private proceedFromLayout() {
		if (!this.productModel) {
			throw new Error('Could not find required product model');
		}

		if (this.modus === 'select') {
			// We only set the layoutid property on the product model when we are in the 'select' modus
			// In case we are in the 'change' modus, we need to be able to check if the layoutid property was changed
			ProductsModule.putModel({
				id: this.productModel.id,
				data: {
					layoutid: this.selectedLayoutId,
				},
			});
		}

		this.closeDialog();
	}

	private proceedFromSize() {
		let offeringModels: DB.OfferingModel[] = [];
		if (this.selectedOfferingModel) {
			offeringModels = AppDataModule.findOffering({
				groupid: this.selectedOfferingModel.groupid,
				typeid: this.selectedOfferingModel.typeid,
			});
		} else {
			offeringModels = this.offeringModels;
		}

		if (offeringModels.length === 0) {
			throw new Error('Could not find selected offering model');
		}

		if (offeringModels.length === 1) {
			ProductStateModule.changeProductSettings({
				childOfferingTypeId: offeringModels[0].typeid,
				childOfferingVariantId: offeringModels[0].variantid,
			});

			this.closeDialog();
		} else if (!this.selectFinish) {
			ProductStateModule.changeProductSettings({
				childOfferingTypeId: this.selectedTypeId,
			});

			this.closeDialog();
		} else {
			this.step = 'finish';
		}
	}

	protected selectLayoutId(
		layoutModelId: DB.LayoutModel['id'],
	) {
		if (layoutModelId != this.selectedLayoutId) {
			this.img = null;
			this.selectedLayoutId = layoutModelId;
		}
	}

	protected selectOffering(
		offeringModelId: DB.OfferingModel['id'] | 'bestFit',
	) {
		if (this.selectedOfferingModel
			&& offeringModelId != this.selectedOfferingModel.id
			&& !(offeringModelId === 'bestFit' && this.selectedTypeId === 'bestFit')
		) {
			this.img = null;
		}

		if (this.step === 'finish' && offeringModelId !== 'bestFit') {
			const offeringModel = AppDataModule.getOffering(offeringModelId);
			if (offeringModel) {
				this.selectedVariantId = offeringModel.variantid;
			}
		} else if (offeringModelId === 'bestFit') {
			this.selectedTypeId = 'bestFit';
		} else {
			const offeringModel = AppDataModule.getOffering(offeringModelId);
			if (offeringModel) {
				this.selectedTypeId = offeringModel.typeid;
			}
		}
	}

	protected isLayoutSelected(
		layoutModelId: DB.LayoutModel['id'],
	) {
		return layoutModelId === this.selectedLayoutId;
	}

	protected isOfferingSelected(
		offeringModel: DB.OfferingModel | null,
	) {
		if (offeringModel === null && this.selectedTypeId === 'bestFit') {
			return true;
		}

		if (!this.selectedOfferingModel || offeringModel === null) {
			return false;
		}

		if (this.step === 'finish') {
			return this.selectedOfferingModel.typeid == offeringModel.typeid
				&& this.selectedOfferingModel.variantid == offeringModel.variantid;
		}

		return this.selectedTypeId === offeringModel.typeid;
	}
}
